<template>

  <div>

     <v-dialog  @keydown.esc="addItemAction(false)" @click:outside="addItemAction(false)" v-model="dialogAdd"  max-width="500px">
       <v-card>
      
        <v-card-title>Accepted Submissions</v-card-title>
        <v-card-subtitle class="pt-2">Add Submission</v-card-subtitle>

        <v-card-text>
          
          <v-row class="d-flex align-baseline" dense>
            <v-col cols="8">
                <v-text-field
                  v-model  ="editedItem.portmanId"
                  label   ="Portman Id"
                ></v-text-field>
              </v-col>

              <v-col align="right" cols="4">
                <v-btn :loading="lookupLoading" @click="lookup">Lookup</v-btn>
              </v-col>
          </v-row>

            <v-row dense>

              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.fullName"
                  label="Candidate Name"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.email"
                  label="Candidate Email"
                ></v-text-field>
              </v-col>

            </v-row>

            <v-row dense>
              
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.hours"
                  label="Work Units"
                ></v-text-field>
              </v-col>
            
              <v-col cols="12"  md="4">
                <v-text-field
                  v-model = "editedItem.mileage"
                  label   = "Mileage"
                ></v-text-field>
              </v-col>
          
              <v-col cols="12"  md="4">
                <v-text-field
                  v-model = "editedItem.expenses"
                  label   = "Expenses"
                ></v-text-field>
              </v-col>


            </v-row>
          
            <v-row dense>
        
              <v-col cols="12"  md="8">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      label="Work Week ending"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color = "#B9802B"
                    no-title
                    v-model="editedItem.weekEnding"
                    @change="menu = false"
                  ></v-date-picker>
                </v-menu>

              
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="4">
                  <v-checkbox
                      v-model = "editedItem.rollOver"
                      label  = "is Roll over"
                  ></v-checkbox>
              </v-col>


            </v-row>

        </v-card-text>
        
        <v-card-actions>
      
          <v-col class="text-right">
            <v-btn
              color="#B9802B"
              text
              @click="addItemAction(false)"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#B9802B"
              text
              :loading = "dialogLoading"
              @click="addItemAction(true)"
            >
              Save
            </v-btn>
          </v-col>
      
        </v-card-actions>

      </v-card>
     </v-dialog>

     <v-dialog  @keydown.esc="addItemAction(false)" @click:outside="addItemAction(false)" v-model="dialogProcessing"  max-width="600px">
       <v-card>
       
       <v-card-title  class="titleColor justify-center">
        {{dialogProcessingText}}
          <v-badge v-if = "dialogProcessingSubmissions"
                class="ml-2"
                color="error"
                :content="rowsSelectedCount"
                inline
          ></v-badge>
       </v-card-title>

       <v-card-text>
          <v-row  class="justify-center ma-4">
            <div v-if="dialogProcessingSubmissions">
              <v-col>
                <v-progress-linear :value="itemPercentageProcessed" color="blue accent-2"  rounded height="10" striped></v-progress-linear>
              </v-col>
              <v-simple-table>

                <thead>
                  <tr>
                    <th class="text-left">Worker</th>
                    <th class="text-left">Portman ID</th>
                    <th class="text-left">Client</th>
                    <th class="text-left">Umbrella</th>
                    <th class="text-left">Consultant</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> {{ (this.itemBeingProcessed) ? this.itemBeingProcessed.fullName   : '' }}</td>
                    <td> {{ (this.itemBeingProcessed) ? this.itemBeingProcessed.portmanId  : '' }}</td>
                    <td> {{ (this.itemBeingProcessed) ? this.itemBeingProcessed.clientName : '' }}</td>
                    <td> {{ (this.itemBeingProcessed) ? this.itemBeingProcessed.umbrellaName : '' }}</td>
                    <td> {{ (this.itemBeingProcessed) ? this.itemBeingProcessed.consultantName : '' }}</td>
                    
                  </tr>
                </tbody>
                
        
              </v-simple-table>
            </div> 
          </v-row>
       </v-card-text>

      </v-card>
     </v-dialog>


     <v-data-table
        v-model       = "itemsSelected"
        id            = "acceptedTable"
        :headers      = "headers"
        :items        = "items"
        :sort-by.sync   = "sortBy"
        :sort-desc.sync = "sortDesc"
         class         = "elevation-1"
        :loading      = "tableLoading"
        loading-text  = "Data is Loading... Please wait"
        show-expand
        :show-select  =  "showSelect"
        :single-select = "singleSelect"
        :item-class   = "itemHoursMoreThan37"
        expand-icon   = "mdi-information"
    >
     
        <template v-slot:item.netPay="{ item }">
           <span>{{ item.netPay | toCurrency }}</span>
        </template>

        <template v-slot:item.netMileage="{ item }">
           <span>{{ item.netMileage | toCurrency }}</span>
        </template>

        <template v-slot:item.netExpense="{ item }">
           <span>{{ item.netExpense | toCurrency }}</span>
        </template>

        <template v-slot:item.netCharge="{ item }">
           <span>{{ item.netCharge | toCurrency }}</span>
        </template>

        <template v-slot:item.netMargin="{ item }">
           <span>{{ item.netMargin | toCurrency }}</span>
        </template>

        <template v-slot:top>
   
        <v-toolbar flat>
  
             <exportToCsvDialog :items= "items" title="Export Accepted to CSV" gridName="Accepted"/>
          
              <v-toolbar-title class="text-capitalize">{{formTitle}}</v-toolbar-title>
             
              <v-divider  class="mx-4" inset vertical></v-divider>

                  <v-text-field
                    :value = "computedWeekEnding"
                    label  = "Week Ending"
                    readonly
                    hide-details
                  ></v-text-field>

                       
              <v-divider  class="mx-4" inset vertical></v-divider>

              <v-btn  :outlined="filter == 'notsubmitted'"
                      @click = "setFilter('notsubmitted')"  text color="orange">Not Submitted ({{ notSubmittedCount }})</v-btn>
                 
              <v-btn  :outlined="filter == 'declined'"
                      @click= "setFilter('declined')"  text color="red">Declined ({{declinedCount}})</v-btn>
                      
              <v-btn :outlined="filter == 'accepted'"
                     @click = "setFilter('accepted')"  text color="green">Accepted ({{acceptedCount}})</v-btn>
                        

              <v-divider  class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>

              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="refreshDataTables" :loading = "tableLoading">
                        <v-icon color="#B9802B" size="28px">mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Refresh Table</span>
              </v-tooltip>
                
              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                           <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>
                    </template>
                    <span>Export to CSV</span>
              </v-tooltip>

              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                           <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Generate PDF</span>
              </v-tooltip>

              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text class  ="simple" v-bind ="attrs" v-on ="on" @click="addItem()">
                      <v-icon color="#B9802B" size="28px" >mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Submission</span>
              </v-tooltip>

        </v-toolbar>

        </template>

         <template  v-if= "filter === 'notsubmitted'" v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">

                  <div class="d-flex flex-column">
                     <div class="pt-2 pb-1 pl-1">Candidate Email : {{ item.email }}</div>
                     <div class="pb-1 pl-1">Portman Id : {{ item.portmanId }}</div>
                  </div>
            </td>
         </template>
         <template  v-else v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">

                  <div class="d-flex flex-column">
                    <div class="pt-2 pb-1 pl-1">Candidate Email : {{ item.email }}</div>
                    <div class="pb-1 pl-1">Portman Id : {{ item.portmanId }}</div>
                    <div class="pb-1 pl-1">Date Submitted  : {{ formatDate(item.created_at) }}</div>
                    <div class="pb-1 pl-1">Submitted By    : {{ item.submitter }}</div>
                    <div :class="(item.rollOver == 1) ? 'rollOverText' : ''" class="pb-1 pl-1">is Rollover     : {{ formatRollOver(item.rollOver)  }}</div>
                    <div class="pb-1 pl-1">Week Ending     : {{ formatDate(item.weekEnding)    }}</div>
                  </div>
            </td>
         </template>
       


        <template v-if="filter === 'accepted'" slot="body.append">
          <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>

          <th class="font14">{{ netPayComputed     | toCurrency }}</th>
          <th class="font14">{{ netMileageComputed | toCurrency }}</th>
          <th class="font14">{{ netExpenseComputed | toCurrency }}</th>
          <th class="font14">{{ netChargeComputed  | toCurrency }}</th>
          <th class="font14">{{ netMarginComputed  | toCurrency }}</th>

          <th></th>
          <th>
            <v-btn :disabled = "rowsSelectedCount == 0" color="info" small @click="handleApproveSelectedItemsPreview"> 
               Preview Run
               <v-badge
                color="error"
                :content="rowsSelectedCount"
                inline
              ></v-badge>
              </v-btn>
          </th>
          
          <th>
              <v-btn :disabled = "rowsSelectedCount == 0" color="success" small @click="handleApproveSelectedItems"> 
              Approve
               <v-badge
                color="error"
                :content="rowsSelectedCount"
                inline
              ></v-badge>
              </v-btn>
          </th>


        </tr>
        </template>

        <template v-slot:item.rollOver = "{ item }">
         <v-simple-checkbox :value="(item.rollOver == 0 ) ? false : true" disabled></v-simple-checkbox>
        </template>

        <template v-slot:item.status="{ item }">
            <span :class="getStatusColor(item.status)" class="text-capitalize">{{ item.status }}</span>
        </template>
            
        <template v-slot:item.created_at="{ item }">
 
          <span>
           <strong>
            {{ new Date(item.created_at).toLocaleString('en-GB', { year: "numeric",month: "numeric", day: "numeric", timeZone: 'UTC' }) }}
          </strong>
         </span> 

        </template>

        <template v-if="filter === 'notsubmitted'" v-slot:item.actions="{ item }">
             <v-list-item  @click="addItem(item)">
                 <v-icon color="#B9802B" size="28px">mdi-plus-circle-outline</v-icon>
              </v-list-item>
        </template>

     </v-data-table>

  </div>
 
</template>

<script>

  import  { store }               from '@/store/store'
  import  { apiResource }         from '@/store/apiResource'
  import  { apiShared }           from '@/store/apiShared'
  import  { acceptedGridHeaders}  from '@/store/acceptedGridHeaders'
  
  import  axios            from "axios";
  import  moment           from 'moment'
  import  exportToCsvDialog       from '@/components/Dialogs/exportToCsvDialog.vue'


  export default {

      name                 : 'AcceptedCrud',
      components           : { exportToCsvDialog },
      
      filters : {
            
                toCurrency: function (value) {
                  var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
                  return formatter.format( value );
                }
      },

      mounted(){
             this.weekEndingFilter = this.endofWeek;
      },

      watch : {
            weekEndingFilter: function () {
                 this.refreshDataTables();
            }

      },


      data : () => ({

          store,
          menu                  : false,
          menu1                 : false,
          statusItems           : ["Pending","Accepted","Declined"],

          itemsSelected           : [],
          itemBeingProcessed      : null,
          itemProcessedCount      : 0,
          itemPercentageProcessed : 0,
                    
          filter                : 'accepted',
          weekEndingFilter      : '',

          notSubmittedItems     : [],
          approvalItems         : [],
          
          sortBy                : "created_at",
          sortDesc              : false,

          tableLoading          : false,
          printerLoading        : false,
          dialogLoading         : false,
          lookupLoading         : false,
          dialogAdd             : false,
          
          dialogProcessing            : false,
          dialogProcessingText        : "",
          dialogProcessingSubmissions : false,
                        
          defaultItem        : {
            id             : '',
            fullName       : '',
            email          : '',
            userId         : '',
            portmanId      : '',
            homePhone      : '',
            cellPhone      : '',
            hours          : '',
            mileage        : '',
            expenses       : '',
            status         : '',
            weekEnding     : '',
            rollOver       : false,
          },

          editedItem           : {
                id             : '',
                fullName       : '',
                email          : '',
                userId         : '',
                portmanId      : '',
                homePhone      : '',
                cellPhone      : '',
                hours          : '',
                mileage        : '',
                expenses       : '',
                status         : '',
                weekEnding     : '',
                rollOver       : ''
          },

      }),


      computed : {
         
          netPayComputed(){
                let summary = 0.00;
                this.approvalItems.forEach(  function(item) {
                      const val = ( item['status'].toLowerCase() === 'accepted') ? item.netPay : 0.00;
                      summary   = summary + val;
                });
                return (summary);
          },
 
          netMileageComputed(){
                let summary = 0.00;
                this.approvalItems.forEach(  function(item) {
                      const val = ( item['status'].toLowerCase() === 'accepted') ? item.netMileage : 0.00;
                      summary   = summary +val;
                });
                return (summary);
          },
          
          netExpenseComputed(){
                let summary = 0.00;
                this.approvalItems.forEach(  function(item) {
                      const val = ( item['status'].toLowerCase() === 'accepted') ? parseFloat(item.netExpense) : 0.00;
                      summary   = summary +val;
                });
                return (summary);
          },
          
          netChargeComputed(){
                let summary = 0.00;
                this.approvalItems.forEach(  function(item) {
                      const val = ( item['status'].toLowerCase() === 'accepted') ? item.netCharge : 0.00;
                      summary   = summary + val;
                });
                return (summary);
          },

          netMarginComputed(){
                let summary = 0.00;
                this.approvalItems.forEach(  function(item) {
                      const val = ( item['status'].toLowerCase() === 'accepted') ? item.netMargin : 0.00;
                      summary   = summary +val;
                });
                return (summary);
          },

          rowsSelectedCount(){
                return ( this.itemsSelected.length == 0 ) ? '0' : `${this.itemsSelected.length - this.itemProcessedCount}`;
                 
          },

          allowAddition() {
            return ( this.filter === 'notsubmitted' ) ? true : false; 
          },

          showSelect() {
              return ( this.filter === 'accepted' ) ? true : false; 
          },

          singleSelect() {
              return ( this.filter != 'accepted' ) ? true : false; 
          },

          computedDateFormattedMomentjs () {
             return this.editedItem.weekEnding ? moment(this.editedItem.weekEnding).format('dddd, MMMM Do YYYY') : ''
          },
          computedWeekEnding () {
             return (this.weekEndingFilter) ? moment( this.weekEndingFilter).format('dddd, MMMM Do YYYY') : ''
          },

          endofWeek () {
            var newDate = moment(); 
            return newDate.day(newDate.day() >= 5 ? 5 :-2).format('YYYY-MM-DD');
          },

          items() {
 
                var temp = [];
     
                if ( this.filter === 'accepted') {
                    temp = this.approvalItems.filter( ( item )  => {
                          return ( item['status'].toLowerCase() === 'accepted' ) 
                    });
                }
                else if ( this.filter === 'declined') {
                    temp = this.approvalItems.filter( ( item )  => {
                          return ( item['status'].toLowerCase() === 'declined' ) 
                    });
                }
                else if ( this.filter === 'notsubmitted') {
                    temp = this.notSubmittedItems.filter( ( item )  => {
                          return item 
                    });
                }

                return temp;
          },
          
          headers() {
  
                  var temp = [];

                  if ( this.filter == 'accepted' )
                       temp = acceptedGridHeaders.getGridHeaders('approval');
                  else if ( this.filter == 'declined' )
                       temp = acceptedGridHeaders.getGridHeaders('declined');
                  else if ( this.filter == 'notsubmitted' )
                       temp = acceptedGridHeaders.getGridHeaders('notsubmitted');
            
                  return ( temp ) 
          },

          formTitle () {

            var temp = "";

              if ( this.filter == 'accepted' )
                  temp = "Accepted";
              else if ( this.filter == 'declined' )
                  temp = "Declined";
              else if ( this.filter == 'notsubmitted' )
                  temp = "Not Submitted";

            return ( temp )

          },

          declinedCount() {
                const result = this.approvalItems.filter( ( item )  => {
                      return ( item['status'].toLowerCase() === "declined") 
                });
                return result.length;
          },
        
          acceptedCount() {
                const result = this.approvalItems.filter( ( item )  => {
                      return ( item['status'].toLowerCase() === "accepted") 
                });
                return result.length;
          },

          notSubmittedCount() {
               // in case we want to filter 
               const result = this.notSubmittedItems.filter( ( item )  => {
                      return ( item );
                });

                return result.length;
          },

      },


      methods : {

        generatePDF() {
            apiShared.generatePDF('Accepted');
        },

        calculateLineItemVAT(netCharge, vatRate){
            // returns a string 2 decimal places
            const _netCharge  = parseFloat( netCharge);
            const _vatRate    = parseFloat( vatRate);
            let   vatAmount   = ( (_netCharge / 100 ) * _vatRate).toFixed(2);
            return vatAmount;
        },

        calculateLineItemTotal( netCharge , vatAmount){
            // returns a string 2 decimal places
            const _netCharge = parseFloat(netCharge);
            const _vatAmount = parseFloat(vatAmount);
            let total = (_vatAmount + _netCharge).toFixed(2);
            return total; 
        },


        itemHoursMoreThan37: function (item) {
            return item.hours > 37 ? 'custom-highlight-row' : ''
        },


        async  handleApproveSelectedItemsPreview() {

                         this.dialogProcessing              = true;
                
                         this.dialogProcessingSubmissions   = true;
                         this.dialogProcessingText          = "Processing Selected Submissions (Preview)";
                         this.itemProcessedCount            = 0 ;
                         this.itemBeingProcessed            = null;
                         this.itemPercentageProcessed       = 0;
                         this.dialogProcessingSubmissions   = false;
                      
                    this.dialogProcessingText          = "Generating Umbrella Self Bill Invoices (Preview)";
                    var globalSettings                 =  await this.getGlobalSettings();
                    var umbrellas                      =  await this.getUmbrellas();

                  for (const umbrella of umbrellas ) {
                      
                      this.dialogProcessingText     = `Generating ${umbrella.umbrellaName} Preview Invoice`;
                      umbrella['vatRate']           = ( umbrella['vatRate'] ) ? umbrella['vatRate'] :  globalSettings['vatRate'];
                      var invoice                   =  await this.generateUmbrellaInvoice( umbrella, globalSettings );

                      /////////////////////////////////////////////////////////////////
                      // only send email umbrella self Bill invoice if invoice has lines items //
                      /////////////////////////////////////////////////////////////////
                      if ( invoice.items.length > 0) {
                         this.dialogProcessingText     = `Sending (preview) ${umbrella.umbrellaName} Self Bill Invoice`;
                         await this.sendUmbrellaInvoicePreview( invoice );
                      } else{
                           this.dialogProcessingText     = `${umbrella.umbrellaName} - No candidate submissions`;
                      } 
             
                  }

                  setTimeout( () => {
                      this.itemsSelected             = [];
                      this.dialogProcessingText      = "";
                      this.dialogProcessing          = false;
                      this.refreshDataTables();
                  }, 1000);

                  return true;

          

        },
        
        async handleApproveSelectedItems(){
      
                  this.dialogProcessing              = true;
                  this.dialogProcessingSubmissions   = true;
                  this.dialogProcessingText          = "Processing Approved Submissions";

                  this.itemProcessedCount       = 0 ;
                  this.itemBeingProcessed       = null;
                  this.itemPercentageProcessed  = 0;
                 
                  await this.approveSelectedItems();
            
                  this.dialogProcessingSubmissions   = false;
                 
          
                      this.dialogProcessingText          = "Creating Umbrella Invoices";
                      var globalSettings                 = await this.getGlobalSettings();
                      var umbrellas                      = await this.getUmbrellas();
        
                       for (const umbrella of umbrellas ) {
                      
                      this.dialogProcessingText     = `Generating ${umbrella.umbrellaName} Invoice`;

                      umbrella['vatRate'] = ( umbrella['vatRate'] ) ? umbrella['vatRate'] :  globalSettings['vatRate'];
                      var invoice         =  await this.generateUmbrellaInvoice( umbrella, globalSettings );

                      /////////////////////////////////////////////////////////////////
                      // only send email umbrella invoice if invoice has lines items //
                      /////////////////////////////////////////////////////////////////
                      if ( invoice.items.length > 0) {
                         this.dialogProcessingText     = `Sending ${umbrella.umbrellaName} Invoice`;
                         await this.sendUmbrellaInvoice( invoice );
                      } else{
                           this.dialogProcessingText     = `${umbrella.umbrellaName} - No candidate submissions`;
                      } 
                    

                      }
              
   
                  setTimeout( () => {
                      this.itemsSelected             = [];
                      this.dialogProcessingText      = "";
                      this.dialogProcessing          = false;
                      this.refreshDataTables();
                  }, 1000);

                  return true;
        },


        async generateUmbrellaInvoice( umbrella , globalSettings ) {

                var invoice                 = {};
                var random_num              = Math.floor(Math.random() * 90000) + 10000;
      
                invoice['items']             = [];

                invoice['umbrellaName']      = (umbrella.umbrellaName)    ? umbrella.umbrellaName    : '' ;
                invoice['umbrellaAddress']   = (umbrella.umbrellaAddress) ? umbrella.umbrellaAddress : '' ;
                invoice['umbrellaVAT']       = (umbrella.umbrellaVAT)     ? umbrella.umbrellaVAT     : '' ;
                invoice['umbrellaEmail']     = (umbrella.contactEmail)    ? umbrella.contactEmail    : '' ;
                invoice['umbrellaContact']   = (umbrella.contactName)     ? umbrella.contactName     : '' ;
                
                /** 7/17/2023 added company Number **/
                invoice['companyNumber']     = (umbrella.companyNumber)   ? umbrella.companyNumber   : '' ;

                invoice['invoiceDate']       =  moment().format('ll');
                
                var tempInvoiceNum           =  umbrella.id + '-' + random_num + '-' + moment().format("DDMMYY");
                invoice['invoiceNum']        =  tempInvoiceNum.toUpperCase();
             
                invoice['subTotal']          = 0.00;
                invoice['VAT']               = 0.00;
                invoice['total']             = 0.00;
            
                const currentUmbrella = umbrella.umbrellaName.toLowerCase();

                for (const item of this.itemsSelected) {
                    
                        if ( item.umbrellaName.toLowerCase() == currentUmbrella ) {
                          
                          var lineItem = {}; 

                          lineItem['weekEnding']   = item['weekEnding'];
                          lineItem['worker']       = item['fullName'];
                          lineItem['endUser']      = item['portmanId'];
                      
                          lineItem['netPay']       = item['netPay'].toFixed(2);       // amount candidate units      EX VAT
                          lineItem['netMiles']     = item['netMileage'].toFixed(2);   // amount candidate mileage    EX VAT
                          lineItem['netExpense']   = item['netExpense'].toFixed(2);   // amount candidate expense    EX VAT
                          
                          // amount umbrella would be charged
                          lineItem['UmbrellaCharge']       = item['netCharge'].toFixed(2);
                          lineItem['UmbrellaChargeVat']    = this.calculateLineItemVAT   ( lineItem['UmbrellaCharge'],  umbrella['vatRate'] )
                          lineItem['umbrellaChartTotal']   = this.calculateLineItemTotal ( lineItem['UmbrellaCharge'],  lineItem['UmbrellaChargeVat']     );
                          ////////////////////////////////// 
                          // amount umbrella would be charged
                          

                          // amount candidate submission /////
                          ////////////////////////////////////
                          let candidateNetCharge  = item['netPay'] + item['netMileage'] + item['netExpense'];
                          
                          lineItem['charge']      = candidateNetCharge.toFixed(2);
                          lineItem['VAT']         = this.calculateLineItemVAT   ( lineItem['charge'],  umbrella['vatRate'] );
                          lineItem['total']       = this.calculateLineItemTotal ( lineItem['charge'],  lineItem['VAT']     );
                          ////////////////////////////////// 
                          // amount candidate submission  //
                          //////////////////////////////////

                          

                          lineItem['strChargeRate']     = ( item['chargeRate']  ) ? item['chargeRate']   :  globalSettings['chargeRate'];
                          lineItem['strMileageRate']    = ( item['mileageRate'] ) ? item['mileageRate']  :  globalSettings['mileageRate'];
                          lineItem['strPayRate']        = ( item['payRate'] )     ? item['payRate']      :  globalSettings['payRate'];
                          
                          lineItem['strHours']          = item['hours'];
                          lineItem['strMileage']        = item['mileage'];
                          lineItem['strExpenses']       = item['expenses'];
                  
                          invoice['items'].push(lineItem);

                                                  
                          invoice['subTotal']  += parseFloat( lineItem['charge'] );
                          invoice['VAT']       += parseFloat( lineItem['VAT']    );
                          invoice['total']     += parseFloat( lineItem['total']  );
                
                        }
                }

                invoice['subTotal']  = invoice['subTotal'].toFixed(2);
                invoice['VAT']       = invoice['VAT'].toFixed(2);
                invoice['total']     = invoice['total'].toFixed(2);
                return invoice;
        },
  
        
        async sendUmbrellaInvoice( invoice ) {

            console.log( "Emailing Self Bill Invoice : ", invoice );
                 
            try {
              
                  const  baseUrl        =  apiResource.getResourceUrl( 'email' );
                  const  url            = `${baseUrl}/umbrella/invoice`;
                  const  result         = await axios.post( url, invoice);
                  console.log(result);

            } catch (error) {

                   this.store.toastDialog.subtext   = "SendUmbrellaInvoice";
                   this.store.toastDialog.text      = error.response.data.message;
                   this.store.toastDialog.show      = true;
            }
        },

        async sendUmbrellaInvoicePreview( invoice ) {

                console.log( "Emailing (Preview) Self Bill Invoice : ", invoice );
                try {
                  
                      const  baseUrl        =  apiResource.getResourceUrl( 'email' );
                      const  url            = `${baseUrl}/umbrella/invoicePreview`;
                      const  result         = await axios.post( url, invoice);
                      console.log(result);

                } catch (error) {

                      this.store.toastDialog.subtext   = "SendUmbrellaInvoicePreview";
                      this.store.toastDialog.text      = error.response.data.message;
                      this.store.toastDialog.show      = true;
                }
        },

        async approveSelectedItems() {
      
                  try {
            
                    for (const item of this.itemsSelected) {
                            this.itemBeingProcessed = item;
                            let res  = await apiResource.sendSubmissionEmail(  "Approved",  item);
                            if (res.status === 200) {
                                  await this.approveSelectedItem( item )
                                  this.itemProcessedCount += 1;
                                  this.itemPercentageProcessed = (this.itemProcessedCount / this.itemsSelected.length) * 100;
                            }
                    }
    
                  } catch (error) {

                      this.store.toastDialog.subtext   = "Approve Selected Items Error";
                      this.store.toastDialog.text      = error.response.data.message;
                      this.store.toastDialog.show      = true;
                  
                    }
                  
                  return true;
        },
 
        async approveSelectedItem( item ) {

                try {

                    const  baseUrl                =  apiResource.getResourceUrl( 'approval' );
                    const  approvalUrl            = `${baseUrl}`;

                    const  closedSubmission       =  apiResource.getResourceUrl( 'closedSubmission' );
                    const  closedSubmissionUrl    = `${closedSubmission}`;

                    await axios.post( approvalUrl,          item );
                    await axios.post( closedSubmissionUrl,  item );


                } catch (error) {

                    console.log(error);
                    this.store.toastDialog.subtext   = "approve Item";
                    this.store.toastDialog.text      = error.response.data.message;
                    this.store.toastDialog.show      = true;
                }

                return true;
        },

        async getUmbrellas() {

              var temp = [];

              try {

                  const  baseUrl        =  apiResource.getResourceUrl( 'umbrella' );
                  const  url            = `${baseUrl}`;
                  var    res            = await  axios.get( url );
                  if ( res.data )
                      temp = res.data.res
                
              } catch (error) {

                  console.log(error);
                  this.store.toastDialog.subtext   = "get Umbrellas";
                  this.store.toastDialog.text      = error.response.data.message;
                  this.store.toastDialog.show      = true;
              }

            
              return temp;
        },

        async getGlobalSettings() {
            
          var temp = [];

              try {

                const  baseUrl        =  apiResource.getResourceUrl( 'setting' );
                const  url            = `${baseUrl}`;
                var    res            = await  axios.get( url );
                if ( res.data )
                    temp = res.data.res
                
              } catch (error) {

                  console.log(error);
                  this.store.toastDialog.subtext   = "get Umbrellas";
                  this.store.toastDialog.text      = error.response.data.message;
                  this.store.toastDialog.show      = true;
              }

              return temp;
        },

        async addSubmission(editedItem){

              const  baseUrl      =  apiResource.getResourceUrl( "submission" );
              const  url          = `${baseUrl}`;
               
              try {

                   editedItem['status']  = 'accepted';
                   await axios.post( url, editedItem );
                   await this.refreshDataTables();
              
              } catch (error) {

                  console.log(error);
                  store.toastDialog.subtext   = "Insert Resource Error";
                  store.toastDialog.text      =  error.response.data.message;
                  store.toastDialog.show      =  true;
              }

              return true;


        },

        async lookup(){ 
           
           try {
              
                  const  baseUrl       =  apiResource.getResourceUrl( "lookup" );
   
                  this.lookupLoading    = true;
                     const response       =  await axios.post(baseUrl, this.editedItem);
                  this.lookupLoading     = false;
          
                  if ( response.data.status ) {
   
                         this.editedItem.userId              = response.data.res.id;
                         this.editedItem.fullName            = response.data.res.fullName;
                         this.editedItem.portmanId           = response.data.res.portmanId;
                         this.editedItem.email               = response.data.res.email;
   
                         this.editedItem.status              = "pending";
                         this.editedItem.submitter           = "Adminstrator";
                         this.editedItem.rollOver            = false;
                         this.editedItem.weekEnding          = this.endofWeek;
                         
                   } else {
   
                       store.toastDialog.subtext   = "Lookup Candidate";
                       store.toastDialog.text      = "Candidate Not Found";
                       store.toastDialog.show      = true;   
                   }
   
           } catch ( error) {
               
                   console.log(error);
                   store.toastDialog.subtext   = "Lookup Candidate";
                   store.toastDialog.text      =  error.response.data.message;
                   store.toastDialog.show      =  true;
           }

           return true;
   
        },
      
        printTable() {

            this.printerLoading = true;
            setTimeout( () => { 
                    this.printerLoading = false;
            }, 1000*5);


        },

        setFilter( data ) {

            this.filter = data.toLowerCase();
            this.refreshDataTables();
        },

        async refreshDataTables() {
           
            this.tableLoading = true;
                 await this.getApprovalItems     ();
                 await this.getNotSubmittedItems (); 
            this.tableLoading = false;

            return true;

        },
    
        async getApprovalItems() {
      
                console.log( "getApprovalItems");
                
                let  baseUrl      =  apiResource.getResourceUrl( 'approval' );

                try {
     
                    const  url          = `${baseUrl}`;
                    const response      = await axios.get( url );
                    
                    if ( response.data ) {

                          var items = response.data;
                          items.forEach( (item) => {
                                apiResource.itemCosting(item);
                          });
                          
                          this.approvalItems = items;
                    }

                } catch (error) {

                    console.log(error);
                    this.store.toastDialog.subtext   = "Insert Resource Error";
                    this.store.toastDialog.text      = error.response.data.message;
                    this.store.toastDialog.show      = true;
                }

        },
     
        async getNotSubmittedItems() {
      
                    console.log( "getNotSubmittedItems");

                    let  baseUrl        =  apiResource.getResourceUrl( 'notsubmitted' );
                  
                    try {

                        const  url          = `${baseUrl}`;
                        const response      =  await axios.get( url );
                        if ( response.data ) {
                             this.notSubmittedItems = response.data;
                        }

                    } catch (error) {

                        console.log(error);
                        this.store.toastDialog.subtext   = "Insert Resource Error";
                        this.store.toastDialog.text      = error.response.data.message;
                        this.store.toastDialog.show      = true;
                    }

                    return true;
                  
        },
          
        formatInvoiceDate() {
               const isoString = new Date().toISOString();
               const options   = { month: "long", day: "numeric", year: "numeric",timeZone: "Europe/London" };
               const date      = new Date(isoString);
               let ukDate      = new Intl.DateTimeFormat("en-GB", options).format(date);
               return ukDate;
        },
  

        formatDate(item) {

                let     date         = new Date( item );
                const   options      = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',timeZone: 'Europe/London'};
                let     intlDateObj  = new Intl.DateTimeFormat('en-GB', options);
                let     gbTime       = intlDateObj.format(date);
                return  gbTime;
        },
          
          formatRollOver(item) {
              return (item == 0 ) ? "No" : "Yes"
          },

          getStatusColor(status) {
            
            const statusColor = {
                            pending   : "pendingText",
                            declined  : "declinedText",
                            accepted  : "acceptedText"
            };

            return statusColor[status]
          },
          
          async addItem (item) {

            this.dialogAdd  =  true

            if ( item ) {           
             
                 this.editedItem.portmanId = item.portmanId;
                 await this.lookup();
            }

 
          },

          async addItemAction  ( confirmed ) {  
        
            if ( confirmed) {
                this.dialogLoading  = true;
                  await this.addSubmission( this.editedItem );
                this.dialogLoading  = false;
            } 

            this.dialogAdd     = false;

            this.$nextTick(() => {
                this.editedItem  = Object.assign({}, this.defaultItem)
            })

          },

      }

  }

</script>




<style>

.titleColor {
   background-color: #B9802B !important;
   color: white
}

.simple { 
  background-color: none !important;
}

.simple:hover {
  background-color: none !important;
}

.declinedText {
  color: red;
}
.pendingText {
  color:  orange;
}
.acceptedText {
  color: green;
}

#submissionTable > div.v-data-table__wrapper > table > thead
{
  background-color: #DFC049 !important;
  border-radius: 6px 6px 6px 6px;
 
}

table {
  padding: 10px;
  background-color : rgb(248,248,248)
}


.on-hover{
  background-color: rgb(248,248,248);
  opacity: 0.08;
}

.font14{
  font-size: 14px !important
}

.custom-highlight-row{
   background-color: pink
}

.custom-highlight-row:hover{
   background-color: pink !important;
}

</style>
