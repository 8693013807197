<template>

  <div>
    
    <v-card class="rounded-0 halfFillHeader" elevation = "1">

        <v-card-text class="d-flex white--text align-center justify-center viewTitle">
                Access Request
        </v-card-text>

        <div class="d-flex align-center justify-center">
            <v-btn style="background-color: white!important;" disabled elevation="0" fab class="portmanfab portmanLogo">
            <v-img
            :src="require('@/assets/images/logos/portman.png')"
              max-height = "80px"
              contain
            ></v-img>
            </v-btn>
        </div>

    </v-card>
    
    <v-card class="rounded-0"  elevation = "1" style="padding: 20px;">

         <v-form ref="accessRequestForm" v-model="isValid">

            <v-text-field
              solo
              rounded
              v-model="credential"
              :rules="[ v => !!v || 'your email is required' ]"
              required
              label="email"
              placeholder="john@example.com"
            ></v-text-field>

            <div class="d-flex align-center justify-center">
            <v-btn 
              :loading  = "loading"
              :disabled = "!isValid"
              color='#b9802b'
              class="lightGoldBorder white--text"
              @click = "accessRequestClicked"
            >
              Submit Details
            </v-btn>
          </div>

         </v-form>
      
         <p class="pt-10 centerText black--text">
            Call us on <strong> {{store.portman.phone}}</strong> if you are stuck.
         </p>

    </v-card>

  </div>
    
</template>


<script>

import axios           from "axios";
import { store       } from '../store/store'
import { apiResource } from '../store/apiResource'

export default {
    name       : 'AccessRequest',
    methods    : {

      accessRequestClicked() {
       
       this.loading           = true;
       const url              =  apiResource.getResourceUrl( "accessRequest" );
       const data             = { "credential"  :  this.credential  } 
       
       axios.post(url, data).then( response => {
              console.log( response.data.status);
              if ( response.data.status == 'success') {
                    this.$router.push("/")
                    this.store.toastDialog.subtext   = "";
                    this.store.toastDialog.text      = "Access Request has been submitted, you will receive an email shortly.";
                    this.store.toastDialog.show      = true;
              } 
        });
      },
    },

 
    data() {
      return {
          store,
          loading           : false,
          isValid           : true,
          value             : false, 
          credential : '',
          card_text_1:  'Enter your e- mail and we',
          card_text_2:  'will send you a login'
       };
    },

}
</script>



<style>
    .whitetext {color: white !important; }
    .spacer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }
</style>


