<template>

 <v-dialog  v-model="store.globalDialog.show"  max-width="500px">
  
  <v-card>
  
    <v-card-title>Global Settings</v-card-title>
  
    <v-card-text>
      
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model ="store.globalDialog.settings.payRate"
              label="Global Pay Rate"
              type="number"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="store.globalDialog.settings.mileageRate"
              label="Global Mileage Rate"
              type="number"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="store.globalDialog.settings.chargeRate"
              label="Global Charge Rate"
              type ="number"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              placeholder = "global VAT rate"
              v-model="store.globalDialog.settings.vatRate"
              label ="VAT rate (%)"
              type  ="number"
            ></v-text-field>
          </v-col>
        
          <v-col cols="12" md="4">
            <v-text-field
              placeholder = "global MAX Hours"
              v-model="store.globalDialog.settings.maxHours"
              label ="Global Max Hours"
              type  ="number"
            ></v-text-field>
          </v-col>
          
          <v-col cols="12" md="4">
            <v-combobox
             v-model="store.globalDialog.settings.ucMethod"
             :items="ucMethods"
             label="UC Email Method"
        ></v-combobox>
      
          </v-col>
       
        
        </v-row>

    </v-card-text>
    
    <v-card-actions>
  
      <v-col class="text-right">
        <v-btn
          color="#B9802B"
          text
          @click = "closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
          color="#B9802B"
          text
          :loading = "loading"
          @click   = "saveSettings"
        >
          Save
        </v-btn>
      </v-col>
  
    </v-card-actions>

  </v-card>

 </v-dialog>

</template>

<script>

  import { store }            from '../../store/store'
  import { apiResource }      from '@/store/apiResource'
  import   axios              from "axios";

  export default {
        
        name: 'globalSettingDialog',

        mounted() {
           this.getSettings();
        },

        data () {
            return { 
                     store,
                     nogroup: '1',
                     ucMethods           : ['Email', 'CSV'],
                     settings            : [],
                     loading             : false,
         
                   }
            },

        methods : {
        
          getSettings : async function () {

                console.log("getSetting");

                const  baseUrl      =  apiResource.getResourceUrl( "setting" );
                const  url          = `${baseUrl}`;

                try {
                
                    const response = await axios.get( url );
                    if ( response.data.status ) {
                         this.store.globalDialog.settings = response.data.res;
          
                    }
          
                } catch (ex) {

                    console.log("error.status:", ex);
                    store.toastDialog.subtext   = "Get Global Settings Error";
                    store.toastDialog.text      = ex.message;
                    store.toastDialog.show      = true;
                 }
          },
          
          closeDialog() {
             this.store.globalDialog.show    = false;
          },
          
          saveSettings : async function () {
                
                  
                      try {
                        this.loading         = true;
                        const  baseUrl       =  apiResource.getResourceUrl( "setting" );
                        const  url           = `${baseUrl}`;
                        await  axios.put(url, this.store.globalDialog.settings );
                        this.loading         = false;

                      } catch ( ex ) {
              
                        console.log(ex );
                        store.toastDialog.subtext   = "save global settings Error";
                        store.toastDialog.text      =  ex.message;
                        store.toastDialog.show      = true;
                        this.loading                = false;
                      }
                  
                    this.store.globalDialog.show    = false;
          }

        },
       
  }

</script>


<style scoped>

.v-list {
    border:             1px dashed  !important;
    border-radius:      0px   !important;
    background-color:   white !important;
}
.titleColor {
   background-color: #B9802B !important;
   color: white
}

</style>
