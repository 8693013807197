import jsPDF        from 'jspdf'
import autoTable    from 'jspdf-autotable'
import moment      from 'moment'

export const apiShared = {

  generatePDF : function( reportName , weekEnding) {

      const title     =  'Report : ' + reportName;
      const rundate   =  'Generated : ' + moment().format('dddd, MMMM Do YYYY');
      const wkEnding  =  (weekEnding) ? 'Week Ending : ' + moment(weekEnding).format('dddd, MMMM Do YYYY') : '';


      const doc   = new jsPDF({orientation : 'landscape'});

      doc.setFontSize(12);
      doc.setTextColor(40);
     
      doc.text(15, 10, title);
      const textWidth = doc.getTextWidth(title); 
      doc.line(15, 12, 15 + textWidth, 12)
      doc.text(15, 20, rundate);
      doc.text(15, 25, wkEnding);


      autoTable(doc,  { 
                        html: 'div.v-data-table__wrapper > table:first-of-type',
                        theme: 'grid',
                        styles: { fontSize: 8 },
                        startY: 30,
                      }
                )

      doc.save(`${reportName}.pdf`)
  }

 }