<template>
    <v-row>
            <v-col cols="12">
                <AcceptedCrud />
            </v-col>
    </v-row>
</template>


<script>
 
import AcceptedCrud                     from '@/components/crud/AcceptedCrud.vue'
import { apiResource }                  from '@/store/apiResource'

export default {  

      components: { AcceptedCrud},
      name       : 'Accepted',
     
      mounted () {
         console.log("Accepted mounted");
      },
     
      beforeMount() {
        // clean up previous crud items 
        // before computed properties activate
         console.log("Accepted beforeMount")
         apiResource.viewDestroyed();
      },
}

</script>