<template>

  <div id="contentd">

        <v-app-bar 
          id="app-bar"
          hide-on-scroll
          :color="this.store.colors.darkGold"
          dark
        >
      
          <!-- Left Content -->
          <v-app-bar-nav-icon v-if = false
              @click.stop="drawer = !drawer"
              class="d-lg-none"
          ></v-app-bar-nav-icon>

          <AppBarUserMenuLeft></AppBarUserMenuLeft>
          
          <div class="d-inline-flex flex-column justify-center ms-3">
             <div>Welcome {{ userFullName }}</div>
          </div>

          <v-spacer></v-spacer>
   
          <!-- Right Content -->
          <AppBarReports></AppBarReports>
          <AppBarUserMenuRight></AppBarUserMenuRight>
    </v-app-bar>

    <v-navigation-drawer v-if = false
        app
        width="260"
        v-model="drawer">

        <v-list-item style="background-color: #DFC049">
         
          <v-list-item-avatar>
            <v-img  
            :src="require('@/assets/images/logos/portman.png')"  max-height = "70px" contain></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Portman Hours Admin </v-list-item-title>
            <v-list-item-subtitle>Portman Recruitment</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>

        <v-divider></v-divider>
      
        <v-list dense nav>

          <v-list-item v-for="item in items" :key="item.title" link :to="item.to">

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    
    </v-navigation-drawer>
    
    <div class="px-8 mt-8">
        <slot></slot>
    </div>

    <v-footer app
              fixed
              padless
              inset
              color="transparent">
              
      <v-col  class="text-center" cols="12">
          <span>
                &copy; 2022
                <a style="color: #DFC049"
                href="https://portmanrecruitment.com"
                class="text-decoration-none"
                target="_blank"
            >Portman Recruitment</a>
          </span>
      </v-col>
   </v-footer>
    
  </div>
  
</template>

<script>
    
    import { mdiMagnify, mdiBellOutline, mdiGithub, mdiAccountSearch } from '@mdi/js'
    import AppBarReports        from '@/layouts/AppBarReports.vue'
    import AppBarUserMenuRight  from '@/layouts/AppBarUserMenuRight.vue'
    import AppBarUserMenuLeft   from '@/layouts/AppBarUserMenuLeft.vue'

    
    import {store}         from '@/store/store'

    export default {
      
      components: {AppBarUserMenuRight,AppBarUserMenuLeft,AppBarReports},
      name      : 'layoutContent',
      
      mounted() {

          var pmrJson  = localStorage.getItem("pwadmin.pmr");
          if ( pmrJson ){
                var pmrObj          = JSON.parse(pmrJson);
                if ( pmrObj )
                    this.adminUserName  = pmrObj.fullName;
          }

      },

      computed: {
        userFullName() {
          return this.adminUserName;
        }
      },

      data() {
        return {
          adminUserName   : "N/A",
          store, 
          drawer          : true,
          barColor        : 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
      
          icons: {
            mdiMagnify,
            mdiAccountSearch,
            mdiBellOutline,
            mdiGithub,
          },
          backgroundClass : '',
        }
      }

    }
</script>


<style scoped>

.boxed-container {
  margin-left: auto;
  margin-right: auto;
}
</style>

