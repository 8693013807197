<template>
  
    <v-menu dark
            offset-y
            left
            nudge-bottom  = "14"
            min-width     = "230"
            content-class = "user-profile-menu-content"
            >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                     <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>

                <v-list class="dropMenuList" nav dense rounded>

                      <v-list-item link  @click.stop="store.globalDialog.show = true">
                                                    
                        <v-list-item-icon>
                           <v-icon size="22px">mdi-cog</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
                        
                 
                        <v-list-item-icon>
                           <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                           <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                                               
                      </v-list-item>


                </v-list>


    </v-menu>
      
</template>

<script>

import { store       }               from '@/store/store'

export default {

 
  methods : {},
  data () {
      return {
        store,
        items    : [
          {
             icon:  'mdi-view-dashboard',
             title: 'Dashboard',
             to:    '/dashboard',
          }, {
             icon:  'mdi-finance',
             title: 'Reports',
             to:    '/reports',
          }, {
             icon:  'mdi-account',
             title: 'Candidates',
             to:    '/users',
          }, {
             icon:  'mdi-security',
             title: 'Admins',
             to:    '/admins',
          }, {
             icon:  'mdi-account-multiple',
             title: 'Clients',
             to:    '/clients',
          }, {
             icon:  'mdi-umbrella',
             title: 'Umbrellas',
             to:    '/umbrellas',
          }, {
             icon:  'mdi-account-group',
             title: 'Consultants',
             to:    '/consultants',
          }, {
             icon:  'mdi-cloud-upload',
             title: 'Submissions',
             to:    '/submissions',
          },{
             icon:  'mdi-check-all',
             title: 'PMA',
             to:    '/pma',
          }, {
             icon:  'mdi-check-decagram',
             title: 'Approvals',
             to:    '/accepted',
          }, {
             icon:   'mdi-blender-software',
             title:  'Processed',
             to:     '/processedWeekly',
          },//{
            // icon:  'mdi-file-delimited',
            // title: 'Import Candidates',
            // to:    '/importCandidates',
           //}
         
        ],

      }
    },
  
}
</script>

<style lang="scss">


.noShadow {
  box-shadow: none !important;
  
}

.user-profile-menu-content {

  .v-list-item {
    min-height: 2.5rem !important;
  }

}
</style>
