<template>

        <v-card  @click="reportClicked"
          elevation="4"
          shaped
          tile
        >

        <v-list-item three-line>
          
          <v-list-item-content>
            <div class="text-overline mb-4">
              {{ reportTitle}}
            </div>
            <v-list-item-subtitle> {{ reportDescription}}</v-list-item-subtitle>
          </v-list-item-content>
          
          <v-list-item-avatar tile size="40" color="grey">
            <v-icon size="25">mdi-finance</v-icon>
          </v-list-item-avatar>

        </v-list-item>

        <v-card-actions>
   
          <v-btn text color="#B9802B" @click="reportClicked">
           View Report
          </v-btn>

         </v-card-actions>
      
        </v-card>

 
  
</template>

<script>

import {reportMap}   from '@/views/reports/widgets/reportMap.js'

export default {
  
    props           : ['resource'],
    data() {
          return {};
    },
    computed   : {
          reportTitle()       {  return reportMap.getTitle(       this.resource)        },
          reportDescription() {  return reportMap.getDescription( this.resource)        },
          reportRoute()       {  return reportMap.getRoute(       this.resource)        }
    },

     

    methods    : {
 
      reportClicked() {
          this.$router.push( this.reportRoute );
    }

  }
}
</script>


<style scoped>

.greeting-card {
  position: relative;
}

.pcrWidgetTitle {
  background-color: #DFC049 !important;
  border-radius: 10px;
}

.setEqual {
  min-height: 250px !important;
}

</style>
