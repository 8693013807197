<template>
  
  <v-row>
          <v-col cols="12" md="4">
             <reportWidget  resource = "umbrellaWeekly" />
          </v-col>
          <v-col cols="12" md="4">
             <reportWidget  resource = "processedWeekly" />
          </v-col>
          <v-col cols="12" md="4">
             <reportWidget  resource = "candidateVacation" />
          </v-col>
  </v-row>
     
</template>

<script>

import reportWidget                  from '@/views/reports/widgets/reportWidget.vue'

export default {
    components : { reportWidget},
    data () {  return {} },
  }

</script>