<template>
    
    <v-data-table
        :headers      = "headers"
        :items        = "filteredItems"
        :sort-by      = "sort"
        class         = "elevation-1"
        :loading      = "store.crudComponent.loading"
        loading-text  = "Data is Loading... Please wait"
    >
     
      <template v-slot:item.startDate ="{ item }">
          <strong>{{ formatSubmittedDate(item) }}</strong>
      </template>
   
      <template v-slot:top>
     
        <exportToCsvDialog :items= "filteredItems" title="Export Admins to CSV" gridName="Admins" />

        <v-toolbar flat>
          
          <v-toolbar-title class="text-capitalize">{{store.resource}}s</v-toolbar-title>
      
          <v-divider  class="mx-4" inset vertical></v-divider>

          <v-text-field
            rounded
            dense
            solo
            prepend-inner-icon ="mdi-magnify"
            class="app-bar-search flex-grow-0 mx-4"
            hide-details
             v-model = "filter"
            label    = "Filter ..."
         ></v-text-field>
 
         <v-divider  class="mx-4" inset vertical></v-divider>
         
         <v-spacer></v-spacer>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
          </v-tooltip>
                
          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                        <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>

                    </template>
                    <span>Create PDF</span>
          </v-tooltip>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="dialogEdit = true">
                        <v-icon color="#B9802B" size="28px">mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Administrator</span>
          </v-tooltip>
        
          <v-dialog   @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogEdit" max-width="500px">
            <v-card>
             
              <v-card-title><span class="text-capitalize text-h5">{{ formTitle }}</span></v-card-title>


              <v-form v-model="adminFormValid" ref="adminForm">

                <v-card-text>
                  
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.fullName"
                        label="Full Name"
                        :rules="adminFormRules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        :rules="adminFormRules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model = "editedItem.userName"
                        label   = "User Name"
                        :rules="adminFormRules.required"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model = "editedItem.password"
                        label   = "Password"
                        :append-icon   = "value ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append  = "() => (value = !value)"
                       :type           = "value ? 'password' : 'text'"
                       :disabled       = "isEditMode"
                       :rules="adminFormRules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.homePhone"
                        label="Home Phone"
                        :rules="adminFormRules.required"
                      ></v-text-field>
                    </v-col>
                
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.cellPhone"
                        label="mobile Phone"
                        :rules="adminFormRules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                                  
               </v-card-text>
              
               <v-card-actions>
            
                <v-col class="text-right">
                  <v-btn
                    color="#B9802B"
                    text
                    @click="editItemAction(false)"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="#B9802B"
                    text
                    :disabled  = "!adminFormValid"
                    :loading = "dialogLoading"
                    @click="editItemAction(true)"
                  >
                    Save
                  </v-btn>
                </v-col>
            
               </v-card-actions>

             </v-form>

            </v-card>
          </v-dialog>

          <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogDelete" max-width="500px">
            <v-card elevation="2">
              
              <v-card-title >Confirm Deletion of Admin ( {{editedItem.userName}} )</v-card-title>
              <v-divider></v-divider>
        
              <v-card-subtitle class="pt-2">
                   Administrator  : {{ editedItem.fullName }}<br/>
                   Email          : {{ editedItem.email }}
              </v-card-subtitle>
             
              <v-card-actions class="text-right">
                <v-col class="text-right">
                  <v-btn color="#B9802B" text  @click="deleteItemAction(false)">Cancel</v-btn>
                  <v-btn color="#B9802B" text  :loading = "dialogLoading" @click="deleteItemAction(true)">Delete</v-btn>
                </v-col>
              </v-card-actions>
         
            </v-card>
          </v-dialog>

          <v-dialog @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogPassword" max-width="500px">
            <v-card elevation="2">
              
              <v-card-title >Password Reset : ( {{editedItem.email}} )</v-card-title>

              <v-divider></v-divider>

              <v-card-subtitle class="pt-2">
                   Administrator  : {{ editedItem.fullName }}<br/>
                   Email          : {{ editedItem.email }}
              </v-card-subtitle>
                     
              <v-card-text>

                <v-form ref="resetPassword" v-model="formIsValid">

                <v-text-field
                  hint="Enter the Password for this Administrator"
                  autofocus
                  v-model = "password"
                  :rules="[ v => !!v || 'Password is required' ]"
                  required
                  label ="Password"
                  :append-icon    = "value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append   = "() => (value = !value)"
                  :type           = "value ? 'password' : 'text'"
                ></v-text-field>
            
                </v-form>

              </v-card-text>

              <v-card-text>
                This form will reset the password for the Administrator, the password is encrypted, as such you cannot retrieve the passport
              </v-card-text>


            
              <v-card-actions class="text-right">
                <v-col class="text-right">
                  <v-btn color="#B9802B" text  @click  = "passwordItemAction(false)">Cancel</v-btn>
                  <v-btn :disabled = "!formIsValid" 
                          color="#B9802B"
                          text
                          @click  = "passwordItemAction(true)"
                          :loading = "loading">Reset</v-btn>
                </v-col>
              </v-card-actions>
         
            </v-card>
          </v-dialog>


        </v-toolbar>

      </template>      
      
      <template v-slot:item.actions="{ item }">
        <v-menu dark offset-y left bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark color="#B9802B" v-bind="attrs" v-on="on">Manage
              </v-btn>
            </template>
            
            <v-list dark>
            <v-list-item  @click="editItem(item)">
              <v-icon class="mr-2">mdi-pencil</v-icon>
              <v-list-item-title>Edit <span class="text-capitalize">{{store.resource}}</span></v-list-item-title>
            </v-list-item>

            <v-list-item  @click="deleteItem(item)">
              <v-icon class="mr-2">mdi-delete</v-icon>
              <v-list-item-title>Delete <span class="text-capitalize">{{store.resource}}</span></v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

              <v-list-item  @click="resetPassword(item)">
                <v-icon class="mr-2">mdi-lock-reset</v-icon>
                <v-list-item-title>Reset Password</v-list-item-title>
              </v-list-item>

            </v-list>


        </v-menu>
      </template>
       
    </v-data-table>

</template>

<script>

  import { store }       from '@/store/store'
  import { apiResource } from '@/store/apiResource'
  import { apiShared }           from '@/store/apiShared'
  import  axios          from "axios";
  import  exportToCsvDialog     from '@/components/Dialogs/exportToCsvDialog.vue'

  
  export default {

    name          : 'AdminsCrud',
    components    : { exportToCsvDialog },
    data          : () => ({

      store,
      filter           : '',
      value            : true,

      dialogEdit       : false,
      dialogDelete     : false,
      dialogLoading    : false,
      dialogPassword   : false,


      adminFormValid : false,
      adminFormRules : { required: [value => !!value || "Required."] },

      password             : '',
      formIsValid          : false,
      loading              : false,

      editedIndex      : -1,
            
      editedItem: {
        id           : '',
        fullName     : '',
        email        : '',
        userName     : '',
        password     : '',
        homePhone    : '',
        cellPhone    : '',
      },
      
      defaultItem: {
        id           : '',
        fullName     : '',
        email        : '',
        userName     : '',
        password     : '',
        homePhone    : '',
        cellPhone    : '',
      },

    }),
    computed      : {

      filteredItems() {

        if ( this.filter.length > 2 ) {
            
            const filtereditems  = this.store.crudComponent.items.filter( item => {
               return item.fullName.toLowerCase().startsWith( this.filter.toLowerCase());
            })

            return filtereditems;
        } 

        return this.store.crudComponent.items;

      },

      headers() {
          return (this.store.crudComponent.items.length > 0) ? this.store.crudComponent.headers : []
      },

      
      
      sort() {
           return this.store.crudComponent.sort;
      },
      
      formTitle () {
         return this.editedIndex === -1 ? 'New Administrator' : 'Edit Administrator'
      },
     
      isEditMode() {
 
        if (this.editedIndex > -1)
            return true
        else
            return false
            
      }
    },

    methods: {


      generatePDF() {
        apiShared.generatePDF('Administrators');
      },

      formatSubmittedDate(item) {
         var date = new Date( item.startDate);
         return ( date.toLocaleDateString());
      },
    
      //////////////////////////
      // turn on the dialogs ///
      //////////////////////////

      resetPassword(item){
          this.editedIndex                =  this.store.crudComponent.items.indexOf(item)
          this.editedItem                 =  Object.assign( {}, item)
          this.dialogPassword             =  true
     },
      
      editItem (item) {
        this.editedIndex          =  this.store.crudComponent.items.indexOf(item)
        this.editedItem           =  Object.assign( {}, item)
        this.dialogEdit           =  true
     
        if (this.$refs.adminForm)
            this.$refs.adminForm.validate()
      },

      deleteItem (item) {
        this.editedIndex          =  this.store.crudComponent.items.indexOf(item)
        this.editedItem           =  Object.assign({}, item)
        this.dialogDelete         =  true
      },
      

      passwordItemAction : async function( confirmed ) {

          if ( confirmed) {

              this.loading        = true;
              const  baseUrl      =  apiResource.getResourceUrl( "password" );
              const  url          = `${baseUrl}/admin/${this.editedItem.id}`;

              try {
                  const  payload        = { "password" : this.password }
                  const  response       = await axios.post( url, payload );
                  this.loading          = false;

                  if ( response.data.status ) {
                      console.log(response.data.status)
                  } 

              } catch (error) {
            
                  console.log(error);
                  store.toastDialog.subtext   = "Insert Resource Error";
                  store.toastDialog.text      = error.response.data.message;
                  store.toastDialog.show      = true;
              }
          } 

          this.dialogPassword    =  false

      },


      async deleteItemAction ( confirmed ) {

          if ( confirmed) {
             this.dialogLoading  = true;
               await apiResource.deleteResource(this.editedItem,this.editedIndex);
             this.dialogLoading  = false;
          }
         
          this.dialogDelete    =  false
           
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
      },

      async editItemAction ( confirmed ) {
        
            if ( confirmed) {
                  this.dialogLoading  = true;

                  if (this.editedIndex > -1) {
                        await apiResource.updateResource(this.editedItem, this.editedIndex );
                  } else {
                        await apiResource.insertResource(this.editedItem );
                  }

                  this.dialogLoading  = false;
           } 

           this.dialogEdit     = false;

            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
      },


    },
  }
</script>