export const reportMap = {

  reports   :      {
                       "umbrellaWeekly"   : { 
                                            route:       "/umbrellaWeekly",
                                            title:       "Umbrella Weekly Report",
                                            description: "This Report will show the umbrella weekly totals"
                                          },
                        "processedWeekly" : { 
                                            route:       "/processedWeekly",
                                            title:       "Processed Weekly Report",
                                            description: "This Report will show the Weekly Processed submissions"
                                          },

                        "candidateVacation" : { 
                                            route:       "/candidateVacation",
                                            title:       "Candidates on holiday",
                                            description: "This Report shows candidates that on holiday"
                                          },


  },
  
  
  getTitle : function ( resource ) {
    return this.reports[ resource ].title;
  },

  getDescription : function ( resource) {
     return this.reports[ resource].description;
  },
  getRoute : function ( resource) {
    return this.reports[ resource].route;
 }

 }