<template>
        
  <v-row>

        <v-dialog      @keydown.esc   = "editSubmissionAction( false )"
                       @click:outside = "editSubmissionAction( false )"
                       v-model        = "showSubmissionEditDialog"
                       max-width      = "500px">
                
                       <v-card>
                
                          
                        <v-card-title>
                            <v-checkbox   readonly
                                          hint    = "submission is pending managers approval"
                                          v-model = "editedItem.pma"
                                          label  =  "Submission is 'Pending Manager Approval' (PMA) "
                            ></v-checkbox>
                        </v-card-title>


                          <v-card-subtitle>Submission status : <b   class="text-capitalize">{{editedItem.status}}</b> </v-card-subtitle>
                          

                            <v-card-subtitle class="font-weight-bold">
                             If you have received managers approval for this submission you can remove the pending managers approval
                             flag by clicking on this
                              <a class="mylink" @click="editSubmissionAction( true )"
                              >Received Managers Approval</a>
                              link.
                           </v-card-subtitle>

                           <v-divider></v-divider>
                   
                          <v-form v-model="submissionFormValid" ref="submissionForm">
 
                            <v-card-text>

                              
                              <v-row  dense>

                                <v-col cols="12" md="8">
                                  <v-text-field
                                    readonly
                                    v-model="editedItem.fullName"
                                    label="Candidate Name"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                  <v-text-field
                                    readonly
                                    v-model  = "editedItem.portmanId"
                                    label     = "Portman Id"
                                  ></v-text-field>
                                </v-col>

                              </v-row>

                              <v-row dense>

                                <v-col cols="12">
                                  <v-text-field
                                    readonly
                                    v-model="editedItem.email"
                                    label="Candidate Email"
                                  ></v-text-field>
                                </v-col>

                              </v-row>

                              <v-row dense>
                                
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    readonly
                                    v-model="editedItem.hours"
                                    label="Work Units"
                                    :rules="submissionFormRules.required"
                                  ></v-text-field>
                                </v-col>
                              
                                <v-col cols="12"  md="4">
                                  <v-text-field
                                    readonly
                                    v-model = "editedItem.mileage"
                                    label   = "Mileage"
                                    :rules="submissionFormRules.required"
                                  ></v-text-field>
                                </v-col>
                            
                                <v-col cols="12"  md="4">
                                  <v-text-field
                                    readonly
                                    v-model = "editedItem.expenses"
                                    label   = "Expenses"
                                    :rules="submissionFormRules.required"
                                  ></v-text-field>
                                </v-col>

                              </v-row>
                            
                              <v-row dense>
                          
                                <v-col cols="12">
                                      <v-text-field
                                        :value="computedDateFormattedMomentjs"
                                        label="Submission for week ending"
                                        readonly
                                      ></v-text-field>
                                </v-col>

                              </v-row>

                              <v-row dense>
                              </v-row>

                            
                            </v-card-text>
        
                             <v-card-actions>
                        
                            <v-col class="text-right">
                              <v-btn
                                class="text-capitalize"
                                color="#B9802B"
                                text
                                @click="editSubmissionAction( false )"
                              >close
                              </v-btn>


                            </v-col>
                        
                            </v-card-actions>

                          </v-form>

                      </v-card>
      
        </v-dialog>
     
        <v-col cols="12">
           <v-data-table
              
              :headers       = "gridHeaders"
              :items         = "gridItems"
              :search        = "gridFilter"

              class          = "elevation-1"
              :loading       = "gridLoading.state"
              :loading-text  = "gridLoading.text"
              show-expand
              single-expand
              expand-icon   = "mdi-information"
          >
     
            <template v-slot:top>

              <exportToCsvDialog :items= "gridItems" title="Export PMA Submissions to CSV" gridName="PMA"/>

              <v-toolbar flat>
               
                  <v-toolbar-title class="text-capitalize">{{gridTitle}}</v-toolbar-title>
                  <v-divider  class="mx-4" inset vertical></v-divider>
                  
                  <v-text-field
                    single-line
                    rounded
                    dense
                    solo
                    prepend-inner-icon ="mdi-magnify"
                    class="app-bar-search flex-grow-0 mx-4"
                    hide-details
                    v-model  = "gridFilter"
                    label    = "Filter Rows ..."
                  ></v-text-field>

                  <v-divider  class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
                  </v-tooltip>
                
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                        <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>

                    </template>
                    <span>Create PDF</span>
                  </v-tooltip>
             
              </v-toolbar>

            </template>

            <template v-slot:item.actions="{ item }">
               <v-btn class="text-capitalize" initial dark color="#B9802B"  @click="editSubmission( item )">Manage</v-btn>
            </template>

            <template v-slot:item.rollOver = "{ item }">
              <v-simple-checkbox :value = "item.rollOver" disabled></v-simple-checkbox>
            </template>
       
            <template v-slot:item.pma      = "{ item }">
              <v-simple-checkbox :value = "item.pma" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.status="{ item }">
              <span :class="getStatusColor(item.status)" class="text-capitalize">{{ item.status }}</span>
            </template>
    
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  
                      <div class="row">

                        <div class="column pt-5 pb-3">
                          <div class="pb-1 pl-1">Candidate Email : {{ item.email }}</div>
                          <div class="pb-1 pl-1">Portman Id : {{ item.portmanId }}</div>
                          <div class="pb-1 pl-1">Date Submitted  : {{ formatDate(item.created_at) }}</div>
                          <div class="pb-1 pl-1">Submitted By    : {{ item.submitter }}</div>
                          <div :class="(item.rollOver === true) ? 'rollOverText' : ''" class="pb-1 pl-1">Rollover Submission  : {{ formatRollOver(item.rollOver)  }}</div>
                          <div :class="(item.pma      === true) ? 'PMAText'      : ''" class="pb-1 pl-1">Pending Manager Approval (PMA)  : {{ formatPMA(item.PMA)  }}</div>
                          <div class="pb-1 pl-1">Week Ending     : {{ formatWkEnding(item.weekEnding)    }}</div>
                        </div>

                        <div class="column pt-5 pb-3">
                          <div class="pb-1 pl-1">Net Pay     : {{ item.netPay     | toCurrency  }}</div>
                          <div class="pb-1 pl-1">Net Mileage : {{ item.netMileage | toCurrency  }}</div>
                          <div class="pb-1 pl-1">Net Charge  : {{ item.netCharge  | toCurrency  }}</div>
                          <div class="pb-1 pl-1">Net Margin  : {{ item.netMargin  | toCurrency  }}</div>
                        </div>

                        <div class="column pt-5 pb-3">
                        </div>

                      </div>



                </td>
           </template>

          </v-data-table>

        </v-col>
   
  </v-row>

</template>

<script>

import { store }               from '@/store/store'
import { apiResource }         from '@/store/apiResource'
import { apiShared   }         from '@/store/apiShared'

import axios                   from "axios"
import moment                  from 'moment'
import exportToCsvDialog       from '@/components/Dialogs/exportToCsvDialog.vue'


export default {  

      components    : { exportToCsvDialog },

      mounted () {
        console.log("PMA view mounted");
        this.initGrid();
      },

      filters : {
            
           toCurrency: function (value) {
              var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
              return formatter.format( value );
            }
      },

      methods   : {
  
        generatePDF() {
            apiShared.generatePDF( `PMA` );
        },


        formatDate( item ) {
            let   date         = new Date( item);
            const options    = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',timeZone: 'Europe/London'};
            let   intlDateObj  = new Intl.DateTimeFormat('en-GB', options);
            let   gbTime      = intlDateObj.format(date);
            return  gbTime;
        },

        formatWkEnding( item ) {

            let date         = new Date( item);
            const options    = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',timeZone: 'Europe/London'};
            let intlDateObj  = new Intl.DateTimeFormat('en-GB', options);
            let gbTime       = intlDateObj.format(date);
            return  gbTime;

        },

        formatPMA( item ) {
             return (item === false ) ? "No" : "Yes"
        },

        formatRollOver( item ) {
             return (item == false ) ? "No" : "Yes"
        },

        getStatusColor( status ) {
        
              const statusColor = {
                              pending   : "pendingText",
                              declined  : "declinedText",
                              accepted  : "acceptedText"
              };

              return statusColor[status]
        },
        
        async initGrid () {
      
              try {

                    this.gridLoading.state = true;
                    this.gridHeaders       = apiResource.getResourceHeader ("pma");
                    const resourceURL      = apiResource.getResourceUrl    ("pma")
  
                    const response         = await axios.get( resourceURL );
                    if ( response.data.status ) {

                           var items = response.data.res;
                           items.forEach( (item ) => { 
                               apiResource.itemCosting(item)
                           }) 
                           this.gridItems      =  items;
                   }


              } catch (error) {
                      console.log(error);
                      store.toastDialog.subtext   = "Insert Resource Error";
                      store.toastDialog.text      = error.response.data.message;
                      store.toastDialog.show      = true;

              } finally {
                    this.gridLoading.state  = false;
              }        
        },
        
        async updateSubmission       ( submission ) {

               ///////////////////////////////////////////////
              // update the PMA setting for this submission//
              ///////////////////////////////////////////////
              const  baseUrl       =  apiResource.getResourceUrl( "pma" );
              const  url           = `${baseUrl}/${submission.id}`;

              try {
                   await axios.put( url, submission );
                   await this.initGrid();
              } catch (error) {
                  store.toastDialog.subtext   = "Update Submission Error";
                  store.toastDialog.text      = error.response.data.message;
                  store.toastDialog.show      = true;
              }

        },
                      
        async editSubmission         ( submission ) {

                    this.editedIndex                   =  this.gridItems.indexOf(submission)
                    this.editedItem                    =  Object.assign( {}, submission)
                    this.showSubmissionEditDialog      =  true
        },

        async editSubmissionAction   ( confirmed ) {

                      if ( confirmed ) {
                          /////////////////////////////////////////////
                          // remove the PMA flag from this submission//
                          /////////////////////////////////////////////
                          this.gridLoading.state  = true;
                          this.editedItem.pma      = false;
                          
                          await this.updateSubmission( this.editedItem );
                          this.gridLoading.state  = false;
                      } 

                      this.showSubmissionEditDialog  = false;

                      this.$nextTick(() => {
                          this.editedItem  = Object.assign({}, this.defaultItem)
                          this.editedIndex = -1
                      })
        },

     
      },

      computed : {

        computedDateFormattedMomentjs () {
                return (this.editedItem.weekEnding) ? moment(this.editedItem.weekEnding).format('dddd, MMMM Do YYYY') : ''
        },

      },

      data      : () => ({

        store,
   
        gridItems        : [],
        gridHeaders      : [],
        gridFilter       : "",

        gridTitle        : 'Pending Manager Approval',
        gridLoading      : {  state : true, text : "Data is Loading... Please wait"  },
        btnLoading       : false,

        editedIndex : -1,
        
        editedItem: {

                id             : '',
                fullName       : '',
                email          : '',
                userId         : '',
                portmanId      : '',
                homePhone      : '',
                cellPhone      : '',
                hours          : '',
                mileage        : '',
                expenses       : '',
                status         : '',
                weekEnding     : '',
                rollOver       : '',
                pma            : ''
        },

        defaultItem : {
              id             : '',
              fullName       : '',
              email          : '',
              userId         : '',
              portmanId      : '',
              homePhone      : '',
              cellPhone      : '',
              hours          : '',
              mileage        : '',
              expenses       : '',
              status         : '',
              weekEnding     : '',
              rollOver       : false,
              pma            : false,
        },

        showSubmissionEditDialog : false,
        submissionFormValid      : false,
        submissionFormRules      : { required: [value => !!value || "Required."] },
        menu                     : false,
        dialogLoading            : false,
     
      })
    
}

</script>

<style>

.declinedText {
  color: red;
}
.pendingText {
  color:  orange;
}
.acceptedText {
  color: green;
}

.rollOverText {
  color: red;
}

.PMAText {
  color: red;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.mylink {
  color:blue !important
}
.mylink:hover {
  color:lightblue!important
}



</style>