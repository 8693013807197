//import { reactive } from 'vue'
//export const store = reactive({

export const store = {
  
    portman                            : {
                                          phone   : "0161 818 2423", 
                                          address : ""
                                         },

    version                             : "4.31",
    user                                : null,
    resource                            : null,   
    
    exportToCsvDialog                   : false,
   
    toastDialog                         : { 
                                            "title"      : "Portman Hours Admin",
                                            "show"       : false,
                                            "subtext"    : "",
                                            "text"       : ""
                                          },
    crudComponent                       : {
                                             headers    : [],
                                             items      : [],   
                                             sort       : "",
                                             loading    : false,
                                             saving     : false,
                                          },
    colors                              : {
                                            darkGold  : '#B9802B',
                                            lightGold : '#DFC049'
                                          },
                                          
    uiArrays                              : {
                                             userType       : ['Candidate', 'Administrator'],
                                             consultant     : [],
                                             client         : [],
                                             umbrella       : [],
                                             users          : [],
                                             candidate      : [],
                                            },
    dashboardCount                        : {
                                                consultant     : 0,
                                                client         : 0,
                                                umbrella       : 0,
                                                user           : 0,
                                                submission     : 0,
                                                admin          : 0,
                                            },
  
    globalDialog                          : {                                              
                                               show         : false,
                                               loading      : false,
                                               settings     : {}, 
                                            },

 }