
export const acceptedGridHeaders = {

    headers : {
 
        "processed"   : [
 
            { text: 'Worker',           value: 'fullName',              sortable: true  },
            { text: 'Umb',              value: 'umbrellaName',          sortable: true  },
            { text: 'Client',           value: 'clientName',            sortable: true  },
            { text: 'Units',            value: 'hours',                 sortable: true  },
            { text: 'Miles',            value: 'mileage',               sortable: true  },
            { text: 'NetPay',           value: 'netPay',                sortable: true  },
            { text: 'Mileage',          value: 'netMileage',            sortable: true  },
            { text: 'Exes',             value: 'netExpense',            sortable: true  },
            { text: 'NetCharge',        value: 'netCharge',             sortable: true  },
            { text: 'NetMargin',        value: 'netMargin',             sortable: true  },
            { text: 'Cons',             value: 'consultantName',        sortable: true  },
            { text: 'RollOver',         value: 'rollOver',              sortable: true  },
            { text: 'Submitter',        value: 'submitter',             sortable: true  },
            { text: 'WkEnding',         value: 'weekEnding',            sortable: true  },
       
            { text: 'id',               value: 'id',            align: ' d-none'  },
            { text: 'userId',           value: 'userId',        align: ' d-none'  },
            { text: 'Portman Id',       value: 'portmanId',     align: ' d-none'  },
            { text: 'Status',           value: 'status',        align: ' d-none' },
            { text: 'Email',            value: 'email',         align: ' d-none' },
            { text: 'Submitted',        value: 'created_at',    align: ' d-none' },
       
        ],

        "submission" : [

            { text: '',                 value: 'data-table-expand'               }, 
            { text: 'Portman Id',       value: 'portmanId',       sortable: true  },
            { text: 'Full Name',        value: 'fullName',        sortable: true  },
          
            { text: 'Units',            value: 'hours',          sortable: true  },
            { text: 'Miles',            value: 'mileage',        sortable: true  },

            { text: 'NetPay',        value: 'expenses',       sortable: true  },
            { text: 'Mileage',       value: 'expenses',        sortable: true  },
            { text: 'Exes',          value: 'expenses',       sortable: true  },
            { text: 'NetCharge',     value: 'expenses',       sortable: true  },
            { text: 'NetMargin',     value: 'expenses',        sortable: true  },

            { text: 'RollOver',         value: 'rollOver',       sortable: true  },
          
            { text: 'Approve',          value: 'data-table-select' },   

            { text: 'Umb',              value: 'umbrellaName',     align: ' d-none' },
            { text: 'Client',           value: 'clientName',       align: ' d-none' },
            { text: 'Cons',             value: 'consultantName',   align: ' d-none' },
            { text: 'id',               value: 'id',            align: ' d-none'  },
            { text: 'userId',           value: 'userId',        align: ' d-none'  },
            { text: 'Status',           value: 'status',        align: ' d-none' },
            { text: 'Email',            value: 'email',         align: ' d-none' },
            { text: 'Submitted',        value: 'created_at',    align: ' d-none' },
            { text: 'Week Ending',      value: 'weekEnding',    align: ' d-none' },
            { text: 'Submitter',        value: 'submitter',     align: ' d-none' },
        ],
    
        "approval" : [
 
            { text: '',                 value: 'data-table-expand',  }, 
            { text: 'Worker',           value: 'fullName',          sortable: true  },
            { text: 'Umb',              value: 'umbrellaName',          sortable: true  },
            { text: 'Client',           value: 'clientName',            sortable: true  },

            { text: 'Units',            value: 'hours',             sortable: true  },
            { text: 'Miles',            value: 'mileage',           sortable: true  },


            { text: 'NetPay',           value: 'netPay',            sortable: true  },
            { text: 'Mileage',          value: 'netMileage',        sortable: true  },
            { text: 'Exes',             value: 'netExpense',        sortable: true  },
            { text: 'NetCharge',        value: 'netCharge',         sortable: true  },
            { text: 'NetMargin',        value: 'netMargin',         sortable: true  },
         
            
            { text: 'Cons',             value: 'consultantName',        sortable: true  },
       
            { text: 'RollOver',         value: 'rollOver',           sortable: true  },
            { text: 'Approve',          value: 'data-table-select'  },  
        
            { text: 'id',               value: 'id',            align: ' d-none'  },
            { text: 'userId',           value: 'userId',        align: ' d-none'  },
            { text: 'Portman Id',       value: 'portmanId',     align: ' d-none'  },
            { text: 'Status',           value: 'status',        align: ' d-none' },
            { text: 'Email',            value: 'email',         align: ' d-none' },
            { text: 'Submitted',        value: 'created_at',    align: ' d-none' },
            { text: 'Week Ending',      value: 'weekEnding',    align: ' d-none' },
            { text: 'Submitter',        value: 'submitter',     align: ' d-none' },
        ],
            
        "declined" : [

            { text: '',                 value: 'data-table-expand'                    }, 
            { text: 'Worker',           value: 'fullName',            sortable: true  },
            { text: 'Umb',              value: 'umbrellaName',        sortable: true  },
            { text: 'Client',           value: 'clientName',          sortable: true  },

            { text: 'Units',            value: 'hours',           sortable: true  },
            { text: 'Miles',            value: 'mileage',         sortable: true  },

            { text: 'NetPay',           value: 'netPay',            sortable: true  },
            { text: 'Mileage',          value: 'netMileage',        sortable: true  },
            { text: 'Exes',             value: 'netExpense',        sortable: true  },
            { text: 'NetCharge',        value: 'netCharge',         sortable: true  },
            { text: 'NetMargin',        value: 'netMargin',         sortable: true  },
 
            { text: 'Cons',             value: 'consultantName',        sortable: true  },
            { text: 'RollOver',         value: 'rollOver',              sortable: true  },

            { text: 'Portman Id',       value: 'portmanId',     align: ' d-none'  },
            { text: 'id',               value: 'id',            align: ' d-none'  },
            { text: 'userId',           value: 'userId',        align: ' d-none'  },
            { text: 'Status',           value: 'status',        align: ' d-none' },
            { text: 'Email',            value: 'email',         align: ' d-none' },
            { text: 'Submitted',        value: 'created_at',    align: ' d-none' },
            { text: 'Week Ending',      value: 'weekEnding',    align: ' d-none' },
            { text: 'Submitter',        value: 'submitter',     align: ' d-none' },
        ],
    
        "notsubmitted" : [

            { text: '',                 value: 'data-table-expand'              }, 
            { text: 'Portman Id',       value: 'portmanId',          sortable: true  },
            { text: 'Full Name',        value: 'fullName',           sortable: true  },
            { text: 'Email',            value: 'email',              sortable: true  },
            { text: 'Home Phone',       value: 'homePhone',          sortable: true  },
            { text: 'Cell Phone',       value: 'cellPhone',          sortable: true  },
            { text: 'Submission',       value: 'actions',            sortable: false },
            { text: 'id',               value: 'id',                 align: ' d-none' },
        ],
    },

    getGridHeaders    : function( filter ) {
        return ( this.headers[ filter ] );
    },
    
}