<template>

  <div class="my-auto mx-auto myBox">
      <slot></slot>
   </div>
   
</template>


<script>

  export default {
    name  : 'layoutBlank',
    data() {  return {} }
  }

</script>

<style scoped>
.myBox {
    width: 80%;
    max-width: 600px;

    height: 400px;
    max-height: max-content;
}
</style>