import { render, staticRenderFns } from "./globalSettingDialog.vue?vue&type=template&id=4e83a178&scoped=true"
import script from "./globalSettingDialog.vue?vue&type=script&lang=js"
export * from "./globalSettingDialog.vue?vue&type=script&lang=js"
import style0 from "./globalSettingDialog.vue?vue&type=style&index=0&id=4e83a178&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e83a178",
  null
  
)

export default component.exports