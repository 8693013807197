<template>

  <v-menu
    offset-y
    left
    nudge-bottom  = "14"
    min-width     = "230"
    content-class = "user-profile-menu-content"
  >

    <template v-slot:activator="{ on, attrs }">

     <v-badge
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
        >

       <v-avatar
          size="40"
          v-bind="attrs"
          v-on="on"
        >
 
        <v-icon size="32px" >mdi-account</v-icon>
 
        </v-avatar>
 
     </v-badge>

    </template>

     <v-list dark nav>

      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon> 
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
        <v-list-item-title>{{  userFullName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
              

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item v-if = false link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <!-- Email -->
      <v-list-item v-if = false link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Chat -->
      <v-list-item v-if = false link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action>
      </v-list-item>

    
      <!-- FAQ -->
      <v-list-item v-if = false link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item
        link
        @click.stop="logoff"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
     </v-list>

  </v-menu>

  
</template>

<script>

import { store }  from '@/store/store'

import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {

  mounted() {

    var pmrJson  = localStorage.getItem("pwadmin.pmr");
    
    if ( pmrJson ){
     
          var pmrObj          = JSON.parse(pmrJson);
          if ( pmrObj )
              this.adminUserName  = pmrObj.fullName;
    }

  },

  computed: {
    userFullName() {
       return this.adminUserName;
    }
  
  },

  methods : {

    logoff() {
        this.store.user = null;
        localStorage.removeItem("pwadmin.pmr");
        this.$router.push("/");
    },


  },
  
  data()    {
      return {
        adminUserName  : "N/A",
        store,
        icons: {
            mdiAccountOutline,
            mdiEmailOutline,
            mdiCheckboxMarkedOutline,
            mdiChatOutline,
            mdiCogOutline,
            mdiCurrencyUsd,
            mdiHelpCircleOutline,
            mdiLogoutVariant,
        }   
      }
  },
  

}

</script>


<style lang="scss">

.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
