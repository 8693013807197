import { store }    from "./store";
import axios        from "axios";

export const apiResource = {

    
    localDev      :   false,
    localUrlbase  :  "http://localhost",
    liveUrlbase   :  "https://pwapi.portmanrecruitment.com",
  
    endpoint      :   {  
    
                        "auth"          : "/api/auth",
                        "lookup"        : "/api/lookup",
                        "password"      : "/api/password",
                        
                        "admin"         : "/api/admin",
                        "user"          : "/api/user",
                        "userstate"     : "/api/userstate",
                        "email"         : "/api/email",
                          
                        "client"        : "/api/client",
                        "umbrella"      : "/api/umbrella",
                        "consultant"    : "/api/consultant",
                    
                        "submission"    : "/api/submission",

                        "approval"      : "/api/approval",
                        
                        "processed"     : "/api/processed",
                    
                        "setting"       : "/api/setting",
                                            
                        "dashboard"     : "/api/dashboard",
                        
                        "passwordReset" : "/api/passwordReset",
                        "accessRequest" : "/api/requestAccess",

                        "openhours"      : "/api/openhours",
                        "closehours"     : "/api/closehours",
                  
                        "activity"       : "/api/activity",
                        
                        "notsubmitted"   : "/api/notsubmitted",

                        "pma"            : "/api/pma",

                        "report"            : "/api/report",
                        "closedSubmission"  : "/api/closedSubmission"
                                                
                      },
                      
    getResourceUrl: function( resource ) {
        
        if (this.localDev) {
             return (  this.localUrlbase + this.endpoint[ resource] )
        } else {
            return (  this.liveUrlbase + this.endpoint[ resource] )
        }
        
      },
 
    crudHeaders    : {

                    "client"        : [
                                     { text: 'id',             value: 'id', align: ' d-none'  },
                                     { text: 'Client Name',    value: 'clientName'   },
                                     { text: 'Contact Name',   value: 'contactName'  },
                                     { text: 'Contact Email',  value: 'contactEmail' },
                                     { text: 'Contact Phone',  value: 'contactPhone' },
                                     { text: 'Client Direct',  value: 'clientDirect' },
                                     { text: 'Docs Needed',    value: 'docsNeeded'   },
                                     { text: 'Actions',        value: 'actions', sortable: false },
                    ],         
                    "umbrella"      : [
                                    { text: 'id',               value: 'id', align: ' d-none'  },
                                    { text: 'Umbrella Name',    value: 'umbrellaName'  },
                                    { text: 'Contact  Name',    value: 'contactName'  },
                                    { text: 'Contact  Email',   value: 'contactEmail' },
                                    { text: 'Contact  Phone',   value: 'contactPhone' },
                                    { text: 'Function',         value: 'function'     },
                                    { text: 'Actions',          value: 'actions', sortable: false },
                    ],
                    "submission"    : [

                                    { text: '',                 value: 'data-table-expand'              }, 
                                    { text: 'Worker',           value: 'fullName',          sortable: true  },

                                    { text: 'Umb',              value: 'umbrellaName',     sortable: true },
                                    { text: 'Client',           value: 'clientName',       sortable: true },
                                
                                  
                                    { text: 'Units',            value: 'hours',          sortable: true  },
                                    { text: 'Mileage',          value: 'mileage',        sortable: true  },
                           
                                    { text: 'NetPay',           value: 'netPay',        sortable: true  },
                                    { text: 'Mileage',          value: 'netMileage',    sortable: true  },
                                    { text: 'Exes',             value: 'netExpense',    sortable: true  },
                                    { text: 'NetCharge',        value: 'netCharge',     sortable: true  },
                                    { text: 'NetMargin',        value: 'netMargin',     sortable: true  },
                        
                                    { text: 'Cons',             value: 'consultantName',      sortable: true },
                        
                                    { text: 'Status',           value: 'status',        sortable: true  },
                                    { text: 'RollOver',         value: 'rollOver',      align: 'center', sortable: true  },
                                    { text: 'PMA',              value: 'pma',           align: 'center', sortable: true  },
                                    { text: 'Admin',            value: 'submitter',     align: 'center', sortable: true  },
                                    { text: 'Submitted',        value: 'created_at',    align: 'center', sortable: true },
                                    
                                
                                    { text: 'Actions',          value: 'actions',       sortable: false },
                               
                                    { text: 'Portman Id',       value: 'portmanId',      align: ' d-none' },
                                    { text: 'id',               value: 'id',             align: ' d-none'  },
                                    { text: 'userId',           value: 'userId',         align: ' d-none'  },
                                    { text: 'Email',            value: 'email',         align: ' d-none' },
                                    { text: 'Week Ending',      value: 'weekEnding',    align: ' d-none' },
                                    { text: 'Submitter',        value: 'submitter',     align: ' d-none' },
                                    

     
                    ],
                    "consultant"    : [
                                    { text: 'id',                   value: 'id', align: ' d-none'  },
                                    { text: 'Consultant Name',      value: 'consultantName'  },
                                    { text: 'Contact Name',         value: 'contactName'  },
                                    { text: 'Contact Email',        value: 'contactEmail' },
                                    { text: 'Contact Phone',        value: 'contactPhone' },
                                    { text: 'Actions',              value: 'actions', sortable: false },
                    ],
                    "admin"         : [
                        { text: 'id',              value: 'id',        align: ' d-none'  },
                        { text: 'userName',        value: 'userName'   },
                        { text: 'Full Name',       value: 'fullName'   },
                        { text: 'Email',           value: 'email'      },
                        { text: 'Phone (Home)',    value: 'homePhone'  },
                        { text: 'Phone (Mobile)',  value: 'cellPhone'  },
                        { text: 'Actions',         value: 'actions',  sortable: false },
                    ],
                    "user"          : [
                          
                        { text: 'id',              value: 'id',        align: ' d-none'  },
                        { text: 'Portman Id',      value: 'portmanId'  },
                        { text: 'Full Name',       value: 'fullName'   },
                        { text: 'Email',           value: 'email'      },

                        
                        { text: 'userName',        value: 'userName'   },
                        { text: 'password',        value: 'password',  align: ' d-none' }, 
                    
                        { text: 'Phone (Home)',    value: 'homePhone'  },
                        { text: 'Phone (Mobile)',  value: 'cellPhone'  },
            
                        { text: 'Day-Rate',      value: 'dayRateCandidate', align: 'center'    },
                        { text: 'Temp',          value: 'tempCandidate',    align: 'center'    },
            
                        { text: 'Start Date',      value: 'startDate'  },


                        { text:'payRate' ,       value: 'payRate',      align: ' d-none'},
                        { text:'chargeRate',     value: 'chargeRate',   align: ' d-none'},
                        { text:'mileageRate',    value: 'mileageRate',  align: ' d-none'},
                      
                        { text:'consultant',    value: 'consultant',    align: ' d-none'},
                        { text:'umbrella',      value: 'umbrella',      align: ' d-none'},
                        { text:'client',        value: 'client',        align: ' d-none'},
                    
                        { text: 'Actions',        value: 'actions',  sortable: false },
                    ],
                    "pma"           : [

                        { text: '',                 value: 'data-table-expand'              }, 
                        { text: 'Worker',           value: 'fullName',          sortable: true  },
                        { text: 'Umb',              value: 'umbrellaName',      sortable: true },
                        { text: 'Client',           value: 'clientName',        sortable: true },
                 
                        { text: 'Units',            value: 'hours',             sortable: true  },
                        { text: 'Mileage',          value: 'mileage',           sortable: true  },
                        { text: 'Exes',             value: 'netExpense',        sortable: true  },
                 
                        { text: 'Cons',             value: 'consultantName',   sortable: true },
                        { text: 'Status',           value: 'status',            sortable: true  },
                        { text: 'RollOver',         value: 'rollOver',          sortable: true },
                        { text: 'PMA',              value: 'pma',               sortable: true },
                        { text: 'Actions',          value: 'actions',           sortable: false },
                   

                        { text: 'NetPay',           value: 'netPay',        align: ' d-none' },
                        { text: 'Mileage',          value: 'netMileage',    align: ' d-none' },
                        { text: 'NetCharge',        value: 'netCharge',     align: ' d-none' },
                        { text: 'NetMargin',        value: 'netMargin',     align: ' d-none' },
                        { text: 'Portman Id',       value: 'portmanId',     align: ' d-none' },
                        { text: 'id',               value: 'id',            align: ' d-none' },
                        { text: 'userId',           value: 'userId',        align: ' d-none' },
                        { text: 'Email',            value: 'email',         align: ' d-none' },
                        { text: 'Submitted',        value: 'created_at',    align: ' d-none' },
                        { text: 'Week Ending',      value: 'weekEnding',    align: ' d-none' },
                        { text: 'Submitter',        value: 'submitter',     align: ' d-none' },
                        


                    ],
        
    }, 

    // crud get items /////////////////
    getCrudItems          :  async function() {
        
        store.crudComponent.loading = true;

        const  resource      = store.resource;
        const  baseUrl      =  this.getResourceUrl( resource );
        const  url          = `${baseUrl}`;

        try {

            const response = await axios.get( url );
                
                if ( response.data.status ) {

                    var items = response.data.res;
                    
                    if ( (resource === 'submission') ||  ( resource === 'pma') ) {  
                        items.forEach( (item ) => { 
                            this.itemCosting(item)
                        })    
                    }
                
                    store.crudComponent.items = items;
                    store.crudComponent.loading = false;
                }    
             
        } catch (error) {
          
                console.log(error);
                store.toastDialog.subtext   = "Insert Resource Error";
                store.toastDialog.text      = error.response.data.message;
                store.toastDialog.show      = true;
        }        
    },
 
    getCrudItemsFiltered  : function(filter) {

        console.log( "getCrudItemsFiltered", filter, store.resource);
    
        store.crudComponent.loading = true;

        const  resource      = store.resource;
        const  baseUrl      =  this.getResourceUrl( resource );

        const status        = filter.statusSelected.toLowerCase();
        const user          = filter.userSelected.toLowerCase();
        const url          = `${baseUrl}/${status}/${user}`;

        console.log("getCrudItemsFiltered", url);
        
        axios.get( url ).then( response => {
            if ( response.data.status ) {
                store.crudComponent.items = response.data.res;
                store.crudComponent.loading = false;
            }
        });
        
    },

    // crud get headers/////////////////
    getCrudHeaders        : function( ) {
        const  resource               = store.resource;
        store.crudComponent.headers   = this.crudHeaders[ resource ];
        console.log("headers", store.crudComponent.headers);
    },
    
    // get resource grid header/////////////////
    getResourceHeader     : function( resource ) {
        return ( this.crudHeaders[ resource ] );
    },
   
    /////////////////////////////////////////////////
    // crud database operations /////////////////////
    // the dialog will be switched off here
    /////////////////////////////////////////////////
    insertResource : async function( item ) {


           const  resource      = store.resource;
           const  baseUrl      =  this.getResourceUrl( resource );
           const  url          = `${baseUrl}`;

            try {
     
                const response      = await axios.post( url, item );
                if ( response.data.status ) {
                    store.crudComponent.items.push( response.data.res);
                }
    
            } catch (error) {
          
                console.log(error);
                store.toastDialog.subtext   = "Insert Resource Error";
                store.toastDialog.text      = error.response.data.message;
                store.toastDialog.show      = true;
            }

           return true;
    },

    deleteResource : async function( deletedItem, deletedIndex ) {

        const  resource     = store.resource;
        const  baseUrl      =  this.getResourceUrl( resource );
        const  url          = `${baseUrl}/${deletedItem.id}`;
        
        const response      = await axios.delete( url, deletedItem );
        if ( response.data.status) {
                store.crudComponent.items.splice( deletedIndex, 1)
        }

        return true;
    },

    updateResource : async function( editedItem,  editedIndex ) {

        const  resource      = store.resource;
        const  baseUrl       =  this.getResourceUrl( resource );
        const  url           = `${baseUrl}/${editedItem.id}`;
        
        try {
     
            const response       = await axios.put(url, editedItem );
            if ( response.data.status ) {
                Object.assign( store.crudComponent.items[editedIndex], editedItem);
            }
        
        } catch (error) {
          
            console.log(error);
            store.toastDialog.subtext   = "update Resource Error";
            store.toastDialog.text      = error.response.data.message;
            store.toastDialog.show      = true;
        }

        return true;

    },


    
    /////////////////////////////////////////////////
    // all async/await so that we do not set the buffer early
    // tye dialog will be switched off here
    /////////////////////////////////////////////////

    viewDestroyed : function() {
        store.crudComponent = {
                headers    : [],
                items      : [],   
                sort       : "",
                loading    : false,
                saving     : false,
         };
    },


    //////////////////////////////////////////////////////////
    // get individual resource items for combo box drops /////
    //////////////////////////////////////////////////////////
    getResourceItems         : function( resource ) {
    
        console.log( "getResourceItems", resource);
        
        const  baseUrl      =  this.getResourceUrl( resource );
        const  url          = `${baseUrl}`;

        axios.get( url ).then( response => {
            if ( response.data.status ) {
                 store.uiArrays[resource] = response.data.res;
                 console.log( "getResourceItems", resource, store.uiArrays[resource] );
            }
        });
        
    },

    getResourceItemCount      : function( resource ) {

        const   baseUrl       =  this.getResourceUrl( "dashboard" );
        const   url           = `${baseUrl}/${resource}/count`;
        
        axios.get( url ).then( response => {
            if ( response.data.status ) {
                 store.dashboardCount[resource] = response.data.res;
            }
        });
    
    },


    openHours : async function(){
        const  baseUrl       =  this.getResourceUrl( 'openhours');
        const  url           = `${baseUrl}`;
        await axios.get(url);
        return true;
    },

    closeHours : async function(){

        const  baseUrl       =  this.getResourceUrl( 'closehours');
        const  url           = `${baseUrl}`;
        await axios.get(url);
        return true;

    },
    
  
    ///////////////////////////////////////
    //accept submission //////////////////
    ///////////////////////////////////////
    acceptSubmission   : async function( editedItem,  editedIndex ) {

            const  resource      =  store.resource;
            const  baseUrl       =  this.getResourceUrl( resource );
            const  url           = `${baseUrl}/accept/${editedItem.id}`;


            const response       = await axios.get(url);
            if ( response.data.status ) {
                editedItem['status'] = "accepted";
                Object.assign( store.crudComponent.items[editedIndex], editedItem);
            }
      
    },

    /////////////////////////////////////
    //decline submission               //
    /////////////////////////////////////
    declineSubmission   : async function(editedItem, editedIndex ) {

            const  resource      = store.resource;
            const  baseUrl       =  this.getResourceUrl( resource );
            const  url           = `${baseUrl}/decline/${editedItem.id}`;

            const response       = await axios.get(url);
            if ( response.data.status ) {
                editedItem['status'] = "declined";
                Object.assign( store.crudComponent.items[editedIndex], editedItem);
            }
    },

    
    /////////////////////////////////////
    //reset password                   //
    /////////////////////////////////////
    resetPassword  : async function(editedItem, editedIndex ) {

        const  resource      = store.resource;
        const  baseUrl       =  this.getResourceUrl( resource );
        const  url           = `${baseUrl}/auth/${editedItem.id}`;

        const response       = await axios.get(url);
        if ( response.data.status ) {
            editedItem['status'] = "declined";
            Object.assign( store.crudComponent.items[editedIndex], editedItem);
        }
    },

    itemCosting  : function ( item ) {

        //////////////////////////////////////////////
        // if the item has no amount then use global//
        //////////////////////////////////////////////
        const globals = store.globalDialog.settings;

        const payRate      = (item.payRate)     ? item.payRate     : globals.payRate;
        const mileageRate  = (item.mileageRate) ? item.mileageRate : globals.mileageRate;
        const chargeRate   = (item.chargeRate)  ? item.chargeRate  : globals.chargeRate;

        item['netPay']      = parseFloat( item.hours   *  payRate    );
        item['netMileage']  = parseFloat( item.mileage *  mileageRate);
        item['netExpense']  = parseFloat( item.expenses );

        item['netCharge']   = parseFloat(  item.hours *  chargeRate);
        item['netMargin']   = parseFloat( (item.hours * chargeRate) - (item.hours * payRate) );
    },



      
    /////////////////////////////////////
    //send emails                      //
    /////////////////////////////////////
    sendSubmissionEmail : async function( emailType, item) {

        const  baseUrl      =  this.getResourceUrl( "email" );
        let    url          =  null;

        switch( emailType.toLowerCase() ) {

            case "submitted":
                url = `${baseUrl}/submission/submitted`
                break;
            case "accepted":
                url = `${baseUrl}/submission/accepted`
                break;
            case "declined":
                url = `${baseUrl}/submission/declined`
                break;
            case "deleted":
                url = `${baseUrl}/submission/deleted`
                break;
            case "updated":
                url = `${baseUrl}/submission/updated`
                break;
            case "approved":
                url = `${baseUrl}/submission/approved`
                break;
            case "umbrella":
                url = `${baseUrl}/umbrella/invoice`
                break;
            }

        if ( url ){

            try {
     
                const response               = await axios.post( url, item );
                return response;
    
            } catch (error) {
          
                console.log(error);
                store.toastDialog.subtext   = "SendEmail Error";
                store.toastDialog.text      = error.response.data.message;
                store.toastDialog.show      = true;
                return error;
            }
        }
         

    },

    toCurrency : function (value) {
        var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
        return formatter.format( value );
    },


    ///CSV export functions///////////////////
    //////////////////////////////////////////
    downloadCsv : function (data) {
              
        // Creating a Blob for having a csv file format and passing the data with type
        const blob = new Blob([data], { type: 'text/csv' });

        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob)

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')

        // Passing the blob downloading url
        a.setAttribute('href', url)

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('download', 'download.csv');

        // Performing a download with click
        a.click()
    },

    objectToCsv : function (data) {

    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    // Loop to get value of each objects key
    for (const row of data) {
    
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });

      // To add, separator between each value
      csvRows.push(values.join(','));
    }

       /* To add new line for each objects values
       and this return statement array csvRows
       to this function.*/
       return csvRows.join('\n');
    },

    generateCSV : function () {
        const csvData = this.objectToCsv( this.items );
        this.download( csvData );
    },
    
  
}