<template>

   
          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn :to="{name: 'reports'}" v-bind="attrs" v-on="on" class="simple" text>
                         <v-icon size="28px">mdi-finance</v-icon>
                      </v-btn>

                    </template>
                    <span>Reports</span>
          </v-tooltip>
   
            
  
  
</template>

<script>

import { store }  from '@/store/store'

import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiBook
} from '@mdi/js'

export default {

  mounted() {},
  methods : {},
  data()    {
      return {
        store,
        icons: {
            mdiAccountOutline,
            mdiEmailOutline,
            mdiCheckboxMarkedOutline,
            mdiChatOutline,
            mdiCogOutline,
            mdiCurrencyUsd,
            mdiHelpCircleOutline,
            mdiLogoutVariant,
            mdiBook
        }   
      }
  },
  

}

</script>


<style lang="scss">

.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
