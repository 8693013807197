<template>
    
    <v-data-table
        :headers        = "headers"
        :items          = "filteredItems"
        :sort-by        = "sort"
        class           = "elevation-1"
        :loading        = "store.crudComponent.loading"
        loading-text    = "Data is Loading... Please wait"

    >
     
      <template v-slot:top>
        
        <v-toolbar flat>

          <exportToCsvDialog :items= "filteredItems" title="Export Consultants to CSV" gridName="Consultants"/>
          
          <v-toolbar-title class="text-capitalize">{{store.resource}}s</v-toolbar-title>
      
          <v-divider  class="mx-4" inset vertical></v-divider>

          <v-text-field
            rounded
            dense
            solo
            prepend-inner-icon ="mdi-magnify"
            class="app-bar-search flex-grow-0 mx-4"
            hide-details
             v-model = "filter"
            label    = "Filter ..."
         ></v-text-field>
 
         <v-divider  class="mx-4" inset vertical></v-divider>

         <v-spacer></v-spacer>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
          </v-tooltip>
                
          <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                        <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>

                    </template>
                    <span>Create PDF</span>
          </v-tooltip>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="dialog = true">
                        <v-icon color="#B9802B" size="28px">mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Consultant</span>
          </v-tooltip>
          
          <v-dialog  @keydown.esc="editItem(false)" @click:outside="editItem(false)" v-model="dialog" max-width="500px">
  
            <v-card>
                           
              <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>

              <v-form v-model="consultantFormValid" ref="consultantForm">

              <v-card-text>
                  
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.consultantName"
                        label="Consultant Name"
                        :rules="consultantFormRules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model = "editedItem.contactName"
                        label   = "Contact Name"
                        prepend-inner-icon="mdi-card-account-mail-outline" 
                        :rules="consultantFormRules.required"
      
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.contactEmail"
                        label="Contact Email"
                        prepend-inner-icon="mdi-email" 
                        :rules="consultantFormRules.required"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.contactPhone"
                        label="contact Phone"
                        prepend-inner-icon="mdi-phone" 
                        :rules="consultantFormRules.required"
                         ></v-text-field>
                    </v-col>

                  </v-row>

              </v-card-text>
              
              <v-card-actions>
            
                <v-col class="text-right">
                  <v-btn
                    color="#B9802B"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="#B9802B"
                    text
                    @click="save"
                    :disabled="!consultantFormValid"
                  >
                    Save
                  </v-btn>
                </v-col>
            
              </v-card-actions>

              </v-form>
            
            </v-card>

          </v-dialog>

          <v-dialog @keydown.esc="editItem(false)" @click:outside="editItem(false)" v-model="dialogDelete" max-width="500px">
            <v-card>
              
              <v-card-title class="text-h6">Are you sure you want to delete this {{store.resource}} ?</v-card-title>
              <v-divider></v-divider>

              <v-card-text  class="text-h6">Consultant Name: {{ editedItem.consultantName}}</v-card-text>
             
              <v-card-actions>
                
                <v-col class="text-right">
                  <v-btn color="#B9802B" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="#B9802B" text @click="deleteItemConfirm">OK</v-btn>
                </v-col>
              
              </v-card-actions>
            </v-card>
          </v-dialog>


        </v-toolbar>

      </template>
      
      <template v-slot:item.actions="{ item }">
        <v-menu dark offset-y left bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark color="#B9802B" v-bind="attrs" v-on="on">Manage
              </v-btn>
            </template>
            
            <v-list  dark>
            <v-list-item  @click="editItem(item)">
              <v-icon class="mr-2">mdi-pencil</v-icon>
              <v-list-item-title>Edit Consultant</v-list-item-title>
            </v-list-item>

            <v-list-item  @click="deleteItem(item)">
              <v-icon class="mr-2">mdi-delete</v-icon>
              <v-list-item-title>Delete Consultant</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-menu>
      </template>
       
    </v-data-table>

</template>

<script>

  import { store }                from '@/store/store'
  import { apiResource }          from '@/store/apiResource'
  import { apiShared }           from '@/store/apiShared'
  import   exportToCsvDialog      from '@/components/Dialogs/exportToCsvDialog.vue'


  export default {

    name          : 'ConsultantsCrud',
    components    : { exportToCsvDialog },
    data          : () => ({
    
      store,

      filter           : '',
      dialog           : false,
      dialogDelete     : false,


      consultantFormValid : false,
      consultantFormRules : { required: [value => !!value || "Required."] },

      editedIndex      : -1,
            
      editedItem: {
        id              : '',
        consultantName  : '',
        contactName     : '',
        contactEmail    : '',
        contactPhone    : ''
      },
      
      defaultItem: {
        id              : '',
        consultantName  : '',
        contactName     : '',
        contactEmail    : '',
        contactPhone    : ''
      },

    }),
    computed      : {

      filteredItems() {

        if ( this.filter.length > 2 ) {
            
            const filtereditems  = this.store.crudComponent.items.filter( item => {
               return item.consultantName.toLowerCase().startsWith( this.filter.toLowerCase());
            })

            return filtereditems;
        } 

        return this.store.crudComponent.items;

      },
      headers() {
           return (this.store.crudComponent.items.length > 0) ? this.store.crudComponent.headers : []
      },
      sort() {
           return this.store.crudComponent.sort;
      },


      
       formTitle () {
        return this.editedIndex === -1 ? 'New Consultant' : 'Edit Consultant'
      },
      
    },

    watch         : {
      dialog       (val) { val || this.close()       },
      dialogDelete (val) { val || this.closeDelete() },
    },

    methods: {

      generatePDF() {
        apiShared.generatePDF('Consultants');
      },
      
      editItem (item) {
        this.editedIndex =  this.store.crudComponent.items.indexOf(item)
        this.editedItem  =  Object.assign( {}, item)
        this.dialog      =  true
      },

      deleteItem (item) {
        this.editedIndex   =  this.store.crudComponent.items.indexOf(item)
        this.editedItem    =  Object.assign({}, item)
        this.dialogDelete  =  true
      },

      deleteItemConfirm () {
        apiResource.deleteResource(this.editedItem,this.editedIndex);
        this.closeDelete()
      },
      
      close () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      
      save () {
        
        if (this.editedIndex > -1) {
            apiResource.updateResource(this.editedItem, this.editedIndex );
        } else {
            apiResource.insertResource(this.editedItem );
        }

        this.close()
      },

    },
  }
</script>