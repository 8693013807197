import Vue from 'vue'

import VueRouter      from 'vue-router'

import SignOn         from '@/views/SignOn.vue'
import PasswordReset  from '@/views/PasswordReset.vue'
import AccessRequest  from '@/views/AccessRequest.vue'

import Dashboard         from '@/views/dashboard/dashboard.vue'
import Clients           from '@/views/clients/clients.vue'
import Umbrellas         from '@/views/umbrellas/umbrellas.vue'
import Users             from '@/views/users/users.vue'
import Admins            from '@/views/admin/admins.vue'
import Consultants       from '@/views/consultants/consultants.vue'
import Submissions       from '@/views/submissions/submissions.vue'
import Accepted          from '@/views/accepted/accepted.vue'
import PMA               from '@/views/pma/pma.vue'
import importCandidates  from '@/views/import/importCandidates.vue'

// import the reports components ////////////////////////////
import reports           from '@/views/reports/reports.vue'
import umbrellaWeekly    from '@/views/reports/umbrellaWeekly.vue'
import processedWeekly   from '@/views/reports/processedWeekly.vue'
import candidateVacation from '@/views/reports/candidateVacation.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignOn',
    component: SignOn,
    meta: { layout: 'LayoutBlank'}
  },{
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/passwordReset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { layout: 'LayoutBlank'}
  },{
    path: '/accessRequest',
    name: 'AccessRequest',
    component: AccessRequest,
    meta: { layout: 'LayoutBlank'}
  },{
    path: '/users',
    name: 'users',
    component: Users,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/admins',
    name: 'admins',
    component: Admins,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/clients',
    name: 'clients',
    component: Clients,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/umbrellas',
    name: 'umbrellas',
    component: Umbrellas,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/consultants',
    name: 'consultants',
    component: Consultants,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/submissions',
    name: 'submissions',
    component: Submissions,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/accepted',
    name: 'Approvals',
    component: Accepted,
    meta: { layout: 'LayoutContent'}
  }, {
    path: '/pma',
    name: 'PMA',
    component: PMA,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/importCandidates',
    name: 'importCandidates',
    component: importCandidates,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/reports',
    name: 'reports',
    component: reports,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/umbrellaWeekly',
    name: 'umbrellaWeekly',
    component: umbrellaWeekly,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/processedWeekly',
    name: 'processedWeekly',
    component: processedWeekly,
    meta: { layout: 'LayoutContent'}
  },{
    path: '/candidateVacation',
    name: 'candidateVacation',
    component: candidateVacation,
    meta: { layout: 'LayoutContent'}
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
