<template>
    <v-row>
          <v-col cols="12">
     
            <v-data-table 
                :headers         = "header"
                :items           = "items"
                sort-by.sync     = "umbrellaName"
                sort-desc.sync   = "umbrellaName"
                 class           = "elevation-1"
                :loading         = "loading.loadingData"
                :loading-text    = "loading.loadingDataText"
                item-key         = "umbrellaId"
                show-expand
                single-expand  
                expand-icon       = "mdi-information"
                @item-expanded    = "getExpandedItemDetail"
            >
     
            <template v-slot:top>

                <exportToCsvDialog :items= "items" title="Export Report to CSV" gridName="Rows"/>
                          
                <v-toolbar flat>
                  
                  <v-toolbar-title >{{report['name']}}</v-toolbar-title>

                  <v-divider  class="mx-4" inset vertical></v-divider>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="runReport">
                        <v-icon color="#B9802B" size="38px">mdi-refresh</v-icon>
                      </v-btn>

                    </template>
                    <span>Refresh Report</span>
                  </v-tooltip>
           
                  <v-divider  class="mx-4" inset vertical></v-divider>

                  <v-menu  v-model="menuWeekEnding" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      rounded
                      dense
                      prepend-inner-icon ="mdi-calendar"
                      :value = "computedWeekEnding"
                      label  = "Week Ending ..."
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color = "#B9802B"
                    no-title
                    v-model = "weekEndingFilter"
                    @change = "menuWeekEnding = false"
                  ></v-date-picker>
                  </v-menu>

                  <v-divider  class="mx-4" inset vertical></v-divider>

                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="38px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
                  </v-tooltip>
                
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                        <v-icon color="#B9802B" size="38px">mdi-file-pdf-box</v-icon>
                      </v-btn>

                    </template>
                    <span>Create PDF</span>
                  </v-tooltip>

                </v-toolbar>

            </template>

            <template v-slot:item.unitAmount="{ item }">
              <span>{{ item.unitAmount | toCurrency }}</span>
            </template>

            <template v-slot:item.mileageAmount="{ item }">
              <span>{{ item.mileageAmount | toCurrency }}</span>
            </template>

            <template v-slot:item.expenseAmount="{ item }">
              <span>{{ item.expenseAmount | toCurrency }}</span>
            </template>

            <template v-slot:item.chargeAmount="{ item }">
              <span>{{ item.chargeAmount | toCurrency }}</span>
            </template>

            <template v-slot:item.marginAmount="{ item }">
              <span>{{ item.marginAmount | toCurrency }}</span>
            </template>
      
            <template v-if = "items.length > 0 " slot="body.append">
       
                <tr pt="2">
                  <th></th>
                  <th class="font14b">Totals</th>
                  <th class="font14b">{{ computedWeeklySubmissions                  }}</th>
                  <th class="font14b">{{ computedWeeklyUnitAmount     | toCurrency  }}</th>
                  <th class="font14b">{{ computedWeeklyMileageAmount  | toCurrency  }}</th>
                  <th class="font14b">{{ computedWeeklyExpenseAmount  | toCurrency  }}</th>
                  <th class="font14b">{{ computedWeeklyChargeAmount   | toCurrency  }}</th>
                  <th class="font14b">{{ computedWeeklyMarginAmount   | toCurrency  }}</th>
                    
                 </tr>

            </template>

            <template v-slot:expanded-item="{ headers}">
                
              <td :colspan="headers.length">

                <v-row class="pt-1 ml-1" no-gutters>
                        <v-col class="font14b" cols="12" sm="2">Candidate</v-col>
                        <v-col class="font14b" cols="12" sm="1">Units</v-col>
                        <v-col class="font14b" cols="12" sm="1">Mileage</v-col>
                        <v-col class="font14b" cols="12" sm="2">Paid</v-col>
                        <v-col class="font14b" cols="12" sm="2">Expense</v-col>
                        <v-col class="font14b" cols="12" sm="2">Charges</v-col>
                        <v-col class="font14b" cols="12" sm="2">Margin</v-col>
                </v-row>
              
                       <v-row class="pt-1 ml-1"></v-row>
             
                      <v-row class="pt-1 ml-1" no-gutters v-for="(detail, index) in computedExpandedItemDetail" :key="index">
                        <v-col class="font14" cols="12"  sm="2">{{ detail.candidateName}}</v-col>
                        <v-col class="font14"  cols="12" sm="1">{{ detail.units}}</v-col>
                        <v-col class="font14"  cols="12" sm="1">{{ detail.mileageAmount | toCurrency}}</v-col>
                        <v-col class="font14" cols="12"  sm="2">{{ detail.unitsAmount | toCurrency }}</v-col>
                        <v-col class="font14" cols="12"  sm="2">{{ detail.expenseAmount | toCurrency}}</v-col>
                        <v-col class="font14" cols="12"  sm="2">{{ detail.chargeAmount | toCurrency }}</v-col>
                        <v-col class="font14" cols="12"  sm="2">{{ detail.netMargin   | toCurrency}}</v-col>
                      </v-row>
        
                      <v-row class="pt-1 ml-1" no-gutters></v-row>
       
                               
                 </td>
            
              </template>




          
           </v-data-table>

          </v-col>
    </v-row>
</template>

<script>

import { apiResource }                  from '@/store/apiResource'
import { store  }                       from '@/store/store'
import { apiShared }                    from '@/store/apiShared'
import { reportMap }                    from '@/views/reports/widgets/reportMap.js'

import  exportToCsvDialog               from '@/components/Dialogs/exportToCsvDialog.vue'
import  moment                          from 'moment'
import  axios                           from "axios"

export default {  

      components    : { exportToCsvDialog },
      name          : 'UmbrellaWeekly',

      filters              : {
                 toCurrency: function (value) {
                    var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
                    return formatter.format( value );
                }
      },

      mounted () {
          console.log(`${this.name} mounted`);
          this.initialize()
      },

      beforeMount() {
         console.log(`${this.name} beforeMount`);
         apiResource.viewDestroyed();
      },
   

      computed      : {
  
        computedWeekEnding () {
            return ( this.weekEndingFilter) ? moment( this.weekEndingFilter).format('dddd, MMMM Do YYYY') : ''
        },

        header() {
          return ( this.items.length > 0) ?  this.reportHeader : [];
        },

        computedExpandedItemDetail() {
          return ( this.expandedItemDetail.length > 0) ?  this.expandedItemDetail : [];
     
        },

        itemCount()         {  return ( this.items.length > 0) ? this.items.length.toString : '0';},
        reportTitle()       {  return reportMap.getTitle      ( this.name )   },
        reportDescription() {  return reportMap.getDescription( this.name )   },
        reportRoute()       {  return reportMap.getRoute      ( this.name )   },

        computedWeeklySubmissions(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseInt(item.submissions);
                    });
                    return (summary);
        },

        computedWeeklyUnitAmount(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat( item.unitAmount );
                    });
                    return (summary);
        },

        computedWeeklyMileageAmount(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat( item.mileageAmount );
                    });
                    return (summary);
        },

        computedWeeklyExpenseAmount(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat( item.expenseAmount );
                    });
                    return (summary);
        },

        computedWeeklyChargeAmount(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat( item.chargeAmount );
                    });
                    return (summary);
        },

        computedWeeklyMarginAmount(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat( item.marginAmount );
                    });
                    return (summary);
        },

      },

      methods: {

        generatePDF() {
            apiShared.generatePDF( 'Umbrella Weekly', this.weekEndingFilter );
        },

        initialize () {
                var newDate = moment(); 
                this.weekEndingFilter = newDate.day(newDate.day() >= 5 ? 5 :-2).format('YYYY-MM-DD');
        },

        getExpandedItemDetail( {item,value} ) {
            
            if ( value) {
              const resourceURL    = apiResource.getResourceUrl("report")
              const url           = `${resourceURL}/${ this.report['drillDown']}/${item.umbrellaId}/${ this.weekEndingFilter }`;
              axios.get(url).then(response => {
                 this.expandedItemDetail = response.data 
              });
            } else {
              this.expandedItemDetail  = [];
            }
          },

        async runReport () {
       
          try {

            this.items               = [];
            this.loading.loadingData = true;

            const resourceURL        = apiResource.getResourceUrl("report")
            const url                = `${resourceURL}/${ this.report['endPoint']}/${ this.weekEndingFilter }`;
            const response           = await axios.get( url );
            this.items               = response.data;

          } catch (error) {

              this.store.toastDialog.subtext   = "Umbrella Weekly Report";
              this.store.toastDialog.text      = error.response.data.message;
              this.store.toastDialog.show      = true;

          } finally {

            this.loading.loadingData     = false;
            this.loading.loadingDataText =  'Loading umbrella Weekly Data'
          
          }        

        },

      },

      watch: {
            weekEndingFilter: function () {
                 this.runReport();
            }
          },
      
      data() {
          return {
            loading          :  {
                                  loadingData       : false,
                                  loadingDataText   : 'Loading umbrella Weekly Data'
                                },

            weekEndingFilter : '',

            reportHeader     : [
                         
                         { text: '',                 value: 'data-table-expand' }, 
                         { text: 'umbrella id',      value: 'umbrellaId',align: ' d-none'   },
                         { text: 'Umbrella Name',    value: 'umbrellaName' },
                         { text: 'Submissions',      value: 'submissions' },
                         { text: 'Unit',             value: 'unitAmount'   },
                         { text: 'Mileage',          value: 'mileageAmount' },
                         { text: 'Expense',          value: 'expenseAmount' },
                         { text: 'Charge',           value: 'chargeAmount'  },
                         { text: 'Margin',           value: 'marginAmount'  },
            
                         
                     ],

            items               : [],
            expandedItemDetail  : [],
            sortBy              : 'fat',
            sortDesc            : false,
            menuWeekEnding      : false,
            
            report           : {
                                'name'         : "Umbrella Weekly Report",
                                'endPoint'     : "umbrellaWeekly",
                                'drillDown'    : "umbrellaWeeklyDrillDown"
                                
            },
            
            store
          };
      }
}

</script>

<style scoped>

section {
  display: table;
}

.myHeader {
  display: table-row !important;
}

.line-break {
  display: table-row !important;
}
.myRow {
  display: table-row !important;
}

.myCol {
  display: table-cell !important;
}


.font14b{
   font-weight  : bold !important;
   font-family  : "Roboto", sans-serif !important;
   font-size    : 14px !important;
}
.font14{
   font-family  : "Roboto", sans-serif !important;
   font-size    : 14px !important;
}
</style>
