<template>
    
    <v-data-table
        :headers      = "headers"
        :items        = "filteredItems"
        :sort-by      = "sort"
        class         = "elevation-1"
        :loading      = "store.crudComponent.loading"
        loading-text  = "Data is Loading... Please wait"
    >
     
      <template v-slot:top>

        <exportToCsvDialog :items= "filteredItems" title="Export Clients to CSV" gridName="Clients"/>
        
        <v-toolbar flat>
          
          <v-toolbar-title class="text-capitalize">{{store.resource}}s</v-toolbar-title>
      
          <v-divider  class="mx-4" inset vertical></v-divider>

          <v-text-field
            rounded
            dense
            solo
            prepend-inner-icon ="mdi-magnify"
            class="app-bar-search flex-grow-0 mx-4"
            hide-details
             v-model = "filter"
            label    = "Filter ..."
         ></v-text-field>
 
         <v-divider  class="mx-4" inset vertical></v-divider>

         <v-spacer></v-spacer>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
          </v-tooltip>
                
          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                        <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>

                    </template>
                    <span>Create PDF</span>
          </v-tooltip>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="dialogEdit = true">
                        <v-icon color="#B9802B" size="28px">mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Client</span>
          </v-tooltip>

          <v-dialog   @keydown.esc="editItem(false)" @click:outside="editItem(false)" v-model="dialogEdit" max-width="500px">
  
            <v-card>
             
              <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>

              <v-form v-model="clientFormValid" ref="clientForm">

              <v-card-text>
                  
                  <v-row dense>

                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="editedItem.clientName"
                        label="Client Name"
                        :rules="clientFormRules.required"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-checkbox
                        v-model="editedItem.clientDirect"
                        label="Direct"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-checkbox
                        v-model ="editedItem.docsNeeded"
                        label   ="Docs"
                      ></v-checkbox>
                    </v-col>
             
                  </v-row>
                  
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model = "editedItem.contactName"
                        label   = "Contact Name"
                        prepend-inner-icon="mdi-card-account-mail-outline"
                        :rules="clientFormRules.required"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="editedItem.contactEmail"
                        label="Contact Email"
                        type="email"
                        prepend-inner-icon="mdi-email"
                        :rules="clientFormRules.required"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="editedItem.contactPhone"
                        label="Contact Phone"
                        prepend-inner-icon="mdi-phone"
                        type="number"
                        :rules="clientFormRules.required"
                      ></v-text-field>
                    </v-col>

                  </v-row>



              </v-card-text>
              
              <v-card-actions>
            
                <v-col class="text-right">
                  <v-btn
                    color="#B9802B"
                    text
                    @click="editItemAction(false)"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="#B9802B"
                    text
                    :loading = "dialogLoading"
                    @click="editItemAction(true)"
                    :disabled="!clientFormValid"
                  >
                    Save
                  </v-btn>
                </v-col>
            
              </v-card-actions>

              </v-form>

            </v-card>

          </v-dialog>

          <v-dialog  @keydown.esc="editItem(false)" @click:outside="editItem(false)"  v-model="dialogDelete" max-width="500px">
            <v-card elevation="2">
              
              <v-card-title >Are you sure you want to delete this Client ?</v-card-title>
              <v-card-text  >Client Name: {{ editedItem.clientName}}</v-card-text>
             
              <v-card-actions>
                <v-col class="text-right">
                  <v-btn color="#B9802B" text @click = "deleteItemAction(false)" >Cancel</v-btn>
                  <v-btn color="#B9802B" text :loading = "dialogLoading" @click = "deleteItemAction(true) ">OK</v-btn>
                </v-col>
              
              </v-card-actions>
            </v-card>
          </v-dialog>


        </v-toolbar>

      </template>
      

      <template v-slot:item.clientDirect = "{ item }">
         <v-simple-checkbox :value="(item.clientDirect == 0 ) ? false : true" disabled></v-simple-checkbox>
      </template>
         
      <template v-slot:item.docsNeeded = "{ item }">
         <v-simple-checkbox :value="item.docsNeeded" disabled></v-simple-checkbox>
      </template>
      

      <template v-slot:item.actions="{ item }">
        <v-menu dark offset-y left bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark color="#B9802B" v-bind="attrs" v-on="on">Manage
              </v-btn>
            </template>
            
            <v-list dark>
            <v-list-item  @click="editItem(item)">
              <v-icon class="mr-2">mdi-pencil</v-icon>
              <v-list-item-title>Edit Client</v-list-item-title>
            </v-list-item>

            <v-list-item  @click="deleteItem(item)">
              <v-icon class="mr-2">mdi-delete</v-icon>
              <v-list-item-title>Delete Client</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-menu>
      </template>
       
    </v-data-table>

</template>

<script>

  import { store }                  from '@/store/store'
  import { apiResource }            from '@/store/apiResource'
  import  { apiShared }           from '@/store/apiShared'

  import   exportToCsvDialog        from '@/components/Dialogs/exportToCsvDialog.vue'

  export default {

    name          : 'ClientsCrud',
    components    : { exportToCsvDialog },
    data          : () => ({
    
      store,

      filter           : '',

      dialogEdit       : false,
      dialogDelete     : false,
      dialogLoading    : false,


      clientFormValid : false,
      clientFormRules : { required: [value => !!value || "Required."] },

      editedIndex      : -1,
            
      editedItem: {
        id           : '',
        clientName   : '',
        contactName  : '',
        contactEmail : '',
        contactPhone : '',
        clientDirect : '',
        docsNeeded   : '',
      },
      
      defaultItem: {
        id           : '',
        clientName   : '',
        contactName  : '',
        contactEmail : '',
        contactPhone : '',
        clientDirect : '',
        docsNeeded   : false,
      },

    }),

    computed      : {

      filteredItems() {

        if ( this.filter.length > 2 ) {
            
            const filtereditems  = this.store.crudComponent.items.filter( item => {
               return item.clientName.toLowerCase().startsWith( this.filter.toLowerCase());
            })

            return filtereditems;
        } 

        return this.store.crudComponent.items;

      },
      headers() {

           return (this.store.crudComponent.items.length > 0) ? this.store.crudComponent.headers : []
      },
      sort() {
           return this.store.crudComponent.sort;
      },
      formTitle () {
         return this.editedIndex === -1 ? 'New Client' : 'Edit Client'
      },
      
    },

     methods: {


      editItem (item) {
        
        this.editedIndex =  this.store.crudComponent.items.indexOf(item)
        this.editedItem  =  Object.assign( {}, item)
        this.dialogEdit  =  true

        this.$nextTick(() => {
            this.$refs.clientForm.validate()
        })
      },

      deleteItem (item) {
        this.editedIndex   =  this.store.crudComponent.items.indexOf(item)
        this.editedItem    =  Object.assign({}, item)
        this.dialogDelete  =  true
      },

      async deleteItemAction ( confirmed ) {

        if ( confirmed) {
          this.dialogLoading  = true;
            await apiResource.deleteResource(this.editedItem,this.editedIndex);
          this.dialogLoading  = false;
        }

        this.dialogDelete    =  false
 
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
      },

      async editItemAction ( confirmed ) {

        if ( confirmed) {

          this.dialogLoading  = true;

            if (this.editedIndex > -1) {
                 await apiResource.updateResource(this.editedItem, this.editedIndex );
            } else {
                await apiResource.insertResource(this.editedItem );
            }

          this.dialogLoading  = false;
        } 

        this.dialogEdit     = false;

        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
      
      },     

      generatePDF() {
            apiShared.generatePDF('Clients');
      },
 
    },
  }
</script>