<template>
  <v-app>
        <v-main>
          <component :is="whichLayout">
              <toastDialog />
              <globalSettingDialog />
              <router-view />
          </component>
        </v-main>
  </v-app>
</template>

<script>

import { store }               from '@/store/store'
import LayoutBlank             from '@/layouts/layoutBlank.vue'
import LayoutContent           from '@/layouts/layoutContent.vue'
import toastDialog             from '@/components/Dialogs/toastDialog.vue'
import globalSettingDialog     from '@/components/Dialogs/globalSettingDialog.vue'

export default {

  mounted() {
    console.log("app.vue mounted");
  },
  computed: {
 
          whichLayout() {
                  const routeLayout = this.$route.meta.layout; 
                  return routeLayout
          } 
  },
  
  name       : 'App',
  components : { LayoutBlank, LayoutContent,toastDialog, globalSettingDialog},
  methods    : {},
  data: () => ({ 
    store,
  }),

};


</script>


<style>


.dropMenuList { 
                border: dashed 2px #DFC049 !important;
                border-radius: 15px !important;
                background-color: #B9802B !important;
              }


.v-menu__content {
   box-shadow: none !important;
}

.v-list {
                border: dashed 2px #DFC049 !important;
                border-radius: 15px !important;
                background-color: #B9802B !important;
  
}

.v-data-table-header
{
  background-color: #DFC049 !important;
  border-radius: 6px 6px 6px 6px;
}

table {
  padding: 10px;
  background-color : rgb(248,248,248)
}

.widget {
  background-color : rgb(248,248,248) !important;
}
</style>