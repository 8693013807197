<template>
    <v-row>
          <v-col cols="12">
              <UmbrellasCrud />
          </v-col>
    </v-row>
</template>


<script>

import UmbrellasCrud                    from '@/components/crud/UmbrellasCrud.vue'
import { apiResource }                  from '@/store/apiResource'
import { store  }                       from '@/store/store'

export default {  

      components : { UmbrellasCrud },
      name       : 'Umbrellas',
      mounted () {
         this.initialize()
      },

      beforeMount() {
        // clean up previous crud items 
        // before computed properties activate
         console.log("umbrellas beforeMount")
         apiResource.viewDestroyed();
      },
      
      methods    : {

        initialize () {
      
                this.store.resource            = "umbrella";
                this.store.crudComponent.sort  = "umbrellaName";
                apiResource.getCrudHeaders ();
                apiResource.getCrudItems   ();
        },
      
      },

      data() {
          return {
            store,
          };
      }
}

</script>