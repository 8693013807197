<template>

  <div>

    <v-data-table 
        id              = "submissionTable"
        :headers        = "headers"
        :items          = "filteredItems"
        :sort-by.sync   = "sortBy"
        :sort-desc.sync = "sortDesc"
        class           = "elevation-1 my_class"
        :loading        = "store.crudComponent.loading"
        loading-text    = "Data is Loading... Please wait"
        show-expand
        single-expand
        :item-class     = "itemHoursMoreThan37"
        expand-icon     = "mdi-information"
    >

      <template v-slot:top>
   
        <v-toolbar flat>

             <exportToCsvDialog :items= "filteredItems" title="Export Submissions to CSV" gridName="Submissions"/>
          
          
              <v-toolbar-title class="text-capitalize">{{store.resource}}s</v-toolbar-title>

               <v-divider  class="mx-4" inset vertical></v-divider>

               <v-text-field
                rounded
                dense
                solo
                prepend-inner-icon ="mdi-magnify"
                class="app-bar-search flex-grow-0 mx-4"
                hide-details
                v-model = "submissionFilter.candidateFilter"
                label    = "Filter by Worker  ..."
                clearable
              ></v-text-field>
            
              <v-divider  class="mx-4" inset vertical></v-divider>

              <div class="d-flex flex-row justify-space-between">

                      <v-btn  :outlined = "submissionFilter.statusSelected == 'pending'"
                              @click="setFilter('pending')" text color="orange">pending ({{pendingCount}})</v-btn>
                 
                      <v-btn :outlined="submissionFilter.statusSelected == 'declined'"
                              @click="setFilter('declined')" text color="red">declined ({{declinedCount}})</v-btn>
                      
                      <v-btn :outlined="submissionFilter.statusSelected == 'accepted'"
                             @click="setFilter('accepted')" text color="green">accepted ({{acceptedCount}})</v-btn>

              </div>
         
              <v-divider  class="mx-4" inset vertical></v-divider>
         
              <v-tooltip v-if="false" color="black" bottom >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">

                    <div class="text-xs-center">
                        <v-switch
                          v-bind  = "attrs"
                          v-on    = "on"
                          class   = "switch-center"
                          v-model = "hoursActivityOpen"
                          @change = "hoursActivityOpenOnChange"
                          color   ="success"
                          hint    = "asdasdSD"
                          ripple
                          hide-details
                          inset
                        >
                        <template v-slot:label>
                              <span><b>{{ hoursActivityOpenSwitchLabel }}</b></span>
                        </template>

                      </v-switch>
                    </div>
 
                  </div>
                </template>
                <div>Manually Open/Close Submission Window</div>
              </v-tooltip>
       
              <v-divider  v-if="false" class="mx-4" inset vertical></v-divider>

              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="refreshTable" :loading = "store.crudComponent.loading">
                        <v-icon color="#B9802B" size="28px">mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Refresh Table</span>
              </v-tooltip>
                
              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                           <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>
                    </template>
                    <span>Export to CSV</span>
              </v-tooltip>

              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                           <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Generate PDF</span>
              </v-tooltip>

              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text class  ="simple" v-bind ="attrs" v-on ="on" @click="dialogEdit = true">
                      <v-icon color="#B9802B" size="28px" >mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Submission</span>
              </v-tooltip>

              <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogEdit"  max-width="500px">
        
                  <v-card>
                  
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-card-subtitle v-if="(editedIndex > -1)">Status: {{editedItem.status}} </v-card-subtitle>
                 
                    <v-form v-model="submissionFormValid" ref="submissionForm">
                  
                     <v-card-text>

                        <v-row class="d-flex align-baseline" dense>

                        <v-col cols="8">
                            <v-text-field
                              :readonly = "(editedIndex > -1)"
                               v-model  = "editedItem.portmanId"
                              label     = "Portman Id"
                              autofocus
                            ></v-text-field>
                          </v-col>

                          <v-col align="right" cols="4">
                            <v-btn :loading="lookupLoading" @click="lookup">Lookup</v-btn>
                          </v-col>

                        </v-row>
            
                        <v-row v-if="editedItem.userId" dense>

                          <v-col cols="12">
                            <v-text-field
                              :readonly = "(editedIndex > -1)"
                              v-model="editedItem.fullName"
                              label="Candidate Name"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                             :readonly = "(editedIndex > -1)"
                              v-model="editedItem.email"
                              label="Candidate Email"
                            ></v-text-field>
                          </v-col>

      
                        </v-row>

                        <v-row v-if="editedItem.userId" dense>
                          
                          <v-col cols="12" md="4">
                            <v-text-field
                              
                              v-model     = "editedItem.hours"
                              label       = "Work Units (h.m)"
                              :rules      = "[rules.required, rules.length5]"
                              placeholder = "37.05"
                              type        = "number"
                              maxlength   = "5"

                            ></v-text-field>
                          </v-col>
                        
                          <v-col cols="12"  md="4">
                            <v-text-field
                              v-model     = "editedItem.mileage"
                              label       = "Mileage"
                              :rules      = "mileageRule"
                              placeholder = "0.00"
                              maxlength   = "6"
                              min         =  0.00
                              max         =  999.99
                            ></v-text-field>
                          </v-col>
                       
                          <v-col cols="12"  md="4">
                            <v-text-field
                              v-model     = "editedItem.expenses"
                              :rules      = "expenseRule"
                              label       = "Expenses"
                              placeholder = "0.00"
                              maxlength   = "6"
                              min         =  0.00
                              max         =  499.99
                              type        =  "number"
                            ></v-text-field>
                          </v-col>



                        </v-row>
                      
                        <v-row v-if="editedItem.userId" dense>
                    
                          <v-col cols="12">
                            <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              max-width="290"
                              hide-details
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateFormattedMomentjs"
                                  label="Work Week ending"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  hide-details
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                color = "#B9802B"
                                no-title
                                v-model="editedItem.weekEnding"
                                @change="menu = false"
                              ></v-date-picker>
                            </v-menu>
                           
                          </v-col>
   
                          <v-col cols="12">
                              <v-checkbox
                                  v-model = "editedItem.rollOver"
                                  label   = "Is this submission a Roll-Over ?"
                                  hide-details
                              ></v-checkbox>
                          </v-col>

                          <v-col cols="12">
                              <v-checkbox
                                  v-model = "editedItem.pma"
                                  label   = "Is this submission 'Pending Manager Approval' (PMA) ?"
                                  hide-details
                              ></v-checkbox>
                          </v-col>

                        </v-row>
               
                    
                      
                     </v-card-text>
                    
                     <v-card-actions>
                  
                      <v-col class="text-right">
                        <v-btn
                          color="#B9802B"
                          text
                          @click="editItemAction(false)"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          v-if="editedItem.userId"
                          color="#B9802B"
                          text
                          :loading = "dialogLoading"
                          @click="editItemAction(true)"
                          :disabled="!submissionFormValid"
                        >
                          Save
                        </v-btn>
                      </v-col>
                  
                     </v-card-actions>

                  </v-form>

                  </v-card>

              </v-dialog>

              <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogDelete" max-width="500px">
              
                <v-card>
              
                <v-card-title class="text-h6">Confirm <span class="mx-1 text-capitalize">{{store.resource}}</span> Deletion ?</v-card-title>
            
                <v-divider class="mb-2"></v-divider> 
               
                <v-card-text class="pt-6 text--primary">
                  <div>Candidate (Name) : {{editedItem.fullName }}</div>
                  <div>Candidate (Email): {{editedItem.email }}</div>
                  <div>Portman Id       : {{editedItem.portmanId }}</div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text v-if = "editedItem.clientDirect === 1" class="pt-6 text--primary">
                  <v-card class="mx-auto" outlined elevation="2">
                    <v-card-title>
                      Client is Direct
                    </v-card-title>
                    <v-card-text>
                      <div>Client        : {{editedItem.clientName}}</div>
                      <div>Client Email  : {{editedItem.clientEmail}}</div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              
                <v-card-actions>
                
                <v-col class="text-right">
                  <v-btn color="#B9802B" text @click="deleteItemAction(false)">Cancel</v-btn>
                  <v-btn color="#B9802B" text :loading = "dialogLoading"  @click="deleteItemAction(true)">Delete</v-btn>
                </v-col>
              
              </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogDecline" max-width="500px">
                <v-card>
                  
                  <v-card-title class="text-h6">Confirm <span class="mx-1">{{store.resource}}</span>will be Declined ?</v-card-title>
                
                  <v-divider></v-divider>
                    <v-card-text class="pt-6 text--primary">

                      <div>Candidate (name) : {{editedItem.fullName }}</div>
                      <div>Candidate (Email): {{editedItem.email }}</div>
                      <div>Portman Id       : {{editedItem.portmanId }}</div>


                    </v-card-text>

                  <v-divider></v-divider>

                  <v-card-text v-if = "editedItem.clientDirect === 1" class="pt-6 text--primary">
                  <v-card class="mx-auto" outlined elevation="2">
                    <v-card-title>
                      Client is Direct
                    </v-card-title>
                    <v-card-text>
                      <div>Client        : {{editedItem.clientName}}</div>
                      <div>Client Email  : {{editedItem.clientEmail}}</div>
                    </v-card-text>
                  </v-card>
                </v-card-text>

                
                  <v-card-actions>
                    
                    <v-col class="text-right">
                      <v-btn color="#B9802B" text @click="declineItemAction(false)">Cancel</v-btn>
                      <v-btn color="#B9802B" text :loading = "dialogLoading"  @click="declineItemAction(true)">Decline</v-btn>
                    </v-col>
                  
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogAccept" max-width="500px">

                <v-card  class="mx-auto">

                <v-card-title class="text-h6">Confirm <span class="mx-1 text-capitalize">{{store.resource}}</span> Acceptance</v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pt-6 text--primary">

                  <div>Candidate (name) : {{editedItem.fullName }}</div>
                  <div>Candidate (Email): {{editedItem.email }}</div>
                  <div>Portman Id       : {{editedItem.portmanId }}</div>

               
                </v-card-text>

               <v-divider></v-divider>

               <v-card-text v-if = "editedItem.clientDirect === 1" class="pt-6 text--primary">
                  <v-card class="mx-auto" outlined elevation="2">
                    <v-card-title>
                      Client is Direct
                    </v-card-title>
                    <v-card-text>
                      <div>Client        : {{editedItem.clientName}}</div>
                      <div>Client Email  : {{editedItem.clientEmail}}</div>
                    </v-card-text>
                  </v-card>
                </v-card-text>


                <v-card-actions>

                  <v-col class="text-right">
                      <v-btn color="#B9802B" text @click="acceptItemAction(false)">Cancel</v-btn>
                      <v-btn color="#B9802B" text :loading = "dialogLoading"  @click="acceptItemAction(true)">Accept</v-btn>
                  </v-col>
                
                </v-card-actions>
               
               </v-card>

              </v-dialog>

        </v-toolbar>

      </template>
      
      <template v-slot:expanded-item="{ headers, item }">
      
      <td :colspan="headers.length">
            <div class="d-flex flex-column">
              <div class="pt-2 pb-1 pl-1">Candidate Email : {{ item.email }}</div>
              <div class="pb-1 pl-1">Portman Id : {{ item.portmanId }}</div>
              <div class="pb-1 pl-1">Date Submitted  : {{ formatDate(item.created_at) }}</div>
              <div class="pb-1 pl-1">Submitted By    : {{ item.submitter }}</div>
              <div :class="(item.rollOver == 1) ? 'rollOverText' : ''" class="pb-1 pl-1">is Rollover     : {{ formatRollOver(item.rollOver)  }}</div>
              <div class="pb-1 pl-1">Week Ending     : {{ formatWkEnding(item.weekEnding)    }}</div>
            </div>
      </td>

      </template>

      <template v-slot:item.submitter = "{ item }">
        <v-simple-checkbox :value="(item.submitter.toLowerCase() == 'candidate' ) ? false : true" disabled></v-simple-checkbox>
      </template>

      <template v-slot:item.rollOver = "{ item }">
       <v-simple-checkbox :value="(item.rollOver == 0 ) ? false : true" disabled></v-simple-checkbox>
      </template>

      <template v-slot:item.pma      = "{ item }">
              <v-simple-checkbox :value = "item.pma" disabled></v-simple-checkbox>
      </template>

      <template v-slot:item.status="{ item }">
              <span :class="getStatusColor(item.status)" class="text-capitalize">{{ item.status }}</span>
      </template>
            
      <template v-slot:item.created_at="{ item }">
        <span>
          <strong>
            {{ new Date(item.created_at).toLocaleString('en-GB', { year: "numeric",month: "numeric", day: "numeric", timeZone: 'UTC' }) }}
         </strong>
        </span> 
      </template>
     
      <template v-slot:item.actions="{ item }">
        <v-menu  offset-y left bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small dark color="#B9802B" v-bind="attrs" v-on="on">Manage
              </v-btn>
            </template>
            
            <v-list dark class="dropMenu dropMenuList">
      
              <v-list-item  @click="editItem(item)">
                <v-icon class="mr-2">mdi-pencil</v-icon>
                <v-list-item-title>Edit Submission</v-list-item-title>
              </v-list-item>

             <v-list-item  @click="deleteItem(item)">
              <v-icon class="mr-2">mdi-delete</v-icon>
              <v-list-item-title>Delete Submission</v-list-item-title>
             </v-list-item>

            <v-divider></v-divider>
 
             <v-list-item  @click="acceptItem(item)">
                <v-icon class="mr-2">mdi-check</v-icon>
                <v-list-item-title>Accept Submission</v-list-item-title>
             </v-list-item>

             <v-list-item  @click="declineItem(item)">
              <v-icon class="mr-2">mdi-cancel</v-icon>
              <v-list-item-title>Decline Submission</v-list-item-title>
            </v-list-item>

            
          </v-list>

        </v-menu>
      </template>

      <template v-slot:item.netPay="{ item }">
           <span>{{ item.netPay | toCurrency }}</span>
      </template>

      <template v-slot:item.netMileage="{ item }">
           <span>{{ item.netMileage | toCurrency }}</span>
      </template>

      <template v-slot:item.netExpense="{ item }">
           <span>{{ item.netExpense | toCurrency }}</span>
      </template>

      <template v-slot:item.netCharge="{ item }">
           <span>{{ item.netCharge | toCurrency }}</span>
      </template>

      <template v-slot:item.netMargin="{ item }">
           <span>{{ item.netMargin | toCurrency }}</span>
      </template>

      <template slot="body.append">
         <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>

          <th class="font14">{{ netPayComputed     | toCurrency   }}</th>
          <th class="font14">{{ netMileageComputed | toCurrency  }}</th>
          <th class="font14">{{ netExpenseComputed | toCurrency }}</th>
          <th class="font14">{{ netChargeComputed  | toCurrency }}</th>
          <th class="font14">{{ netMarginComputed  | toCurrency }}</th>

          <th></th>
          <th></th>
          
      </tr>
   
       </template>
       
    </v-data-table>
    
  </div>
 
</template>

<script>

  import  { store }               from '@/store/store'
  import  { apiResource }         from '@/store/apiResource'
  import  { apiShared }           from '@/store/apiShared'
  import  axios                   from "axios";
  import  moment                  from 'moment'
  import  exportToCsvDialog       from '@/components/Dialogs/exportToCsvDialog.vue'

  export default {

     name                 : 'SubmissionsCrud',
     components           : { exportToCsvDialog },
     filters              : {
                toCurrency: function (value) {
                   var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
                   return formatter.format( value );
                }
     },

     //7/21/2023 added
     mounted() {
       this.getHoursActivity();
     },

     data         : () => ({
      
      rules :  {
                    required   : value =>  !!value  || 'Required.',
                    length10   : value =>  value.length <= 10          || 'Max 10 Characters',
                    length3    : value =>  value.length <= 3           || 'Max 3  Characters',
                    length5    : value =>  value.length <= 5           || 'Max 5 Characters',
                    length6    : value =>  value.length <= 6           || 'Max 6  Characters',
                    float      : value =>  !isNaN( parseFloat(value) ) || 'Invalid Input',
                    integer    : value =>  !isNaN( parseInt(value) )   || 'Invalid input'
                    
                },

        mileageRule : [

            function(v) {
                  
                  if (!v || v.length < 0) {
                     return 'Mileage use 0 if none';
                  } else if ( v > 999.99 ) {
                     return  'Max Mileage is 999.99';
                  } else if ( v < 0.00 ) {
                     return  'Min Mileage is 0';
                  }else
                     return true;
            }  
   
        ],

        expenseRule : [

              function(v) {
          
                if (!v || v.length < 0) {
                  return 'Expenses use 0 if none';
                } else if ( v > 499.99 ) {
                   return 'Max Expenses are 499.99';
                } else if ( v < 0.00 ) {
                   return 'Min Expenses are 0.00';
                }else
                   return true;
              }  

        ],

      lookupLoading            : false,
      closeHoursLoading        : false,
      openHoursLoading         : false,
      menu                     : false,

      sortBy        : "created_at",
      sortDesc      : false,

    hoursActivityOpen        : false,

      store,
      statusItems              : ["Pending","Accepted","Declined"],
  
      submissionFilter         : {
            statusSelected     : "pending",
            candidateFilter    : null,
      },

      dialogEdit            : false,
      dialogDelete          : false,
      dialogDecline         : false,
      dialogAccept          : false,
      dialogLoading         : false,
    
      submissionFormValid : false,
      submissionFormRules : { required: [value => !!value || "Required."] },
      
      editedIndex           : -1,
            
      editedItem: {

        id             : '',
        fullName       : '',
        email          : '',
        userId         : '',
        portmanId      : '',
        homePhone      : '',
        cellPhone      : '',
        hours          : '',
        mileage        : '',
        expenses       : '',
        status         : '',
        weekEnding     : '',
        rollOver       : '',
        pma            : '',
      },
      
      defaultItem: {
        id             : '',
        fullName       : '',
        email          : '',
        userId         : '',
        portmanId      : '',
        homePhone      : '',
        cellPhone      : '',
        hours          : '0.0',
        mileage        : '0',
        expenses       : '0.00',
        status         : '',
        weekEnding     : '',
        rollOver       : false,
        pma            : true,
      },

    }),

     computed     : {

              hoursActivityOpenSwitchLabel() {
                return (this.hoursActivityOpen) ? "Submissions open" : "Submissions closed";  
              },

              netPayComputed(){
                    let summary = 0.00;
                    this.filteredItems.forEach(  function(item) {
                          summary   = summary + item.netPay;
                    });
                    return (summary);
              },
    
              netMileageComputed(){
                    let summary = 0.00;
                    this.filteredItems.forEach(  function(item) {
                          summary   = summary + item.netMileage;
                    });
                    return (summary);
              },
              
              netExpenseComputed(){
                    let summary = 0.00;
                    this.filteredItems.forEach(  function(item) {
                          summary   = summary + parseFloat(item.netExpense);
                    });
                    return (summary);
              },
              
              netChargeComputed(){
                    let summary = 0.00;
                    this.filteredItems.forEach(  function(item) {
                          summary   = summary + item.netCharge;
                    });
                    return (summary);
              },

              netMarginComputed(){
                    let summary = 0.00;
                    this.filteredItems.forEach(  function(item) {
                          summary   = summary + item.netMargin;
                    });
                    return (summary);
              },

              computedDateFormattedMomentjs () {
                return this.editedItem.weekEnding ? moment(this.editedItem.weekEnding).format('dddd, MMMM Do YYYY') : ''
              },

            endofWeek () {
              var newDate = moment(); 
              return newDate.day(newDate.day() >= 5 ? 5 :-2).format('YYYY-MM-DD');
            },

            filteredItems() {

                var filteredResults = [];
                

                  if ( this.store.crudComponent.items ) {
                      
                      filteredResults = this.store.crudComponent.items.filter( ( item )  => {
                            return ( item['status'].toLowerCase() === this.submissionFilter.statusSelected) 
                      });
               
               
                      if (this.submissionFilter.candidateFilter != null) {
 
                            var candidateFilterResult = filteredResults.filter( ( item )  => {
                                 return ( item['fullName'].toLowerCase().startsWith( this.submissionFilter.candidateFilter.toLowerCase() ) ); 
                            });

                            filteredResults = candidateFilterResult;
                      }
                  }
                
                  return filteredResults;
            },

           headers() {
                 return this.store.crudComponent.headers;
           },
           
           sort() {
          return this.store.crudComponent.sort;
           },
      
           formTitle () {
           return this.editedIndex === -1 ? 'New ' + this.store.resource : 'Edit ' + this.store.resource
           },

          pendingCount() { 
              
            let result = [];
              
              if (this.store.crudComponent.items) {
                  result = this.store.crudComponent.items.filter( ( item )  => {
                      return ( item['status'].toLowerCase() === "pending") 
                  });
              }

                return result.length;
          },

          declinedCount() {

              let result = [];
            
              if (this.store.crudComponent.items) {
                  result = this.store.crudComponent.items.filter( ( item )  => {
                      return ( item['status'].toLowerCase() === "declined") 
                });
              }

              return result.length;
          },

          acceptedCount() {
      
              let result = [];
          
              if (this.store.crudComponent.items) {
              
                    result = this.store.crudComponent.items.filter( ( item )  => {
                      return ( item['status'].toLowerCase() === "accepted") 
                });
              }

              return result.length;
          },
      
     },

     methods      : {

      generatePDF() {
            apiShared.generatePDF('Submissions');
      },

      hoursActivityOpenOnChange() {
          console.log(`Hours Activity is now ${ this.hoursActivityOpen ? "open" : "closed"}`);
          
          if (this.hoursActivityOpen)
              this.openHours();
          else
              this.closeHours();
              

      },

      itemHoursMoreThan37(item) {
         return item.hours > 37 ? 'custom-highlight-row' : ''
      },

      async lookup(){ 
           
        try {
           
               const  baseUrl       =  apiResource.getResourceUrl( "lookup" );

               this.lookupLoading    = true;
                const response       =  await axios.post(baseUrl, this.editedItem);
               this.lookupLoading     = false;
       
               if ( response.data.status ) {

                      this.editedItem.userId              = response.data.res.id;
                      this.editedItem.fullName            = response.data.res.fullName;
                      this.editedItem.portmanId           = response.data.res.portmanId;
                      this.editedItem.email               = response.data.res.email;

                      this.editedItem.status              = "pending";
                      this.editedItem.submitter           = "Adminstrator";
                      this.editedItem.rollOver            = false;
                      this.editedItem.weekEnding          = this.endofWeek;
                      
                } else {

                    store.toastDialog.subtext   = "Lookup Candidate";
                    store.toastDialog.text      = "Candidate Not Found";
                    store.toastDialog.show      = true;   
                }

        } catch ( error) {
            
                console.log(error);
                store.toastDialog.subtext   = "Lookup Candidate";
                store.toastDialog.text      =  error.response.data.message;
                store.toastDialog.show      =  true;
        }

      },

      async refreshTable(){
           await apiResource.getCrudItems();
           return true;
      },

      setFilter(status) { 
            this.submissionFilter.statusSelected = status.toLowerCase();
      },

      formatExpenses(item){
          var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
          return formatter.format( item.expenses );
      },

      formatDate(item) {

          let   date         = new Date( item);
          const options    = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',timeZone: 'Europe/London'};
          let   intlDateObj  = new Intl.DateTimeFormat('en-GB', options);
          let   gbTime      = intlDateObj.format(date);
          return  gbTime;
      },

      formatWkEnding(item) {

       let date         = new Date( item);
       const options    = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',timeZone: 'Europe/London'};
       let intlDateObj  = new Intl.DateTimeFormat('en-GB', options);
       let gbTime       = intlDateObj.format(date);
       return  gbTime;
    
      },

      formatRollOver(item) {
          return (item == 0 ) ? "No" : "Yes"
      },

      getStatusColor(status) {
        
        const statusColor = {
                        pending   : "pendingText",
                        declined  : "declinedText",
                        accepted  : "acceptedText"
        };

        return statusColor[status]
      },
        
      /////////////////////////////////
      // show dialogs for each action//
      /////////////////////////////////
      editItem   (item) {
        this.editedIndex =  this.store.crudComponent.items.indexOf(item)
        this.editedItem  =  Object.assign( {}, item)
        this.dialogEdit  =  true
      },

      deleteItem (item) {
        this.editedIndex   =  this.store.crudComponent.items.indexOf(item)
        this.editedItem    =  Object.assign({}, item)
        this.dialogDelete  =  true
      },

      acceptItem (item) {
        this.editedIndex   =  this.store.crudComponent.items.indexOf(item)
        this.editedItem    =  Object.assign({}, item)
        this.dialogAccept =  true
      },

      declineItem (item) {
        this.editedIndex   =  this.store.crudComponent.items.indexOf(item)
        this.editedItem    =  Object.assign({}, item)
        this.dialogDecline=  true
      },

      
      //////////////////////////////////////////
      // action function for each dialog above//
      //////////////////////////////////////////
      async editItemAction  ( confirmed ) {

        if ( confirmed) {
            
              this.dialogLoading  = true;
     
                  if (this.editedIndex > -1) {
                         let res = await apiResource.sendSubmissionEmail( "Updated", this.editedItem);
                         if ( res.status === 200 ) 
                              await this.updateSubmission( this.editedItem, this.editedIndex);
                  } else {
                      
                      if ( !this.isDuplicateSubmission( this.editedItem ) ) {
                           let res = await apiResource.sendSubmissionEmail( "Submitted", this.editedItem );
                           if ( res.status === 200 ) 
                              await this.insertSubmission( this.editedItem);
                      }

                  }
              
              this.dialogLoading  = false;
        } 

       this.dialogEdit     = false;

        this.$nextTick(() => {
          this.editedItem  = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async deleteItemAction ( confirmed ) {

            if ( confirmed) {
              this.dialogLoading  = true;
                
                let res = await apiResource.sendSubmissionEmail( "Deleted", this.editedItem);
                if ( res.status === 200 ) 
                      await apiResource.deleteResource(this.editedItem,this.editedIndex);

              this.dialogLoading  = false;
            }

            this.dialogDelete    =  false
            
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
      },
     
      async declineItemAction ( confirmed ) {

            if ( confirmed) { 
               this.dialogLoading  = true;
                   
                   let  res =  await apiResource.sendSubmissionEmail( "Declined", this.editedItem );
                   if ( res.status === 200 )
                        await apiResource.declineSubmission(this.editedItem, this.editedIndex );

               this.dialogLoading  = false;
            }

            this.dialogDecline    =  false
       
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
      },

      async acceptItemAction ( confirmed ) {
        
        if ( confirmed) {
         
              this.dialogLoading  = true;
      
                 let res = await apiResource.sendSubmissionEmail( "Accepted", this.editedItem );
                 if ( res.status === 200 ) 
                      await apiResource.acceptSubmission ( this.editedItem,  this.editedIndex );
      
              this.dialogLoading  = false;
        } 

       this.dialogAccept   = false;

          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })

      },
          
      ///////////////////////////////////////////////////////////////////
      // insert and update submission, different for the submission view/
      ///////////////////////////////////////////////////////////////////
      async insertSubmission ( editedItem ){

            const  baseUrl      =  apiResource.getResourceUrl( "submission" );
            const  url          = `${baseUrl}`;

            try {

                const response      = await axios.post( url, editedItem );
                if ( response.data.status ) {
                    this.refreshTable();
                }

            } catch (error) {

                console.log(error);
                store.toastDialog.subtext   = "User Submission Error";
                store.toastDialog.text      = error.response.data.message;
                store.toastDialog.show      = true;
            }

      },

      async updateSubmission  ( editedItem ){

        const  baseUrl       =  apiResource.getResourceUrl( "submission" );
        const  url           = `${baseUrl}/${editedItem.id}`;

            try {

                const response      = await axios.put( url, editedItem );
                if ( response.data.status ) {
                    this.refreshTable();
                }

            } catch (error) {

                console.log(error);
                store.toastDialog.subtext   = "User Submission Error";
                store.toastDialog.text      = error.response.data.message;
                store.toastDialog.show      = true;
            }

      },

      async openHours () {
              
              this.openHoursLoading  = true;
              console.log("openhours would be called"); 
              // just for now
              // await apiResource.openHours();
              this.openHoursLoading  = false;
              this.hoursActivityOpen = true;

              return true;
      },
  
      async closeHours () {

              this.closeHoursLoading  = true;
              console.log("closehours would be called"); 
              // just for now
              // await apiResource.closeHours();
              this.closeHoursLoading  = false;
              this.hoursActivityOpen  = false;

              return true;
      },

      getHoursActivity            : function () {

        const  baseUrl      =  apiResource.getResourceUrl( "activity" );
        const  url          = `${baseUrl}`;
     
        axios.get( url)
        .then( response => {
            if ( response.data ) {
                 var activities         = response.data;
                 let hoursObj           = activities.find( (el) => el['activity'] === "hours");
                 this.hoursActivityOpen = ( hoursObj.isOpen == 'yes') ? true : false;
            }
        })
        .catch((error) => { // error is handled in catch block
            if (error.response) { // status code out of the range of 2xx
              console.log("Data :" , error.response.data);
              console.log("Status :" + error.response.status);
            } else if (error.request) { // The request was made but no response was received
              console.log(error.request);
            } else {// Error on setting up the request
              console.log('Error', error.message);
            }
          });
        
      },

      isDuplicateSubmission( editedItem ){

                            let duplicate = this.store.crudComponent.items.filter( ( item )  => {
                                if ( ( editedItem['portmanId'].toLowerCase() == item['portmanId'].toLowerCase() ) &&
                                     ( editedItem['weekEnding'] == item['weekEnding'] ) )
                                      {  return item }
                            });

                            if (duplicate.length > 0) {
                                store.toastDialog.subtext   = "Submission";
                                store.toastDialog.text      = "Duplicate Submission...";
                                store.toastDialog.show      = true;   
                            }
  
                            return ( duplicate.length > 0 ) ? true : false;
      },

    
     }
    
  }

</script>

<style>

    .my_class td{
            font-size:smaller!important;
            height: 1!important;
            padding: 1px!important;
    }

.simple { 
  background-color: none !important;
}

.simple:hover {
  background-color: none !important;
}

.rollOverText {
  color: red;
}

.declinedText {
  color: red;
}
.pendingText {
  color:  orange;
}
.acceptedText {
  color: green;
}

#submissionTable > div.v-data-table__wrapper > table > thead
{
  background-color: #DFC049 !important;
  border-radius: 6px 6px 6px 6px;
 
}

table {
  padding: 10px;
  background-color : rgb(248,248,248)
}


.on-hover{
  background-color: rgb(248,248,248);
  opacity: 0.08;
}

.custom-highlight-row{
   background-color: lightyellow
}

.custom-highlight-row:hover{
   background-color: lightyellow !important;
}

.switch-center {
  display: flex;
  justify-content: center;
}

.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset) .v-input--switch__thumb {
  color: red 
}

.theme--light.v-input--switch .v-input--switch__track {
  color: red
  
}

</style>


