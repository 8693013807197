<template>
  
  <v-row>

          <v-col cols="12" md="4">
             <DashboardWidgetSubmission />
          </v-col>

          <v-col cols="12" md="4">
             <DashboardWidget  resource="user"/>
          </v-col>

          <v-col cols="12" md="4">
             <DashboardWidget  resource="admin"/>
          </v-col>

          <v-col cols="12" md="4">
             <DashboardWidget resource="client"/>
          </v-col>

          <v-col cols="12" md="4">
             <DashboardWidget resource="umbrella"/>
          </v-col>

          <v-col cols="12" md="4">
             <DashboardWidget resource="consultant"/>
          </v-col>
         
  </v-row>
     
</template>


<script>

import DashboardWidget                  from '@/views/dashboard/widgets/DashboardWidget.vue'
import DashboardWidgetSubmission        from '@/views/dashboard/widgets/DashboardWidgetSubmission.vue'

export default {

    components : { DashboardWidget,DashboardWidgetSubmission },
    name       : 'Dashboard',
    
    data () { 
      return {
      }
   },
   
  }

</script>