<template>
 
  <v-card  color="#F8F8F8" class="greeting-card">
 
    <v-row class="ma-0 pa-0">
      
      <v-col class="setEqual" cols="12">

        <p class="text-h7 pcrWidgetTitle d-flex justify-center py-2">
          <span>Submissions</span>
        </p>
        
        <p text-h6 class="text-center">
          <span class="orange--text">You have <strong>{{ counts['pending']  }}</strong> Pending</span> 
        </p>

        <p text-h6 class="text-center">
          <span class="red--text">You have <strong>{{ counts['declined'] }}</strong> Declined</span> 
        </p>

        <p  text-h6 class="text-center">
          <span class="green--text">You have <strong>{{ counts['accepted'] }}</strong> accepted</span> 
        </p>

        <v-card-text class="d-flex justify-center pt-5">
             <router-link  class ="black--text" 
                          :to    = " {name: 'submissions'}">
                          Click here
             </router-link>
             <span class="pl-1">to Manage</span>
        </v-card-text>


      </v-col>

    </v-row>
 
 </v-card>
 
</template>

<script>

import { apiResource }                  from '@/store/apiResource'
import  axios                           from "axios";

export default {
  
    data() {
          return {
            counts   : {
              pending  : 0,
              declined : 0,
              accepted : 0
            }
          };
    },

    mounted() {

        console.log("widget Submission Mounted")
        this.getResourceCount("pending");
        this.getResourceCount("declined");
        this.getResourceCount("accepted");  
    },
    
    methods    : {
   
    getResourceCount(resource) { 

            const   baseUrl       =  apiResource.getResourceUrl( "dashboard" );
            const   url           = `${baseUrl}/${resource}`;

            try {

              console.log("dashboard Get Resource Count for :", url );
              axios.get( url ).then( response => {
                if ( response.data.status ) {
 
                        console.log(`Resource Count Response : ${ url } : ${response.data.res}`);
                         this.counts[resource] = response.data.res;
                         setTimeout(() => { 
                            this.getResourceCount(resource);
                         }, 60000);
       
                      }
              });

          } catch ( error) {
            console.log(error);
          }
    },

    viewClicked() {
       const route = `/${this.resource}s`;
       this.$router.push( route);
    }

  }
}
</script>


<style scoped>

.greeting-card {
  position: relative;
}

.pcrWidgetTitle {
  background-color: #DFC049 !important;
  border-radius: 10px;
}

.setEqual {
  min-height: 250px !important;
}

</style>
