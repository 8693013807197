<template>
    

    <v-data-table
        :headers       = "headers"
        :items         = "filteredItems"
        :sort-by       = "sort"
         class         = "elevation-1"
        :loading       = "store.crudComponent.loading"
         loading-text  = "Data is Loading... Please wait"
    >
     
      <template v-slot:top>
        
        <exportToCsvDialog :items= "filteredItems" title="Export Umbrellas to CSV" gridName="Umbrellas"/>
        
        <v-toolbar flat>
          
          <v-toolbar-title class="text-capitalize">{{store.resource}}s</v-toolbar-title>
 
          <v-divider  class="mx-4" inset vertical></v-divider>

          <v-text-field
            rounded
            dense
            solo
            class="app-bar-search flex-grow-0 mx-4"
            hide-details
            prepend-inner-icon ="mdi-magnify"
             v-model = "filter"
            label    = "Filter ..."
          ></v-text-field>
 
         <v-divider  class="mx-4" inset vertical></v-divider>

         <v-spacer></v-spacer>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
          </v-tooltip>
                
          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                        <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>

                    </template>
                    <span>Create PDF</span>
          </v-tooltip>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="dialogEdit = true">
                        <v-icon color="#B9802B" size="28px">mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Umbrella</span>
          </v-tooltip>
          
          <v-dialog   @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogEdit" max-width="500px">

            <v-card>
             
              <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>

              <v-form v-model="umbrellaFormValid" ref="umbrellaForm">
              <v-card-text>
                  
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.umbrellaName"
                        label="Umbrella Name"
                        :rules="umbrellaFormRules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model = "editedItem.contactName"
                        label   = "Contact Name"
                        prepend-inner-icon="mdi-card-account-mail-outline" 
                        :rules="umbrellaFormRules.required"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="editedItem.contactEmail"
                        label="Contact Email"
                        prepend-inner-icon="mdi-email"
                        :rules="umbrellaFormRules.required"
 
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.contactPhone"
                        label="Contact Phone"
                        prepend-inner-icon="mdi-phone"
                        :rules="umbrellaFormRules.required"
                       ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">

                      <v-select
                       label      = "Function"
                       :items   = "['Email', 'Other']"
                       v-model  = "editedItem.function"
                       prepend-inner-icon="mdi-adjust"
                       :rules="umbrellaFormRules.required"
                       ></v-select>


                    </v-col>
                  </v-row>

                  <v-row dense>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.umbrellaVAT"
                        label="VAT Number"
                        prepend-inner-icon="mdi-text-box"
                        :rules="umbrellaFormRules.required"
                       ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.companyNumber"
                        label="Company Number"
                        prepend-inner-icon="mdi-text-box"
                        :rules="umbrellaFormRules.required"
                       ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="editedItem.umbrellaAddress"
                        label="Address"
                        prepend-inner-icon="mdi-map-marker"
                        :rules="umbrellaFormRules.required"
                       ></v-text-field>
                    </v-col>
                  
                  </v-row>

              </v-card-text>
              
              <v-card-actions>
            
                <v-col class="text-right">
                  <v-btn
                    color="#B9802B"
                    text
                    @click="editItemAction(false)"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="#B9802B"
                    text
                    :loading = "dialogLoading"
                    @click="editItemAction(true)"
                    :disabled="!umbrellaFormValid"
                  >
                    Save
                  </v-btn>
                </v-col>
            
              </v-card-actions>

              </v-form>

            </v-card>

          </v-dialog>

          <v-dialog @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogDelete" max-width="500px">
            <v-card>
              
              <v-card-title class="text-h6">Are you sure you want to delete this {{store.crudComponent.resource}}?</v-card-title>
              <v-card-text  class="text-h6">Client Name: {{ editedItem.umbrellaName}}</v-card-text>
             
              <v-card-actions>
                
                <v-col class="text-right">
                  <v-btn color="blue darken-1" text @click="deleteItemAction(false)">Cancel</v-btn>
                  <v-btn color="blue darken-1" text :loading = "dialogLoading" @click="deleteItemAction(true)">OK</v-btn>
                </v-col>
              
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>

      </template>
      
      <template v-slot:item.actions="{ item }">
        <v-menu dark offset-y left bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark color="#B9802B" v-bind="attrs" v-on="on">Manage
              </v-btn>
            </template>
            
            <v-list dark>
            <v-list-item  @click="editItem(item)">
              <v-icon class="mr-2">mdi-pencil</v-icon>
              <v-list-item-title>Edit Umbrella</v-list-item-title>
            </v-list-item>

            <v-list-item  @click="deleteItem(item)">
              <v-icon class="mr-2">mdi-delete</v-icon>
              <v-list-item-title>Delete Umbrella</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-menu>
      </template>
       
    </v-data-table>

</template>

<script>

  import { store }       from '@/store/store'
  import { apiResource } from '@/store/apiResource'
  import { apiShared }           from '@/store/apiShared'
  import   exportToCsvDialog     from '@/components/Dialogs/exportToCsvDialog.vue'
  
  export default {

    name          : 'UmbrellasCrud',
    components    : { exportToCsvDialog },
    data          : () => ({
    
      store,
      filter           : '',

      dialogEdit       : false,
      dialogDelete     : false,
      dialogLoading    : false,
      dialogTableToCSV : false,

      umbrellaFormValid : false,
      umbrellaFormRules : { required: [value => !!value || "Required."] },

      editedIndex      : -1,
            
      editedItem: {
        id                : '',
        umbrellaName      : '',
        umbrellaAddress   : '',
        umbrellaVAT       : '',
        contactName       : '',
        contactEmail      : '',
        contactPhone      : '',
        function          : '',
        companyNumber     : '',
      },
      
      defaultItem: {
        id                : '',
        umbrellaName      : '',
        umbrellaAddress   : '',
        umbrellaVAT       : '',
        contactName       : '',
        contactEmail      : '',
        contactPhone      : '',
        function          : '',
        companyNumber     : '',
      },

    }),
    computed      : {

      filteredItems() {

        if ( this.filter.length > 2 ) {
            
            const filtereditems  = this.store.crudComponent.items.filter( item => {
               return item.umbrellaName.toLowerCase().startsWith( this.filter.toLowerCase());
            })

            return filtereditems;
        } 

        return this.store.crudComponent.items;

      },

      headers() {
          return (this.store.crudComponent.items.length > 0) ? this.store.crudComponent.headers : []
      },
      sort() {
           return this.store.crudComponent.sort;
      },
   
      formTitle () {
        return this.editedIndex === -1 ? 'New Umbrella (UC)' : 'Edit Umbrella (UC)'
      },
      
    },

   
    methods: {

      generatePDF() {
        apiShared.generatePDF('Umbrellas');
      },

      editItem (item) {
        this.editedIndex =  this.store.crudComponent.items.indexOf(item)
        this.editedItem  =  Object.assign( {}, item)
        this.dialogEdit  =  true
      },

      deleteItem (item) {
        this.editedIndex   =  this.store.crudComponent.items.indexOf(item)
        this.editedItem    =  Object.assign({}, item)
        this.dialogDelete  =  true
      },


      async deleteItemAction ( confirmed ) {

        if ( confirmed) {
          this.dialogLoading  = true;
            await apiResource.deleteResource(this.editedItem,this.editedIndex);
          this.dialogLoading  = false;
        }

        this.dialogDelete    =  false
 
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
      },

      async editItemAction ( confirmed ) {

        if ( confirmed) {

          this.dialogLoading  = true;

            if (this.editedIndex > -1) {
                  await apiResource.updateResource(this.editedItem, this.editedIndex );
            } else {
                  await apiResource.insertResource(this.editedItem );
            }

          this.dialogLoading  = false;
        } 

         this.dialogEdit     = false;

          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
      },

    },
  }

</script>