<template>

    <div>
  
    <exportToCsvDialog :items= "filteredItems" title="Export Candidates to CSV" gridName="Candidates"/>

    <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogEdit" max-width="600px">
    
             <v-card>
             
              <v-card-title>
                  <span class="text-capitalize text-h5">{{ formTitle }}</span>
              </v-card-title>
         
 
              <v-form v-model="candidateFormValid" ref="candidateForm">
     
                  <v-card-text>
                
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                            prepend-icon="mdi-account"
                            v-model="editedItem.fullName"
                            label="Candidate Full Name"
                            :rules="candidateFormRules.required"
                            clearable
                            variant="outlined"
                            hint="Enter Candidates full Name"
                          >
                       </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                           prepend-icon="mdi-email"
                            variant="outlined"
                            v-model="editedItem.email"
                            label="Candidate Email"
                            :rules="candidateFormRules.required"
                            clearable
                            hint="Enter Candidates Email address"
                          ></v-text-field>
                        </v-col>

                      </v-row>
                      
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model = "editedItem.userName"
                            label   = "User Name"
                            :rules="candidateFormRules.required"
                            prepend-icon  = "mdi-account-circle"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field 
                            v-model  = "editedItem.password"
                            label    = "Password"
                            :rules    ="candidateFormRules.required"
                            prepend-icon  = "mdi-lock"
                            :append-icon   = "value ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append  = "() => (value = !value)"
                          :type           = "value ? 'password' : 'text'"
                          :disabled       = "isEditMode"

                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-text-field
                            type="date"
                            v-model="editedItem.startDate"
                            label="Start Date"
                            prepend-icon  = "mdi-calendar"
                            clearable
                            :rules="candidateFormRules.required"

                          ></v-text-field>
                        </v-col>
                      
                        <v-col cols="12" md="6">
                          <v-text-field
                          label      = "Portman Id"
                          v-model    = "editedItem.portmanId"
                          :rules="candidateFormRules.required"
                          prepend-icon  = "mdi-book-account"

                          ></v-text-field>
                        </v-col>
                      </v-row>
                  
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.homePhone"
                            label="Home Phone"
                            :rules="candidateFormRules.required"
                            prepend-icon  = "mdi-phone"
                          ></v-text-field>
                        </v-col>
                    
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.cellPhone"
                            label="mobile Phone"
                            :rules="candidateFormRules.required"
                            prepend-icon  = "mdi-phone"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="12" md="4">
                          <v-select
                          label      = "Consultant"
                          item-text  = "consultantName"
                          item-value = "id"
                          :rules     ="candidateFormRules.required"
                          :items   = "store.uiArrays.consultant"
                          v-model  = "editedItem.consultant"
                          ></v-select>
                        </v-col>
                    
                        <v-col cols="12" md="4">
                          <v-select
                          
                          label      = "Umbrella"

                          item-text   = "umbrellaName"
                          item-value  = "id"

                          :items   = "store.uiArrays.umbrella"
                          
                          v-model  = "editedItem.umbrella"
                          
                          :rules="candidateFormRules.required"

                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="4">

                          <v-select
                          label    = "Client"

                          item-text  = "clientName"
                          item-value = "id"

                          :items   = "store.uiArrays.client"
                          v-model  = "editedItem.client"
                          :rules="candidateFormRules.required"

                          ></v-select>

                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.payRate"
                            :label= "payRateText"
                            :rules="candidateFormRules.required"
                            type="number"
                            clearable
                          ></v-text-field>
                        </v-col>
                    
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.chargeRate"
                            :label="chargeRateText"
                            :rules="candidateFormRules.required"
                            type="number"
                            clearable
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.mileageRate"
                            label="Mileage Rate"
                            :rules="candidateFormRules.required"
                            type="number"
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                                     
               
                        <v-row dense>
                        <v-col cols="12" md="6">
                          <v-checkbox  class  = "px-2" v-model= "editedItem.dayRateCandidate"
                                       label  = "Day Rate Candidate"
                          ></v-checkbox>
                        </v-col>
                    
                        <v-col cols="12" md="6">
                          <v-checkbox  class  = "px-2" v-model= "editedItem.tempCandidate"
                                       label  = "Temporary Candidate"
                          ></v-checkbox>
                        </v-col>

                      </v-row>
               

                  </v-card-text>
                  
                  <v-card-actions>
                
                    <v-col class="text-right">
                      <v-btn
                        color="#B9802B"
                        text
                        @click="editItemAction(false)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="#B9802B"
                        text
                        :loading   = "dialogLoading"
                        :disabled  = "!candidateFormValid"
                        @click="editItemAction(true)"
                      >
                        Save
                      </v-btn>
                    </v-col>
                
                  </v-card-actions>

              </v-form>

            </v-card>
    </v-dialog>


          
    <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogDelete" max-width="500px">
            <v-card elevation="2">
              
              <v-card-title >Confirm Deletion of Candidate ( {{editedItem.portmanId}} )</v-card-title>
              <v-divider></v-divider>
                     
              <v-card-subtitle class="pt-2">Candidate  : {{ editedItem.fullName }}</v-card-subtitle>
             
              <v-card-actions class="text-right">
                <v-col class="text-right">
                  <v-btn color="#B9802B" text  @click="deleteItemAction(false)">Cancel</v-btn>
                  <v-btn color="#B9802B" text  :loading = "dialogLoading" @click="deleteItemAction(true)">Delete</v-btn>
                </v-col>
              </v-card-actions>
         
            </v-card>
    </v-dialog>

    <v-dialog  @keydown.esc="editItemAction(false)" @click:outside="editItemAction(false)" v-model="dialogPassword" max-width="500px">
            <v-card elevation="2">
              
              <v-card-title >Password Reset : ( {{editedItem.portmanId}} )</v-card-title>
              <v-divider></v-divider>

              <v-card-subtitle class="pt-2">Candidate  : {{ editedItem.fullName }}</v-card-subtitle>
                     
              <v-card-text>

                <v-form ref="resetPassword" v-model="formIsValid">

                <v-text-field
                  hint="Enter the Password for this candidate"
                  autofocus
                  v-model = "password"
                  :rules="[ v => !!v || 'Password is required' ]"
                  required
                  label ="Password"
                  :append-icon    = "value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append   = "() => (value = !value)"
                  :type           = "value ? 'password' : 'text'"
                ></v-text-field>
            
                </v-form>

              </v-card-text>

              <v-card-text>
                This form will reset the password for the Candidate, the password is encrypted, as such you cannot retrieve the passport
              </v-card-text>


            
              <v-card-actions class="text-right">
                <v-col class="text-right">
                  <v-btn color="#B9802B" text  @click  = "passwordItemAction(false)">Cancel</v-btn>
                  <v-btn :disabled = "!formIsValid" 
                          color="#B9802B"
                          text
                          @click  = "passwordItemAction(true)"
                          :loading = "loading">Reset</v-btn>
                </v-col>
              </v-card-actions>
         
            </v-card>
    </v-dialog>
  
    <v-data-table
        :headers        = "headers"
        :items          = "filteredItems"
        :sort-by.sync   = "sortBy"
        :sort-desc.sync = "sortDesc"
        class           = "elevation-1"
        :loading        = "store.crudComponent.loading"
        loading-text    = "Data is Loading... Please wait"
        show-expand
        single-expand
        expand-icon     = "mdi-information"
    >

      <template v-slot:item.startDate ="{ item }">
        <span>
          <strong>
            {{ new Date(item.startDate).toLocaleString('en-GB', { year: "numeric",month: "numeric", day: "numeric", timeZone: 'UTC' }) }}
         </strong>
        </span> 
      </template>
   
      <template v-slot:item.dayRateCandidate   = "{ item }">
            <v-simple-checkbox :value = "item.dayRateCandidate" disabled></v-simple-checkbox>
      </template>
   
      <template v-slot:item.tempCandidate   = "{ item }">
            <v-simple-checkbox :value = "item.tempCandidate" disabled></v-simple-checkbox>
      </template>
   
      <template v-slot:top>
            
       <v-toolbar flat>
          
          <v-toolbar-title class="text-capitalize"> {{ toolbarTitle }}</v-toolbar-title>
      
          <v-divider  class="mx-4" inset vertical></v-divider>

          <v-text-field
            rounded
            dense
            solo
            prepend-inner-icon ="mdi-magnify"
            class="app-bar-search flex-grow-0 mx-4"
            hide-details
             v-model = "filter"
            label    = "Filter ..."
          ></v-text-field>
          
          <v-divider  class="mx-4" inset vertical></v-divider>
         
         <v-spacer></v-spacer>

          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="28px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
          </v-tooltip>
                
          <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                           <v-icon color="#B9802B" size="28px">mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Create PDF</span>
          </v-tooltip>

          <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text class  ="simple" v-bind ="attrs" v-on ="on" @click="dialogEdit = true">
                      <v-icon color="#B9802B" size="28px" >mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Candidate</span>
          </v-tooltip> 
     
       </v-toolbar>

      </template>      
      
      <template v-slot:item.actions="{ item }">
        <v-menu dark offset-y left bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark color="#B9802B" v-bind="attrs" v-on="on">Manage
              </v-btn>
            </template>
            
            <v-list dark>
            <v-list-item  @click="editItem(item)">
              <v-icon class="mr-2">mdi-pencil</v-icon>
              <v-list-item-title>Edit <span class="text-capitalize"> Candidate</span></v-list-item-title>
            </v-list-item>

            <v-list-item  @click="deleteItem(item)">
              <v-icon class="mr-2">mdi-delete</v-icon>
              <v-list-item-title>Delete <span class="text-capitalize"> Candidate</span></v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item  @click="resetPassword(item)">
              <v-icon class="mr-2">mdi-lock-reset</v-icon>
              <v-list-item-title>Reset Password</v-list-item-title>
            </v-list-item>
            </v-list>

        </v-menu>
      </template>
      
      
    </v-data-table>

  </div>
  
</template>






<script>

  import { store }               from '@/store/store'
  import { apiResource }         from '@/store/apiResource'
  import { apiShared }           from '@/store/apiShared'
  import  axios                  from "axios";
  import  exportToCsvDialog      from '@/components/Dialogs/exportToCsvDialog.vue'

  export default {

    name          : 'UsersCrud',
    components    : { exportToCsvDialog },

    data          : () => ({

      store,
      filter           : '',
      value            : true,
      
      toolbarTitle     : 'Candidates',

      password             : '',
      formIsValid          : false,
      loading              : false,

      sortBy                : "email",
      sortDesc              : false,
      
      dialogEdit        : false,
      dialogDelete      : false,
      dialogLoading     : false,
      dialogPassword    : false,

      candidateFormValid  : false,
      candidateFormRules  : { required: [value => !!value || "Required."] },
    
      editedIndex      : -1,
            
      editedItem: {
        id             : '',
        fullName       : '',
        email          : '',
        portmanId      : '',
        userName       : '',
        password       : '', 
        homePhone      : '',
        cellPhone      : '',
        startDate      : '',
        payRate        : '',
        chargeRate     : '',
        mileageRate    : '',
        consultant     : '',
        umbrella       : '',
        client         : '',
        dayRateCandidate  : '',
        dayRateHoursPerDay : '',
        tempCandidate     : '',
      },
      
      defaultItem: {
        id           : '',
        fullName     : '',
        email        : '',
        portmanId    : '',
        userName     : '',
        password     : '', 
        homePhone    : '',
        cellPhone    : '',
        startDate    : '',
        payRate      : '',
        chargeRate   : '',
        mileageRate  : '',
        consultant   : '',
        umbrella     : '',
        client       : '',
        dayRateCandidate  : '',
        dayRateHoursPerDay : '',
        tempCandidate    : false,
      },

    }),
    computed      : {

      payRateText() {
        return (this.editedItem.dayRateCandidate) ? "Day Pay Rate" : "Hourly Pay Rate"
      },

      chargeRateText() {
        return (this.editedItem.dayRateCandidate) ? "Day Charge Rate" : "Hourly Charge Rate"
      },


      filteredItems() {

        if ( this.filter.length > 2 ) {
            
            const filtereditems  = this.store.crudComponent.items.filter( item => {
               return item.fullName.toLowerCase().startsWith( this.filter.toLowerCase());
            })

            return filtereditems;
        } 

        return this.store.crudComponent.items;

      },
      headers() {
          return (this.store.crudComponent.items.length > 0) ? this.store.crudComponent.headers : []
      },
      sort() {
           return this.store.crudComponent.sort;
      },
      formTitle () {
           return this.editedIndex === -1 ? 'New Candidate' : 'Edit Candidate'
      },
      isEditMode() {
            
            if (this.editedIndex > -1)
                return true
            else
                return false
                
      }

    },

    methods: {

      generatePDF() {
         apiShared.generatePDF('Candidates');
      },

 
      formatSubmittedDate(item) {
         var date = new Date( item.startDate);
         return ( date.toLocaleDateString());
      },

      //////////////////////////
      // turn on the dialogs ///
      //////////////////////////

      resetPassword(item){
        this.editedIndex                =  this.store.crudComponent.items.indexOf(item)
        this.editedItem                 =  Object.assign( {}, item)
        this.dialogPassword             =  true
    
      },

      editItem (item) {
        this.editedIndex          =  this.store.crudComponent.items.indexOf(item)
        this.editedItem           =  Object.assign( {}, item)
        this.dialogEdit           =  true
      },

      deleteItem (item) {
        this.editedIndex          =  this.store.crudComponent.items.indexOf(item)
        this.editedItem           =  Object.assign({}, item)
        this.dialogDelete         =  true
      },
      
      passwordItemAction : async function( confirmed ) {

        if ( confirmed) {

             this.loading        = true;
             const  baseUrl      =  apiResource.getResourceUrl( "password" );
             const  url          = `${baseUrl}/user/${this.editedItem.id}`;

            try {
                const  payload        = { "password" : this.password }
                const  response       = await axios.post( url, payload );
                this.loading          = false;

                if ( response.data.status ) {
                     console.log(response.data.status)
                } 
    
            } catch (error) {
          
                console.log(error);
                store.toastDialog.subtext   = "Insert Resource Error";
                store.toastDialog.text      = error.response.data.message;
                store.toastDialog.show      = true;
            }
        } 
        
        this.dialogPassword    =  false

     
      },

      async deleteItemAction ( confirmed ) {

          if ( confirmed) {
             this.dialogLoading  = true;
               await apiResource.deleteResource(this.editedItem,this.editedIndex);
             this.dialogLoading  = false;
          }
         
          this.dialogDelete    =  false
           
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
      },

      async editItemAction ( confirmed ) {
        
            if ( confirmed) {
                  this.dialogLoading  = true;

                  if (this.editedIndex > -1) {
                        await apiResource.updateResource(this.editedItem, this.editedIndex );
                  } else {
                        await apiResource.insertResource(this.editedItem );
                  }

                  this.dialogLoading  = false;
           } 

           this.dialogEdit     = false;

            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
      },

    },
  }
</script>