<template>
    <v-row>
          <v-col cols="12">
              <ConsultantsCrud />
          </v-col>
    </v-row>
</template>


<script>

import ConsultantsCrud                  from '@/components/crud/ConsultantsCrud.vue'
import { apiResource }                  from '@/store/apiResource'
import { store  }                       from '@/store/store'

export default {  

      components : { ConsultantsCrud },
      name       : 'Consultants',

      mounted () {
        console.log("Consultant mounted");
        this.initialize()
      },

      beforeMount() {
        // clean up previous crud items 
        // before computed properties activate
         console.log("Consultants beforeMount")
         apiResource.viewDestroyed();
      },
   
      
      methods    : {

        initialize () {
      
                this.store.resource            = "consultant";
                this.store.crudComponent.sort  = "consutantName";
                apiResource.getCrudHeaders ();
                apiResource.getCrudItems   ();
        },
      
      },

      data() {
          return {
            store,
          };
      }
}

</script>