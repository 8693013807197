<template>
    <v-row>
          <v-col cols="12">
              <UsersCrud />
          </v-col>
    </v-row>
</template>


<script>

import UsersCrud                        from '@/components/crud/UsersCrud.vue'
import { apiResource }                  from '@/store/apiResource'
import { store  }                       from '@/store/store'

export default {  

      components : { UsersCrud },
      name       : 'Users',
      
      mounted () {
          console.log("Candidate mounted");
          this.initialize()
      },
      
      beforeMount() {
        // clean up previous crud items 
        // before computed properties activate
         console.log("Candidates beforeMount")
         apiResource.viewDestroyed();
      },
      
      methods    : {

        initialize () {
      
                this.store.resource            = "user";
                this.store.crudComponent.sort  = "userName";
                
                apiResource.getCrudHeaders ();
                apiResource.getCrudItems   ();

                apiResource.getResourceItems("client");
                apiResource.getResourceItems("consultant");
                apiResource.getResourceItems("umbrella");
                
        },
      
      },

      data() {
          return {
            store,
          };
      }
}

</script>