<template>

    <v-row>
 
      <v-dialog      
                       @keydown.esc     = "showFileImportDialog = false"
                       @click:outside   = "showFileImportDialog = false"
                       v-model          = "showFileImportDialog"
                       max-width        = "600px">
                       
                       <v-card>
                                          
                          <v-card-title>Select Candidate File</v-card-title>

                          <v-divider></v-divider>
                         
                          <v-card-subtitle class="mt-2 font-weight-bold">
                             Please select the candidates excel or CSV file. The selected import file should contain the list of new candidates you want to import.
                          </v-card-subtitle>
                   
                          <v-card-subtitle class="font-weight-bold">
                              <input id="csvFile" type="file" accept=".csv,.xlsx,.xls" @change = "handleImportFileChange( $event )" />
                          </v-card-subtitle>
                                                                         
                          <v-card-subtitle  v-if = "this.importFile.fileChecked" class="mt-1 pt-2 font-weight-bold">

                            <v-divider></v-divider>

                            <div class="mt-2">Imported Data Record Count                : {{ importedDataRecordCount }} </div>
                            <div class="mb-2">Does the excel imported data have errors  : {{ importedDataHasError    }} </div>
                            
                            <v-divider class="mb-2"></v-divider>

                            Client Error Count       : {{ this.importData.clientError     }}<br>
                            Umbrella Error Count     : {{ this.importData.umbrellaError   }}<br>
                            Consultant Error Count   : {{ this.importData.consultantError }} <br>
                            Duplicate Email Count    : {{ this.importData.duplicateEmail  }} <br>
                            
                            <v-divider class="mt-2"></v-divider>
                          
                            <div> 
                              {{ this.importData.errorNotes }}
                            </div>
                  
                          </v-card-subtitle>

                          <v-card-subtitle  v-else class="mt-1 pt-2 font-weight-bold">
                             <v-divider></v-divider>
                          </v-card-subtitle>
   
                          <v-card-actions>
                            <v-col class="text-right">

                              <v-btn
                                class="text-capitalize"
                                color="#B9802B"
                                text
                                @click = "closeFileImportDialog"
                              >close
                              </v-btn>
                 
                            </v-col>
                          </v-card-actions>

                      </v-card>
                      


      </v-dialog>
   
      <v-col cols= "12">
           <v-data-table
              :headers        = "gridHeaders"
              :items          = "gridItems"
               class          = "elevation-1"
              :loading        = "gridLoading.state"
              :loading-text   = "gridLoading.text"
              v-model         = "gridItemsSelected"
              item-key        = "portmanId"
              single-select
              :item-class     = "importedRowHasError"
              show-expand
              single-expand
              expand-icon    = "mdi-information"
          >
     
            <template v-slot:top>

              <v-toolbar flat>
               
                  <v-toolbar-title class="text-capitalize">{{gridTitle}}</v-toolbar-title>
                  <v-divider  class="mx-4" inset vertical></v-divider>

                  <v-btn  class="simple" text @click="showFileImportDialog = true">
                      <v-icon color="#B9802B" size="28px">mdi-file-check</v-icon>
                  </v-btn>

                  <v-btn  :disabled = "gridItemLoaded == false" class="simple" text @click="ImportSelectedItems">
                      <v-icon color="#B9802B" size="28px">mdi-file-import-outline</v-icon>
                  </v-btn>

                  <v-text-field
                    :disabled = "gridItemLoaded == false"
                    single-line
                    rounded
                    dense
                    solo
                    prepend-inner-icon ="mdi-magnify"
                    class="app-bar-search flex-grow-0 mx-4"
                    hide-details
                    v-model  = "gridFilter"
                    label    = "Filter Rows ..."
                  ></v-text-field>

                  <v-spacer></v-spacer>


             
              </v-toolbar>
            </template>
            
            <template v-slot:item.rollOver =  "{ item }">
               <v-simple-checkbox :value = "item.rollOver" disabled></v-simple-checkbox>
            </template>
       
            <template v-slot:item.pma      =  "{ item }">
               <v-simple-checkbox :value = "item.pma" disabled></v-simple-checkbox>
            </template>

            <template v-slot:expanded-item =  "{ headers, item }">
      
              <td :colspan="headers.length">
                
                    <div class="row">

                      <div class="column pt-5 pb-3">
                        <div class="pb-1 pl-1">Pay Rate     : {{ item.payRate     | toCurrency  }}</div>
                        <div class="pb-1 pl-1">Charge Rate  : {{ item.chargeRate  | toCurrency  }}</div>
                        <div class="pb-1 pl-1">Mileage Rate : {{ item.mileageRate | toCurrency  }}</div>
                      </div>

                      <div class="column pt-5 pb-3">
                        <div class="pb-1 pl-1">Phone (Mobile)      : {{ item.cellphone  }}</div>
                        <div class="pb-1 pl-1">Phone (Home )       : {{ item.cellphone  }}</div>
                      </div>

                      <div class="column pt-5 pb-3">
                        <div class="pb-1 pl-1">Day Rate Candidate  : {{ item.dayRateCandidate  }}</div>
                        <div class="pb-1 pl-1">Temp Rate Candidate : {{ item.tempRateCandidate }}</div>
                      </div>

                    </div>

                    <div class="row">
                        <div class="column pt-1 pb-3">
                          <div class="pb-1 pl-2">Row Has Errors : {{ item.error }}</div>
                          <div class="pb-1 pl-2">Error          : {{ item.errorMsg }}</div>
                       </div>
                    </div>

              </td>

            </template>

           </v-data-table>
      </v-col>
   
    </v-row>

</template>


<script>

import  Papa              from 'papaparse';
import  axios             from 'axios';
import  { read, utils }   from 'xlsx';
import  { store }         from '@/store/store'
import  { apiResource }   from '@/store/apiResource'

export default {  

      name          : 'importCandidates',
      
      mounted () {

         this.getClients();
         this.getUmbrellas();
         this.getConsultants();
         this.getCandidates();
         this.parsed = false;

      },

      filters:  {
            
            toCurrency: function (value) {
               var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
               return formatter.format( value );
             }
      },
   
      computed: {

        importedDataHasError() {
          return (this.importData.error) ? "Yes" : "No"
        },

        importedDataRecordCount() {
          return ( this.gridItems.length );
        },

        gridItemLoaded() {
           return ( this.gridItems.length > 0 ) ? true : false;
        }

      },

      methods:  {

        parseCSV( csvData ){

                Papa.parse( csvData, {

                  header         :  true,
                  skipEmptyLines : 'greedy',
 
                  transformHeader: function( header ){

                    const headerMap = {
                      "LastName (ignore this col)"    : "lastName",
                      "Portman Id"                    : "portmanId",
                      "Full Name"                     : "fullName",
                      "Email"                         : "email",
                      "UserName"                      : "userName",
                      "Password ( add # at the end)"  : "password",
                      "(Phone) Mobile"                : "cellPhone",
                      "Umbrella"                      : "umbrella" ,
                      "Client"                        : "client",
                      "Start Date"                    : "startDate",
                      "Consultant"                    : "consultant",
                      "Day Rate Cand"                 : "dayRateCandidate",
                      "Temp Cand"                     : "tempRateCandidate",
                      "Pay Rate"                      : "payRate",
                      "Charge Rate"                   : "chargeRate", 
                      "Mileage"                       : "mileageRate"
                    };

                    return headerMap[header];
                  },

                  transform: function( value, field) {
                                        
                    if ( field === 'password') {
                          
                          if (value.length > 1)
                             return (value + "#");
                          else
                             return value;

                    }else
                      return value;
                  },
                         
                  complete: function( results ){
              
                      this.gridLoading.state = false;
                      this.gridItems         = results.data;
                      this.gridHeaders       = this.headers;

                      setTimeout(this.startVerification, 500);

                  }.bind(this)

              });
        },
       
        async parseExcel( fileURL ) { 

            const f     = await fetch( fileURL );
            const ab    = await f.arrayBuffer();
            const wb    = read( ab );
            const csv   = utils.sheet_to_csv(  wb.Sheets[ wb.SheetNames[0] ] );
            //////////////////////////////////////////////////
            // now process as a CSV with header on first line/ 
            //////////////////////////////////////////////////
            this.parseCSV(csv);
        },

        handleImportFileChange( event ){

              this.importFile.fileURL        = URL.createObjectURL( event.target.files[0] ); 
              this.importFile.fileName       = event.target.files[0].name;
              const tempFName                  = event.target.files[0].name;
              this.importFile.fileExtension  = tempFName.substring( tempFName.lastIndexOf(".")).toUpperCase();
              
              this.importFile.fileSelected   = true;
              this.importFile.fileChecked    = false;

              this.gridHeaders          = [];
              this.gridItems            = [];
              this.gridItemsSelected    = [];

              setTimeout( () => {

                  const fileExtension = this.importFile.fileExtension;
                  if (fileExtension == '.XLS' || fileExtension == '.XLSX') {
                       this.parseExcel( this.importFile.fileURL );
                  }else{
                       this.parseCSV( this.importFile.fileURL );
                  }

                  this.importFile.fileChecked    = true;

              }, 200);

        },


        async getClients() {

                try {

                        const resourceURL      = apiResource.getResourceUrl    ("client");
                        const response         = await axios.get( resourceURL );

                        if ( response.data.status ) {
                              this.clients  =  response.data.res;
                              console.log(this.clients);

                        }

                } catch ( error ) {
                  store.toastDialog.subtext   = "getClients Resource Error";
                  store.toastDialog.text      = error.response.data.message;
                  store.toastDialog.show      = true;
                }
                
                return ( this.clients.length);

        },

        async getUmbrellas() {
          
          try {

                const resourceURL      = apiResource.getResourceUrl    ("umbrella");
                const response         = await axios.get( resourceURL );
                if ( response.data.status ) {
                      this.umbrellas  =  response.data.res;
                      console.log(this.umbrellas);

                }

                } catch ( error ) {
                    store.toastDialog.subtext   = "getClients Resource Error";
                    store.toastDialog.text      = error.response.data.message;
                    store.toastDialog.show      = true;
                }

                return ( this.umbrellas.length);

        },

        async getConsultants() {
          
          try {

                const resourceURL      = apiResource.getResourceUrl("consultant");
                const response         = await axios.get( resourceURL );
                if ( response.data.status ) {
                      this.consultants  =  response.data.res;
                      console.log(this.consultants);
                }

                } catch ( error ) {
                    store.toastDialog.subtext   = "getConsultants Resource Error";
                    store.toastDialog.text      = error.response.data.message;
                    store.toastDialog.show      = true;
                }

                return ( this.consultants.length);

        },

        async getCandidates() {
          
          try {

                const resourceURL      = apiResource.getResourceUrl("user");
                const response         = await axios.get( resourceURL);

                if ( response.data.status ) {
                      this.candidates  =  response.data.res;
                      console.log(this.candidates);
                }

                } catch ( error ) {
                    store.toastDialog.subtext   = "get Candidates Resource Error";
                    store.toastDialog.text      = error.response.data.message;
                    store.toastDialog.show      = true;
                }

                return ( this.candidates.length);

        },


        verifyResource( resource, name ){

          var obj = undefined;
                     
           const search = name.toLowerCase();

           switch ( resource.toLowerCase() ) {

            case 'client' :   
                   obj = this.clients.find( x=> x.clientName.toLowerCase() === search);
                   break;
            case 'umbrella' :   
                   obj = this.umbrellas.find( x=> x.umbrellaName.toLowerCase() === search);
                   break;
            case 'consultant' :   
                   obj = this.consultants.find( x=> x.consultantName.toLowerCase() === search);
                   break;
            case 'email' :   
                   obj = this.candidates.find( x => x.email.toLowerCase() === search);
                   break;
            default:
                  break;     
           }

           return ( obj === undefined ) ? null : obj.id;  

        },

        startVerification() {

            console.log("Start Verification");
    
            this.importData.error                  = false;
            this.importData.recordCount            = this.gridItems.length;
            this.importData.clientError            = 0;
            this.importData.umbrellaError          = 0;
            this.importData.consultantError        = 0;
            this.importData.duplicateEmail         = 0;
                             
            this.gridItems.forEach( c => {

                    c['error']      = false;
                    c['errorMsg']   = ""; 
                    
                    if ( null == this.verifyResource( "client", c.client) ) {
                         this.importData.error        = true;
                         this.importData.clientError += 1;
                         c['error']      = true;
                         c['errorMsg']  += `${c.client} is not a valid client,` 
                    }

                    if (null == this.verifyResource( "umbrella", c.umbrella ) ) { 
                         this.importData.error          = true;
                         this.importData.umbrellaError += 1;
                         c['error']      = true;
                         c['errorMsg']  += `${c.umbrella} is not a valid UC,`;
                    }

                    if (null == this.verifyResource( "consultant", c.consultant ) ){
                          this.importData.error = true;
                          this.importData.consultantError += 1;
                          c['error']      = true;
                          c['errorMsg']  += `${c.consultant} is not a valid consultant,`;
                    }
                    
                    if ( null != this.verifyResource( "email",  c.email  ) ) {
                         this.importData.error           = true;
                         this.importData.duplicateEmail  += 1;
                         c['error']      = true;
                         c['errorMsg']  += `${c.email} email is duplicated or has been used`;
                    }

                    return c;
                                    
            } );
            this.parsed = true;

        },

        closeFileImportDialog() {

//          this.$forceUpdate();

            document.getElementById("csvFile").value = "";

            this.importData.error                  = false;
            this.importData.clientError            = 0;
            this.importData.umbrellaError          = 0;
            this.importData.consultantError        = 0;
            this.importData.duplicateEmail         = 0;
            this.importData.recordCount            = 0;
            this.importData.errorNotes             = [];
      
            this.importFile.fileSelected           = false,
            this.importFile.fileChecked            = false,
            this.importFile.fileURL                = "", 
            this.importFile.fileName               = "",
            this.importFile.fileExtension          = "",

            this.showFileImportDialog              = false;   
      
        },

        ImportSelectedItems() {
          return true;
        },

        importedRowHasError( item ) {

               console.log("importRowHasError");
               return (item.error) ? 'imported-row-has-error' : 'imported-row-no-error'
        
        },

    
      },

      data(){
        return {

            headers       : [

                          { text: '',                 value: 'data-table-expand'}, 
                          { text: 'Portman ID',       value: 'portmanId'   },
                          { text: 'Password',         value: 'password'    }, 
                          { text: 'UserName',         value: 'userName'    },
                          { text: 'Full Name',        value: 'fullName'    },
                          { text: 'Email',            value: 'email'       },
                          { text: 'Start Date',       value: 'startDate'   },
                          { text: 'Consultant',       value: 'consultant'  },
                          { text: 'Umbrella',         value: 'umbrella'    },
                          { text: 'Client',           value: 'client',     },
                          
                          { text: 'Pay Rate' ,        value: 'payRate',      align: ' d-none'},
                          { text: 'Charge Rate',      value: 'chargeRate',   align: ' d-none'},
                          { text: 'Mileage Rate',     value: 'mileageRate',  align: ' d-none'},
                          { text: 'Phone (Home)',     value: 'cellphone',   align: ' d-none'},
                          { text: 'Phone (Mobile',    value: 'cellPhone',   align: ' d-none'},
                       
            ],
    
            gridHeaders          : [],
            gridItems            : [],
            gridItemsSelected    : [],
            
            gridFilter           : "",
            gridTitle            : 'Import Candidates From Excel or CSV file',
            gridLoading          : {  state : true, text  : "Waiting for import data....." },
            
            showFileImportDialog : false,
            
            clients           : [],
            umbrellas         : [],
            consultants       : [],
            candidates        : [],

            importData        :   { 
                                     error             : false,
                                     recordCount       : 0,
                                     clientError       : 0,
                                     umbrellaError     : 0,
                                     consultantError   : 0,
                                     candidateError    : 0,
                                     duplicateEmail    : 0, 
                                     errorNotes        : [],
                                  },

            importFile        :   {
                                    fileChecked    : false,
                                    fileSelected   : false,
                                    fileURL        : "", 
                                    fileName       : "",
                                    fileExtension  : "",
                                  },                      

        }
      }
      
}

</script>


<style>

.imported-row-has-error {
   background-color : red !important;
   color            : white !important;
}

.imported-row-has-error:hover{
   background-color :  rgba(240, 153, 153, 0.604) !important;
   color            : white !important;
}

.imported-row-no-error {
   background-color : lightgreen !important;
   color            : white !important;
}

.imported-row-no-error:hover{
   background-color :  greenyellow !important;
   color            : white !important;
}

</style>