<template>


      <div class="wrapper mx-auto">

        <v-card  class="rounded-0 halfFillHeader" elevation = "1">

          <v-card-text class="d-flex white--text align-center justify-center viewTitle">
                Portman Hours Admin - Ver : {{store.version}}
          </v-card-text>
          
          <div class="d-flex align-center justify-center">
               
                 <v-btn style="background-color: white!important;" :disabled = "true" elevation="0" fab class="portmanfab portmanLogo">
                   <v-img
                     :src="require('@/assets/images/logos/portman.png')"
                     max-height = "80px"
                     contain
                  ></v-img>

             </v-btn>
          </div>
        
        </v-card>
      
        <v-card  class = "rounded-0" elevation = "1" style="padding: 20px;  background: white">

            <v-form ref="signonForm" v-model="isValid">

                <v-text-field
                  solo
                  rounded
                  v-model="credential"
                  :rules="[ v => !!v || 'email is required' ]"
                  required
                  label="your username or email"
                  placeholder="john | john@example.com"
                ></v-text-field>

                <v-text-field
                  solo
                  rounded
                  v-model ="password"
                  :rules="[ v => !!v || 'password is required' ]"
                  required
                  label ="Password"
                  :append-icon   = "value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append  = "() => (value = !value)"
                  :type           = "value ? 'password' : 'text'"
                ></v-text-field>

                <div class="d-flex align-center justify-center">
                  <v-btn
                    :loading  = "loading"
                    color='#b9802b'
                    class="lightGoldBorder white--text mt-6"
                    @click = "loginClicked"
                    :disabled="!isValid"
                  >Login
                  </v-btn>
                 </div>

              
            </v-form>
          
            <div class="mt-5 d-flex align-center justify-center">
                <router-link class="black--text" 
                            :to="{name:'PasswordReset'}">
                            Forgot Password ?
                  </router-link>

            </div>
                
            <div class="mt-2 d-flex align-center justify-center">
                Click here to  &nbsp; 
                <router-link class="black--text"
                              :to="{name:'AccessRequest'}">
                              Request Access
                  </router-link>
            </div>

        </v-card>
    
    </div>
 
</template>

<script>

import axios from "axios";
import { store }       from '@/store/store'
import { apiResource } from '@/store/apiResource'
import md5 from "js-md5";

export default {  
  
      name       : 'SignOn',
      methods    : {

      loginClicked() {

         this.loading        =  true;
         const baseUrl       =  apiResource.getResourceUrl( "auth" );
         const url           = `${baseUrl}/admin`;
         const md5Password    = md5(this.password); 
       

         // 11-20-2023 added appVersion to json
         const data = {  
                 "credential"      :  this.credential,
                 "password"        :  md5Password,
                 "appVersion"      : this.store.version
         } 
         
          axios.post(url, data).then( response => {

            if ( response.data.status == 'success') {
                   
                      this.store.user = response.data.user

                      //7/25/2023//
                      // store the logged on user object
                      if (this.store.user) {
                         localStorage.setItem("pwadmin.pmr", JSON.stringify(this.store.user) );
                      }

                      this.store.bottomNavigation = 0;
                      this.$router.push("/submissions");

                      //////////////////////////////////////////////////////
                      // also get the global setting after successful login/
                      //////////////////////////////////////////////////////
                  
            } else {
              
                  console.log( "login failed" , response.data.status);
                  this.$refs.signonForm.reset()
                  this.store.toastDialog.text      = "Login Failed, please retry";
                  this.store.toastDialog.subtext   = "Invalid login/password";
                  this.store.toastDialog.show      = true;
            }
       
          }).catch(err => {
           
                  console.log("Login Failed", err.message, data);
                  this.$refs.signonForm.reset()
                  this.store.toastDialog.text      = "Login Failed, please retry";
                  this.store.toastDialog.subtext   = err.message;
                  this.store.toastDialog.show      = true;
          });

          this.loading                     = false;
         

    
      },
 
    },

    data() {
      return {
          store,
          isValid           : true,
          value             : true,
          loading           : false,
          isPasswordVisible : false,
          credential        : null,
          password          : null,
       };
    },

}
</script>


<style>
.portmanLogo {
      height: 140px !important;
      width:  140px !important;
      background-color: white !important;
}

.halfFillHeader {
      background: linear-gradient(180deg, #B9802B 65%, #FFFFFF 35%) !important;
}

        .wrapper {
            height: 500px;
            margin-top: 10%;
        }
</style>