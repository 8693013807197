<template>

  <v-row>
    <v-col cols="12">
      <v-data-table
          :headers             = "header"
          :items               = "items"
           class              = "elevation-1"
           sort-by            = "fullName"
           item-key           = "id"
           single-expand  
           expand-icon         = "mdi-information"
          :loading             = "loading.loadingData"
          :loading-text        = "loading.loadingDataText"       
          :item-class          = "itemOnVacationClass"
          :search              = "search"
   >

      <template v-slot:top>
      
          <exportToCsvDialog :items= "items" title="Export Candidates to CSV" gridName="Processed"/>
        
          <v-toolbar flat>
      
              <v-toolbar-title>{{ reportTitle }}</v-toolbar-title>
              
              <v-divider  class="mx-4" inset vertical></v-divider>

              <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs" v-on="on" class="simple" text @click="runReport">
                          <v-icon color="#B9802B" size="38px">mdi-refresh</v-icon>
                        </v-btn>

                      </template>
                      <span>Refresh Report</span>
              </v-tooltip>
        
              <v-divider  class="mx-4" inset vertical></v-divider>

              <v-text-field
                rounded
                dense
                solo
                prepend-inner-icon ="mdi-magnify"
                class="app-bar-search mx-4"
                single-line
                hide-details
                label    = "Filter by Candidate Name  ..."
                clearable
                v-model  = "search"

              ></v-text-field>

             <v-divider  class="mx-4" inset vertical></v-divider>


              <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs" v-on="on" fab dark x-small color="#B9802B">
                          {{ itemCount }}
                        </v-btn>
                      </template>
                      <span>Item Count</span>
              </v-tooltip>

              <v-divider  class="mx-4" inset vertical></v-divider>

              <div class="flex-grow-1"></div>
              
              <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs" v-on="on" text @click="store.exportToCsvDialog = true">
                          <v-icon color="#B9802B" size="38px">mdi-file-delimited</v-icon>
                        </v-btn>

                      </template>
                      <span>Export as CSV</span>
              </v-tooltip>
                  
              <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn v-bind="attrs" v-on="on" text @click="generatePDF">
                          <v-icon color="#B9802B" size="38px">mdi-file-pdf-box</v-icon>
                        </v-btn>

                      </template>
                      <span>Create PDF</span>
              </v-tooltip>
                      
          </v-toolbar>
      
      </template>
         
       <template v-slot:item.onVacation="{ item }">

            <v-switch   v-model   = "item.onVacation"
                        :label    = "(item.onVacation) ? 'On Holiday' : 'Not On Holiday'"
                        inset
                        color    = "blue"
                        @change  = "itemOnVacationChanged(item)">
            </v-switch>
       
       </template>
               
      </v-data-table>
    </v-col>
  </v-row>

</template>


<script>

import { apiResource }                  from '@/store/apiResource'
import { apiShared }                    from '@/store/apiShared'
import { store  }                       from '@/store/store'
import { reportMap }                    from '@/views/reports/widgets/reportMap.js'

import  exportToCsvDialog               from '@/components/Dialogs/exportToCsvDialog.vue'
import  moment                          from 'moment'
import  axios                           from "axios"

export default {  

      components    : { exportToCsvDialog },
      name          : 'candidateVacation',

      mounted () {
          console.log(`${this.$options.name} has mounted`);
          this.initialize()
      },

      beforeMount() {
         console.log(`${this.$options.name} before mount`);
         apiResource.viewDestroyed();
      },
   
      computed      : {
  
        header()              {  return ( this.items.length > 0) ? this.reportHeader : []  },
        itemCount()           {  return ( this.items.length > 0) ? this.items.length : '0' },

        reportTitle()         {  return reportMap.getTitle(       this.$options.name )   },
        reportDescription()   {  return reportMap.getDescription( this.$options.name )   },
        reportRoute()         {  return reportMap.getRoute(       this.$options.name )   },
   
      },

      methods: {

        async generatePDF() {
              apiShared.generatePDF( 'Candidates On Holiday', this.weekEndingFilter );
        },

        initialize () {
                var newDate           = moment(); 
                this.weekEndingFilter = newDate.day(newDate.day() >= 5 ? 5 :-2).format('YYYY-MM-DD');
        },

        runReport: function() {

            this.items                = [];
            this.loading.loadingData  = true;

            let url            = apiResource.getResourceUrl( "report" ) + reportMap.getRoute( this.$options.name );
            axios.get( url )
            .then(  (response) => { 
                                    this.items                        = response.data;
                                    this.loading.loadingData          =  false;
            })
            .catch( (error)    => {
                          this.store.toastDialog.subtext   = "Candidate On Holiday Error";
                          this.store.toastDialog.text      = error.response.data.message;
                          this.store.toastDialog.show      = true;
            })
      
        },

        itemOnVacationChanged: function( item ){

                  let url             = apiResource.getResourceUrl( "report" ) + reportMap.getRoute( this.$options.name ) ;
                   axios.post( url, item )
                  .then(  (response) =>  console.log(response.data) )
                  .catch( (error)    => {
                          this.store.toastDialog.subtext   = "Candidate Vacation Update Error";
                          this.store.toastDialog.text      = error.response.data.message;
                          this.store.toastDialog.show      = true;
                  });
        },

        itemOnVacationClass: function (item) {
                 return item.onVacation ? 'custom-highlight-row' : ''
        },
     
      },

      watch: {
            weekEndingFilter: function () {
                 this.runReport();
            }
      },      
            
      data() {
          return {
            loading          :  {
                                  loadingData       : false,
                                  loadingDataText   : 'Loading candidate holiday view ...'
                                },

            weekEndingFilter : '',
            search           : '',                    
            reportHeader     : [
                         
                                  { text: 'Candidate',      value:  'fullName',   sortable: true  }, 
                                  { text: 'Email',          value:  'email',      sortable: true  }, 
                                  { text: 'Telephone',      value:  'cellPhone',  sortable: false }, 
                                  { text: 'Holiday Status', value:  'onVacation', sortable: true  }, 
                               ],

            items               : [],
            menuWeekEnding      : false,
            store
          };
      },

  }


</script>

<style scoped>
.custom-highlight-row{
   background-color: pink
}

.custom-highlight-row:hover{
   background-color: pink !important;
}

</style>