<template>

      <v-dialog v-model = "store.exportToCsvDialog" max-width  = "500" >

          <v-card>
            <v-card-title  class="titleColor" >{{ title }}</v-card-title>

              <v-divider></v-divider>

              <v-card-text     class="pt-2 text-center">To Export {{items.length}} {{ gridName }} as a CSV file, please press the export button, the CSV file will be saved</v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="justify-end">

               <v-btn 
                    color='#b9802b'
                    text
                    @click = "generateCSV"
               >Export</v-btn>

              <v-btn 
                    color='#b9802b'
                    text
                    @click="store.exportToCsvDialog = false"
              >Close</v-btn>

            </v-card-actions>
            
          </v-card>
      
      </v-dialog>

</template>


<script>

    import { store }      from '../../store/store'
    export default {
      name : 'exportToCsvDialog',
      props: ['items', 'title', 'gridName'],
      data () {
        return {  
          store,
        }
      },

      methods:{

        download : function (data) {
              
              // Creating a Blob for having a csv file format and passing the data with type
              const blob = new Blob([data], { type: 'text/csv' });

              // Creating an object for downloading url
              const url = window.URL.createObjectURL(blob)

              // Creating an anchor(a) tag of HTML
              const a = document.createElement('a')

              // Passing the blob downloading url
              a.setAttribute('href', url)

              // Setting the anchor tag attribute for downloading
              // and passing the download file name
              const downloadFName = this.gridName.toLowerCase() + ".csv"
              a.setAttribute('download', downloadFName);

              // Performing a download with click
              a.click()
        },

        objectToCsv : function (data) {
     
          const csvRows = [];
          const headers = Object.keys(data[0]);
          csvRows.push(headers.join(','));
  
          // Loop to get value of each objects key
          for (const row of data) {
          
            const values = headers.map(header => {
                const val = row[header]
                return `"${val}"`;
            });
      
            // To add, separator between each value
            csvRows.push(values.join(','));
          }
  
             /* To add new line for each objects values
             and this return statement array csvRows
             to this function.*/
             return csvRows.join('\n');
        },

        generateCSV : function () {

          if  (this.items.length > 0 ){
            const csvData = this.objectToCsv( this.items );
            this.download( csvData );
          }
        }

  }
}


</script>



<style scoped>

.titleColor {
   background-color: #B9802B !important;
   color: white
}

</style>
