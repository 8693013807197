<template>
    <v-row>
            <v-col cols="12">
                <SubmissionsCrud   />
            </v-col>
    </v-row>
</template>


<script>

import SubmissionsCrud                  from '@/components/crud/SubmissionsCrud.vue'
import { apiResource }                  from '@/store/apiResource'
import { store  }                       from '@/store/store'

export default {  

      components: { SubmissionsCrud},
      name       : 'Submissions',


      mounted () {
        console.log("submissions mounted")
         this.initialize()
      },
      

      beforeMount() {
        // clean up previous crud items 
        // before computed properties activate
         console.log("submissions beforeMount")
         apiResource.viewDestroyed();
      },


      methods: {

        initialize () {

          this.store.resource              = "submission";
          this.store.crudComponent.sort    = "submissionDate";

           // get the initial crud table items
           apiResource.getCrudHeaders ();

           // get the user array for the filter  combo box
           apiResource.getResourceItems("user");

           // get the initial submissions array (all submissions max 200)
           apiResource.getCrudItems ();
          
        },
      },
      
      data() {
          return { store };
      }
}

</script>