<template>
   <v-row>
      <v-col cols="12">

         <v-data-table
         :headers            = "header"
         :items              = "items"
          class              = "elevation-1"
          sort-by.sync       = "candidateName"
          sort-desc.sync     = "candidateName"
          item-key           = "id"
          single-expand  
          expand-icon       = "mdi-information"
         :loading           = "loading.loadingData"
         :loading-text      = "loading.loadingDataText"       
         :item-class        = "itemHoursMoreThan37"
   >

     <template v-slot:top>
     
        <exportToCsvDialog :items= "items" title="Export Processed to CSV" gridName="Processed"/>
      
        <v-toolbar flat>

              <v-toolbar-title >{{report['name']}}</v-toolbar-title>
            
             <v-divider  class="mx-4" inset vertical></v-divider>

             <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="runReport">
                        <v-icon color="#B9802B" size="38px">mdi-refresh</v-icon>
                      </v-btn>

                    </template>
                    <span>Refresh Report</span>
             </v-tooltip>

             <v-divider  class="mx-4" inset vertical></v-divider>

             <v-menu  v-model="menuWeekEnding" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      rounded
                      dense
                      prepend-inner-icon ="mdi-calendar"
                      :value = "computedWeekEnding"
                      label  = "Week Ending ..."
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color = "#B9802B"
                    no-title
                    v-model = "weekEndingFilter"
                    @change = "menuWeekEnding = false"
                  ></v-date-picker>
             </v-menu>
                      
             <v-divider  class="mx-4" inset vertical></v-divider>

             <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on"  fab dark x-small color="#B9802B">
                        {{ itemCount }}
                      </v-btn>
                    </template>
                    <span>Item Count</span>
             </v-tooltip>

             <v-spacer></v-spacer>

             <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="store.exportToCsvDialog = true">
                        <v-icon color="#B9802B" size="38px">mdi-file-delimited</v-icon>
                      </v-btn>

                    </template>
                    <span>Export as CSV</span>
             </v-tooltip>
                
              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn v-bind="attrs" v-on="on" class="simple" text @click="generatePDF">
                        <v-icon color="#B9802B" size="38px">mdi-file-pdf-box</v-icon>
                      </v-btn>

                    </template>
                    <span>Create PDF</span>
              </v-tooltip>
             
        </v-toolbar>
     
     </template>
      
     <template v-if = "items.length > 0" slot="body.append">
        <tr>
         <th></th>
         <th></th>
         <th></th>
         <th></th>
         <th></th>
         <th></th>

         <th class="font14">{{ netPayComputed     | toCurrency   }}</th>
         <th class="font14">{{ netMileageComputed | toCurrency  }}</th>
         <th class="font14">{{ netExpenseComputed | toCurrency }}</th>
         <th class="font14">{{ netChargeComputed  | toCurrency }}</th>
         <th class="font14">{{ netMarginComputed  | toCurrency }}</th>

         <th></th>
         <th></th>
         
     </tr>
  
     </template>
    
     <template v-slot:item.unitsAmount="{ item }">
          <span>{{ item.unitsAmount | toCurrency }}</span>
     </template>

     <template v-slot:item.mileageAmount="{ item }">
          <span>{{ item.mileageAmount | toCurrency }}</span>
     </template>

     <template v-slot:item.expenseAmount="{ item }">
          <span>{{ item.expenseAmount | toCurrency }}</span>
     </template>

     <template v-slot:item.chargeAmount="{ item }">
          <span>{{ item.chargeAmount | toCurrency }}</span>
     </template>

     <template v-slot:item.netMargin="{ item }">
          <span>{{ item.netMargin | toCurrency }}</span>
     </template>

     <template v-slot:item.pma = "{ item }">
         <v-simple-checkbox  :value="item.pma" disabled></v-simple-checkbox>
    </template> 

     <template v-slot:item.rollOver = "{ item }">
         <v-simple-checkbox  :value="item.rollOver" disabled></v-simple-checkbox>
    </template> 
           
    <template v-slot:item.weekEnding = "{ item }">
      <span>
          <strong>
            {{ new Date(item.weekEnding).toLocaleString('en-GB', { year: "numeric",month: "numeric", day: "numeric", timeZone: 'UTC' }) }}
         </strong>
        </span> 
    </template> 

      </v-data-table>
     </v-col>
  </v-row>

</template>

<script>

import { apiResource }                  from '@/store/apiResource'
import { store  }                       from '@/store/store'
import { apiShared }                    from '@/store/apiShared'
import { reportMap }                      from '@/views/reports/widgets/reportMap.js'

import  exportToCsvDialog               from '@/components/Dialogs/exportToCsvDialog.vue'
import  moment                          from 'moment'
import  axios                           from "axios"

export default {  

      components    : { exportToCsvDialog },
      name          : 'processedWeekly',

       filters              : {
                 toCurrency: function (value) {
                    var formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP'});
                    return formatter.format( value );
                }
       },

       mounted () {
          console.log(`${this.name} has mounted`);
          this.initialize()
       },

       beforeMount() {
         console.log(`before mount ${this.name}`);
         apiResource.viewDestroyed();
       },
   
      computed      : {
  
        computedWeekEnding () {
            return ( this.weekEndingFilter) ? moment( this.weekEndingFilter).format('dddd, MMMM Do YYYY') : ''
        },

        header() {
          return ( this.items.length > 0) ?  this.reportHeader : [];
        },

        netPayComputed(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat(item.unitsAmount);
                    });
                    return (summary);
        },
    
        netMileageComputed(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat(item.mileageAmount);
                    });
                    return (summary);
        },
              
        netExpenseComputed(){
                let summary = 0.00;
                 this.items.forEach(  function(item) {
                          summary   = summary + parseFloat(item.expenseAmount);
                    });
                    return (summary);
        },
              
        netChargeComputed(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat(item.chargeAmount);
                    });
                    return (summary);
        },

        netMarginComputed(){
                    let summary = 0.00;
                    this.items.forEach(  function(item) {
                          summary   = summary + parseFloat(item.netMargin);
                    });
                    return (summary);
        },

        itemCount()         {  return ( this.items.length > 0) ? this.items.length : '0';},
        reportTitle()       {  return reportMap.getTitle(       this.name )   },
        reportDescription() {  return reportMap.getDescription( this.name )   },
        reportRoute()       {  return reportMap.getRoute(       this.name )   },

        computedPdfPrinting() {
            return this.pdfPrinting;     
        }
      },

      methods: {

        async generatePDF() {
              apiShared.generatePDF( 'Processed Weekly', this.weekEndingFilter );
       
        },

        initialize () {
                var newDate = moment(); 
                this.weekEndingFilter = newDate.day(newDate.day() >= 5 ? 5 :-2).format('YYYY-MM-DD');
        },

        async runReport () {
       
          try {

            this.items               = [];
            this.loading.loadingData = true;

            const resourceURL        = apiResource.getResourceUrl("report")
            const url                = `${resourceURL}/${ this.report['endPoint']}/${ this.weekEndingFilter }`;
            const response           = await axios.get( url );
            this.items               = response.data;

          } catch (error) {

              this.loading.loadingData         = false;
              this.store.toastDialog.subtext   = "Processed Weekly Report";
              this.store.toastDialog.text      = error.response.data.message;
              this.store.toastDialog.show      = true;

          } finally {

            this.loading.loadingData      = false;
            this.loading.loadingDataText  =  'Loading Processed Weekly Data'
          
          }        

        },

        itemHoursMoreThan37: function (item) {
                 return item.hours > 37 ? 'custom-highlight-row' : ''
        },
     
      },

      watch: {
            weekEndingFilter: function () {
                 this.runReport();
            }
      },      
      
      data() {
          return {
            loading          :  {
                                  loadingData       : false,
                                  loadingDataText   : 'Loading processed Weekly Data'
                                },

            weekEndingFilter : '',

            reportHeader     : [
                         
                         { text: 'Worker',           value: 'candidateName'   }, 
                         { text: 'Umbrella',         value: 'umbrellaName'    },
                         { text: 'Client',           value: 'clientName'      },
                         { text: 'Cons',             value: 'consultantName'  },

                         { text: 'Units',            value: 'units'           },
                         { text: 'Miles',            value: 'mileage'         },

                         { text: 'Netpay',           value: 'unitsAmount'     },
                         { text: 'Mileage',          value: 'mileageAmount'   },
                         { text: 'Exes',             value: 'expenseAmount'   },
                         { text: 'Netcharge',        value: 'chargeAmount'    },
                         { text: 'Netmargin',        value: 'netMargin'       },
                          
                         { text: 'Rollover',         value: 'rollOver'        },
                         { text: 'PMA',              value: 'pma'             },
                         
                         { text: 'Submmitter',       value: 'submitter'       },
                         { text: 'Weekending',       value: 'weekEnding'      },
                         
                         
            
                         
                               ],

            items               : [],
            menuWeekEnding      : false,
            
            report           : {
                                'name'         : "Processed Weekly Report",
                                'endPoint'     : "processedWeekly",
                                'drillDown'    : "processedWeeklyDrillDown"
            },
            
            store
          };
      },

  }


</script>

<style scoped>
.custom-highlight-row{
   background-color: pink
}

.custom-highlight-row:hover{
   background-color: pink !important;
}

</style>