<template>
    <v-row>
          <v-col cols="12">
             <ClientsCrud />
          </v-col>
    </v-row>
</template>


<script>

import ClientsCrud                      from '@/components/crud/ClientsCrud.vue'
import { apiResource }                  from '@/store/apiResource'
import { store  }                       from '@/store/store'


export default {  

      components: {ClientsCrud},
      name       : 'Clients',

      mounted () {
          console.log("Clients mounted")
          this.initialize()
      },

      beforeMount() {
        // clean up previous crud items 
        // before computed properties activate
         console.log("Clients beforeMount")
         apiResource.viewDestroyed();
      },
   
      methods: {

        initialize () {

          this.store.resource            = "client";
          this.store.crudComponent.sort  = "clientName";
          apiResource.getCrudHeaders ();
          apiResource.getCrudItems   ();

        },
      },
      
      data() {
          return {
            store
          };
      }
}

</script>